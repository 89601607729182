// comments done
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../utils/css/main.css';
class ErrorPage404 extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
        }
    }
    defaultTheme = {
        '--primary-accent': '#9500ff',
        '--primary-active': '#8100dd',
        '--primary-hover': '#a322ff',
        '--reacttable-odd': '#e0b5ff59',
        '--reacttable-hover': '#bf64ff59',
        '--mainfont-color': '#ffffff',
        '--reactcalendar-now': '#fad2ff',
        '--reactcalendar-now-hover': '#f4bfff'
    }
    lightMode = {
        '--normalfont-color': '#000000',
        '--default-bg': '#ffffff',
        '--body-bg': '#e6e6e6',
        '--settings-bg': '#f3f3f3',
        '--reacttable-input': '#ffffff',
        '--reactcalendar-navbutton': 'none',
        '--reactcalendar-navbutton-hover': '#e6e6e6',
        '--reactcalendar-navbutton-disabled': '#f0f0f0',
        '--reactcalendar-navbutton-disabled2': '#f0f0f0',
        '--tableborder-color':'#dbdbdb'
    }
    darkMode = {
        '--normalfont-color': '#ffffff',
        '--default-bg': '#363636',
        '--body-bg': '#232323',
        '--settings-bg': '#474747',
        '--reacttable-odd': 'transparent',
        '--reacttable-input': '#ffffff15',
        '--reactcalendar-navbutton': '#e6e6e60e',
        '--reactcalendar-navbutton-hover': '#e6e6e634',
        '--reactcalendar-navbutton-disabled': '#f0f0f000',
        '--reactcalendar-navbutton-disabled2': '#e6e6e60e',
        '--tableborder-color':'#6b6b6b'
    }
    componentDidMount = () =>
    {
        let theme = JSON.parse(localStorage.getItem('currentTheme'))

        if (theme !== null)
        {
            Object.keys(theme).map(key =>
            {
                const value = theme[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        }
        else theme = this.defaultTheme

        const uiBgNo = parseInt(localStorage.getItem('uiBgNo'))

        if (uiBgNo === 0)
        {
            let temp = this.lightMode;
            if (theme !== null)
            {
                temp['--reacttable-odd'] = theme['--reacttable-odd']
                temp['--reactcalendar-now'] = theme['--reactcalendar-now']
                temp['--reactcalendar-now-hover'] = theme['--reactcalendar-now-hover']
            }
            Object.keys(temp).map(key =>
            {
                const value = temp[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        } else if (uiBgNo === 1)
        {
            if (theme !== null)
            {
                this.darkMode['--reactcalendar-now'] = theme['--reactcalendar-now'] + '6c'
                this.darkMode['--reactcalendar-now-hover'] = theme['--reactcalendar-now-hover'] + '98'
            }
            Object.keys(this.darkMode).map(key =>
            {
                const value = this.darkMode[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        }
        console.log('THE THEME IS ', theme)
        //theme logic used above for error page
    }
    render()
    {
        return (
            <div>
                <div className="wrapper text-center">
                    <div className="main_content" style={{ height: '97vh' }}>
                        <div className='error404Container'>
                            <div className='error404Container2'>
                                <div style={{ fontWeight: '800', fontSize: '4rem', color: 'var(--normalfont-color)' }}>
                                    Oops!
                                </div>
                                <div style={{ fontSize: '2.25rem', textAlign: 'left', color: 'var(--normalfont-color)' }}>
                                    You seem lost, let’s head<br></br> back home
                                </div>
                                <Link to={'/Dashboard'}>
                                    <div className='buttonText' style={{ paddingTop: '2.25rem' }}>
                                        <div className='buttonContainer'>
                                            <div className='buttonText'>Dashboard</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <img src={require('../../utils/images/logo.png')} alt='Zalt Logo' width='50px' id='logo1'></img>
                            <svg width="63" height="14" viewBox="0 0 68 17" fill="none" xmlns="http://www.w3.org/2000/svg" id='logo2'>
                                <path d="M2 4.03102V1.60327H16.3488L7.3703 13.0946H16.3488V15.6033H2L10.9785 4.03102H2Z" fill="#8A8A8A" stroke="#8A8A8A" />
                                <path d="M19.6108 15.5225L26.8272 1.84619L34.2113 15.5225H31.2745L26.8272 7.18723L22.296 15.5225H19.6108Z" fill="#8A8A8A" stroke="#8A8A8A" />
                                <path d="M39.991 1.60327H37.4736V11.7189C37.4736 14.5027 39.7672 15.3605 40.914 15.4414H49.3051V13.0946H41.5014C40.2259 13.0946 39.963 11.9617 39.991 11.3952V1.60327Z" fill="#8A8A8A" stroke="#8A8A8A" />
                                <path d="M52.5674 4.19287V1.60327H67.0001V4.19287H61.0424V15.6033H58.4411V4.19287H52.5674Z" fill="#8A8A8A" stroke="#8A8A8A" />
                            </svg>
                            {/* central graphic svg above */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage404;
