// comments done
import React, { Component } from 'react';
import '../../utils/css/main.css';
import { Redirect } from 'react-router-dom';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'
import { connect } from 'react-redux';
import { StaticMap } from 'react-map-gl';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import DeckGL from '@deck.gl/react';
import { tooltipStyle } from './style';
import axios from 'axios'
import Loader from '../../utils/additional components/LoaderComponent'

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZXh0cmFhbmFudCIsImEiOiJja2Nkcnl4eGkwMWZ3MnhwY3Y4djhoOWliIn0.MRHbTJanWDrCjGJ4kggwcw';
const DATA_URL1 = 'https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/chennai_heatmap2d.json';
const DATA_URL2 = 'https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/chennai_heatmap2d.json';
const DATA_URL3 = 'https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/chennai_heatmap2d.json';

//intitial config for mapbox map
let INITIAL_VIEW_STATE = {
    longitude: 80.2707,
    latitude: 13.0827,
    zoom: 7.4,
    minZoom: 1,
    maxZoom: 15,
    pitch: 0,
    bearing: 0
};
const mapStateToProps = (state) =>
{
    return { isLogged: state.isLogged, companyDetails: state.companyDetails }
}


class HeatMap2D extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            hover: {
                x: 0,
                y: 0,
                hoveredObject: null
            },//tooltip values required to position tooltip on screen
            data: null,
            intensity: 1,//map visuals values
            threshold: 0.03,//map visuals values
            radiusPixels: 5,//map visuals values
            mapDataReady: false,
            dropDownClicked: true,
            heatMapReady: false,//flag variable to render map
            data1Ready: false,
            data2Ready: false,
            data3Ready: false,
            data1Switch: 1,
            data2Switch: 0,
            data3Switch: 0,
            hm_data: [],
            hm_data1: [],
            hm_data2: [],
            hm_data3: [],
            x1: 0,
            x2: 0,
            x3: 0,
            y1: 0,
            y2: 0,
            y3: 0,
            l1: 0,
            l2: 0,
            l3: 0,
            timeFrame: 0
        };
    }

    //function to set react tooltip values when hovering over visuals
    _onHover(hover)
    {
        console.log('RED', hover)
        let object = hover.coordinate;
        const label = object ? ('Coordinates : \n' + object[0].toFixed(4) + ', ' + object[1].toFixed(4) + "") : null;
        let x = hover.x, y = hover.y;
        this.setState({ hover: { x, y, hoveredObject: hover.color, label } });
    }

    //function to render the necessary heatmap layer
    _renderLayers(heatMapData, onHover)
    {
        return [
            new HeatmapLayer({
                className: 'helloWorld',
                id: 'heatmap',
                data: heatMapData,//data for heatmap
                getPosition: d => [d[1], d[0]],// since final data structure is [x, y, weight] which is being fetched
                getWeight: d => d[2],// since final data structure is [x, y, weight] which is being fetched
                onHover: hover => onHover(hover),//using passed parameter onHover
                pickable: true,//to enable tooltip(not sure, confirm once)
                radiusPixels: this.state.radiusPixels,//setting heatmap layer config values
                intensity: this.state.intensity,//setting heatmap layer config values
                threshold: this.state.threshold//setting heatmap layer config values
            })
        ];
    }

    fetchData = () =>
    {
        axios.get(DATA_URL1)//fetching data from source1
            .then((response) => response.data)
            .then(data =>
            {
                console.log('FETCHED DATA 1', this.state.timeFrame)
                let hm_data1 = data;//setting fetched data in heatmap data1 variable
                let x1 = 0, y1 = 0, l1 = hm_data1.length;
                // x1, y1, l1, correspond to sum of x coords, y coords, and no of coords in hm_data1, to be used in calculating the average location
                for (let i = 0; i < l1; i++)
                {
                    x1 += parseFloat(hm_data1[i][0])
                    y1 += parseFloat(hm_data1[i][1])
                }
                let data1Ready = true;
                // flag set when data1 has been pre processed
                if (this.state.data2Ready && this.state.data3Ready)//this is called when already data2 and data3 had been processed, and only data1 was remaining which too is now over
                {
                    let x = (x1 + this.state.x2 + this.state.x3);//sum of all x_coords from all data sources
                    let y = (y1 + this.state.y2 + this.state.y3);//sum of all y_coords from all data sources
                    let len = (l1 + this.state.l2 + this.state.l3);//sum of all no of coords from all data sources

                    INITIAL_VIEW_STATE.latitude = parseFloat((x / len).toFixed(8));//setting average lat and long in the map config json
                    INITIAL_VIEW_STATE.longitude = parseFloat((y / len).toFixed(8));//setting average lat and long in the map config json
                    console.log('RED@@@1', INITIAL_VIEW_STATE)

                    this.setState({ data1Ready, hm_data1, l1, x1, y1, mapDataReady: false })//setting flags associated with data1
                }
                else
                    this.setState({ data1Ready, hm_data1, l1, x1, y1 })//setting flags associated with data1
            })
            .catch(error => this.setState({
                error
            }))

            //refer above explanation
        axios.get(DATA_URL2)
            .then((response) => response.data)
            .then(data =>
            {
                console.log('FETCHED DATA 2', this.state.timeFrame)
                let hm_data2 = data;
                let x2 = 0, y2 = 0, l2 = hm_data2.length;
                for (let i = 0; i < l2; i++)
                {
                    x2 += parseFloat(hm_data2[i][0])
                    y2 += parseFloat(hm_data2[i][1])
                }
                let data2Ready = true;
                if (this.state.data1Ready && this.state.data3Ready)
                {
                    let x = (x2 + this.state.x1 + this.state.x3);
                    let y = (y2 + this.state.y1 + this.state.y3);
                    let len = (l2 + this.state.l1 + this.state.l3);

                    INITIAL_VIEW_STATE.latitude = parseFloat((x / len).toFixed(8));
                    INITIAL_VIEW_STATE.longitude = parseFloat((y / len).toFixed(8));
                    console.log('RED@@@1', INITIAL_VIEW_STATE)

                    this.setState({ data2Ready, hm_data2, l2, x2, y2, mapDataReady: false })
                }
                else
                    this.setState({ data2Ready, hm_data2, l2, x2, y2 })
            })
            .catch(error => this.setState({
                error
            }))

        axios.get(DATA_URL3)
            .then((response) => response.data)
            .then(data =>
            {
                console.log('FETCHED DATA 3', this.state.timeFrame)
                let hm_data3 = data;
                let x3 = 0, y3 = 0, l3 = hm_data3.length;
                for (let i = 0; i < l3; i++)
                {
                    x3 += parseFloat(hm_data3[i][0])
                    y3 += parseFloat(hm_data3[i][1])
                }
                let data3Ready = true;
                if (this.state.data2Ready && this.state.data1Ready)
                {
                    let x = (x3 + this.state.x2 + this.state.x1);
                    let y = (y3 + this.state.y2 + this.state.y1);
                    let len = (l3 + this.state.l2 + this.state.l1);

                    INITIAL_VIEW_STATE.latitude = parseFloat((x / len).toFixed(8));
                    INITIAL_VIEW_STATE.longitude = parseFloat((y / len).toFixed(8));
                    console.log('RED@@@1', INITIAL_VIEW_STATE)

                    this.setState({ data3Ready, hm_data3, l3, x3, y3, mapDataReady: false })
                }
                else
                    this.setState({ data3Ready, hm_data3, l3, x3, y3 })
            })
            .catch(error => this.setState({
                error
            }))


    }
    componentDidMount = () =>
    {
        if(this.state.timeFrame===0)//fetching when time frame 0, i,e 24 hours
            this.fetchData();
    }

    handleDropDownClick = (e) =>
    {
        console.log('BLUE', e.target.value);//printing timeframe value on click
        this.setState({ timeFrame: parseInt(e.target.value),  heatMapReady:false});//setting heat map render flag false and timeframe value
        this.fetchData();//fetching all data required again
    }
    render()
    {
        if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
            return <Redirect to='/' />//redirect to login if not logged in

        if (this.state.mapDataReady === false)
        {
            let hm_data = []

            if (this.state.data1Switch === 1)//checks if data1 checkbox clicked and appends the required data to the fincal heatmap data array
            {
                hm_data = hm_data.concat(this.state.hm_data1)
            }

            if (this.state.data2Switch === 1)//checks if data2 checkbox clicked and appends the required data to the fincal heatmap data array
            {
                hm_data = hm_data.concat(this.state.hm_data2)
            }

            if (this.state.data3Switch === 1)//checks if data3 checkbox clicked and appends the required data to the fincal heatmap data array
            {
                hm_data = hm_data.concat(this.state.hm_data3)
            }

            this.setState({ hm_data, mapDataReady: true, heatMapReady: true })//heatmap data ready and can now render the heatmap

        }
        const { mapStyle2 = 'mapbox://styles/mapbox/dark-v9' } = this.props;
        const { mapStyle1 = 'mapbox://styles/mapbox/light-v9' } = this.props;

        const uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
        let mapStyle = mapStyle1;
        if (uiBgNo === 1)
            mapStyle = mapStyle2;

        const { hover } = this.state;

        console.log('RED@@@0', this.state.hex_data)
        //above is map styles theme management

        return (
            <div>
                {hover.hoveredObject && (
                    <div
                        style={{
                            ...tooltipStyle,
                            transform: `translate(${hover.x + 105}px, ${hover.y + 88}px)`//here I am taking the position of the cursor on the screen from the hover object, and rendering my tool tip at the position on the screen
                        }}
                    >
                        <div>{hover.label}</div>
                    </div>
                )}
                {this.state.heatMapReady===false &&
                    <Loader/>
                }
                {this.state.dropDownClicked && this.state.heatMapReady && ((this.state.data1Switch === 1) || (this.state.data2Switch === 1) || (this.state.data3Switch === 1)) ?
                // checking if all the data has been fetched and the data to be displayed is ready or not

                //control panel for changing the properties of the heatmap layer
                    <div id="control-panel">
                        <div>
                            <label><strong>Radius Pixels:</strong> {this.state.radiusPixels}</label>
                            <input className='slider' id="radiuspixels" type="range" min="1" max="50" step="1" onChange={(e) => { this.setState({ radiusPixels: parseInt(e.target.value) }) }} defaultValue='5'></input>
                            <span id="radiuspixels-value"></span>
                        </div>
                        <div>
                            <label><strong>Intensity:</strong> {this.state.intensity}</label>
                            <input className='slider' id="intensity" type="range" min="0" max="5" step="0.1" onChange={(e) => { this.setState({ intensity: parseFloat(e.target.value) }) }} defaultValue='1'></input>
                            <span id="intensity-value"></span>
                        </div>
                        <div>
                            <label><strong>Threshold:</strong> {this.state.threshold}</label>
                            <input className='slider' id="threshold" type="range" min="0" max="1" step="0.01" onChange={(e) => { this.setState({ threshold: parseFloat(e.target.value) }) }} defaultValue='0.03' ></input>
                            <span id="threshold-value"></span>
                        </div>
                    </div>
                    :
                    null
                }

                <TopNavbar title={"2D Heat Map"} buttons={[{ title: "3D Heat Map", Link: "/HexagonAnalytics" }]} />
                <Navbar highlighted={"Analytics"} />
                <div className="wrapper text-center">

                    <div className="main_content" style={{ height: '97vh' }}>
                    <div className='analyticsInputPanel'>
                        {/* this is the div containing the drop down and the checlboxes */}
                            <select name="hours" id="hours" onChange={(e) => { this.handleDropDownClick(e) }}>
                                <option value="0"  selected>24hrs</option>
                                <option value="1">0-4</option>
                                <option value="2">4-8</option>
                                <option value="3">8-12</option>
                                <option value="4">12-16</option>
                                <option value="5">16-20</option>
                                <option value="6">20-24</option>
                            </select>
                            <div className='analyticsInputPanelInner'>
                                <div className='checkBoxGroup'>
                                    <label className='containerCheckbox'>
                                        <input type="checkbox" onClick={() => { this.setState({ data1Switch: 1 - this.state.data1Switch, mapDataReady: false }) }} id="male" name="gender" value="male" defaultChecked={true}></input>
                                        <span className='checkmark'></span>
                                    </label>
                                    <label for="male" style={{ marginRight: '1rem', marginLeft: '0rem' }}>Data 1</label>
                                </div>

                                <div className='checkBoxGroup'>
                                    <label className='containerCheckbox'>
                                        <input type="checkbox" onClick={() => { this.setState({ data2Switch: 1 - this.state.data2Switch, mapDataReady: false }) }} id="female" name="gender" value="female"></input>
                                        <span className='checkmark'></span>
                                    </label>
                                    <label for="female" style={{ marginRight: '1rem', marginLeft: '0rem' }}>Data 2</label>
                                </div>

                                <div className='checkBoxGroup'>
                                    <label className='containerCheckbox'>
                                        <input type="checkbox" onClick={() => { this.setState({ data3Switch: 1 - this.state.data3Switch, mapDataReady: false }) }} id="other" name="gender" value="other"></input>
                                        <span className='checkmark'></span>
                                    </label>
                                    <label for="other" style={{ marginLeft: '0rem' }}>Data 3</label>
                                </div>
                            </div>
                        </div>


                        <div className='analyticsMapContainer'>

                            {this.state.dropDownClicked && this.state.heatMapReady && ((this.state.data1Switch === 1) || (this.state.data2Switch === 1) || (this.state.data3Switch === 1)) ?
                                <DeckGL
                                    layers={this._renderLayers(this.state.hm_data, (hover) => { this._onHover(hover) })}
                                    initialViewState={INITIAL_VIEW_STATE}
                                    controller={true}
                                    className='DeckGlOuter'
                                >
                                    {/* using static map from map-gl */}
                                    <StaticMap
                                        reuseMaps
                                        mapStyle={mapStyle}
                                        preventStyleDiffing={true}
                                        mapboxApiAccessToken={MAPBOX_TOKEN}
                                    />
                                </DeckGL>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps)(HeatMap2D);