// comments done
/*global google*/
import React from "react";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	DirectionsRenderer,
} from "react-google-maps";
import Timeline, { DateHeader, TimelineHeaders } from 'react-calendar-timeline'
import moment from 'moment';
import MarkerWithInfoWindowHover from '../additional components/MarkerWithInfoWindowHover';
import '../../utils/css/OrderPageTimeline.css';
import Loader from '../../utils/additional components/LoaderComponent'
const stylesLight = require('../json/mapColors.json')
const stylesDark = require('../json/mapColorsDark.json')
let styles = null;
class Map extends React.Component {
	// same as DashboardMap
	constructor(props) {
		super(props);
		this.state = {
			flag: false,
			directionService: [],
			driver: [],
			temp: this.props.routes,
			loading: false,
			groups: [],
			items: []
		};
	}
	innerRoutes = this.props.routes;
	markerRendered = [];
	ctr = 0;
	noOfRoutes = Object.keys(this.innerRoutes).length;
	allDirections = [];
	allDrivers = [];
	allGroups = [];
	allItems = [];
	id_ctr = 0;
	driver_color = {}
	componentDidMount = () => {
		let uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
        if(uiBgNo===1)
            styles=stylesDark
        else
            styles = stylesLight
		if (this.state.flag === false) {
			this.setState({ loading: true })
			const DirectionsService = new google.maps.DirectionsService();
			for (let driver in this.innerRoutes)
			{
				var waypts = [];
				for (var i = 1; i < this.innerRoutes[driver].length - 1; i++)
				{
					waypts.push({
						location: {
							lat: this.innerRoutes[driver][i][0],
							lng: this.innerRoutes[driver][i][1],
						},
						stopover: true,
					});
				}
				DirectionsService.route(
					{
						origin: new google.maps.LatLng(
							this.innerRoutes[driver][0][0],
							this.innerRoutes[driver][0][1]
						),
						destination: new google.maps.LatLng(
							this.innerRoutes[driver][this.innerRoutes[driver].length - 1][0],
							this.innerRoutes[driver][this.innerRoutes[driver].length - 1][1]
						),
						waypoints: waypts,
						optimizeWaypoints: true,
						travelMode: google.maps.TravelMode.DRIVING,
					},
					(result, status) => {
						if (status === google.maps.DirectionsStatus.OK) {
							this.allDirections = this.allDirections.concat(result);
							this.allDrivers = this.allDrivers.concat(driver);
							this.ctr = this.ctr + 1;
							this.allGroups = this.allGroups.concat(
								{
									'id': this.ctr,
									'title': driver
								}
							)
							this.driver_color[driver] = "purple"
							for (let w = 0; w < this.innerRoutes[driver].length; w++) {
								let time = this.innerRoutes[driver][w][2]
								this.allItems = this.allItems.concat(
									{
										id: this.id_ctr,
										group: this.ctr,
										title: String.fromCharCode(65 + w) + ' :' + this.innerRoutes[driver][w][3],
										start_time: moment().hour(time.substring(0, time.indexOf(':'))).minute(time.substring(time.indexOf(':') + 1)),
										end_time: moment().hour(time.substring(0, time.indexOf(':'))).minute(time.substring(time.indexOf(':') + 1)).add(15, 'minutes'),
										canMove: false,
										canResize: false,
										canChangeGroup: false,
										itemProps: {
											style: {
												background: this.driver_color[driver],
												color: 'white',
												textAlign: 'left',
												border: 'none',
												borderRadius: '3px',
												fontWeight: 'bold',
											}
										}
									}
								)
								this.id_ctr = this.id_ctr + 1;
							}
							if (this.ctr === this.noOfRoutes) {
								this.setState({
									directionService: this.state.directionService.concat(
										this.allDirections
									),
									flag: true,
									driver: this.state.driver.concat(this.allDrivers),
									loading: false,
									groups: this.allGroups,
									items: this.allItems,
								});
							}
						} else {
							console.error(`error fetching directions ${result}`);
						}
					}
				);
			}
		}
	};
	render = () => {
		var color = "purple";
		var pathsRendered = null;
		if (this.state.flag === true) {
			pathsRendered = this.state.directionService.map((direction, i) => {
				color = "purple";
				return (
					<DirectionsRenderer
						directions={direction}
						options={{
							polylineOptions: { strokeColor: color },
							suppressMarkers: true,
							markerOptions: {
								icon: {
									path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
									strokeColor: color,
									scale: 3,
								},
							},
						}}
					/>
				);
			});
			this.markerRendered = [];
			console.log("drivers order", this.state.driver);
			for (let driver in this.innerRoutes) {
				color = "purple";
				for (var j = 0; j < this.innerRoutes[driver].length; j++) {
					let loc = this.innerRoutes[driver][j];
					let type = 'normal';
					let num = j;
					if(!(j===0 || j===this.innerRoutes[driver].length-1))
						type='location';
					if(j===this.innerRoutes[driver].length-1)
						num = 1;
					this.markerRendered = this.markerRendered.concat(
						<MarkerWithInfoWindowHover
							loc={loc}
							content={
								<div style={{ color: "black" }}>
									<span style={{ fontWeight: "bold" }}>Time: </span><span>{loc[2]}</span>
									<br></br>
									<span style={{ fontWeight: "bold" }}>Address: </span><span>{loc[3]}</span>
								</div>
							}
							j={num}
							color={color}
							type={type}
						/>
					);
				}
			}
		}
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
				{this.state.loading && <Loader />}
				{this.state.loading ?
					<div style={{ width: '100%', height: '100%', background: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>Loading...</div>
					:
					<GoogleMap
						defaultZoom={8}
						defaultCenter={{ lat: 13.0827, lng: 80.2707 }}
						defaultOptions={{
							disableDefaultUI: false, // disable default map UI
							draggable: true, // make map draggable
							keyboardShortcuts: true, // disable keyboard shortcuts
							scaleControl: true, // allow scale controle
							scrollwheel: true, // allow scroll wheel
							styles: styles // change default map styles
						}}
					>
						{this.markerRendered}
						{pathsRendered}
					</GoogleMap>
				}
				{!this.state.loading ?
					<div style={{ width: '90%', marginTop: '5vh', marginBottom: '5vh' }}>
						Delivery Timeline
						<Timeline
								groups={this.state.groups}
								items={this.state.items}
								defaultTimeStart={moment().hour(7).minute(0)}
								defaultTimeEnd={moment().hour(20).minute(0)}
								minZoom={2 * 60 * 60 * 1000}// to restric min zoom
								maxZoom={24 * 60 * 60 * 1000}// to restrict max zoom, any doubts refer the docs
							>
								<TimelineHeaders>
									<DateHeader labelFormat='HH:mm' />
								</TimelineHeaders>
							</Timeline>
					</div>
					:
					null
				}
			</div>
		);
	};
}

const MapComponent = withScriptjs(withGoogleMap(Map));
export default MapComponent