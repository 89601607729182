// comments done
import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
import axios from "axios";
import Cookies from 'js-cookie';
import { connect } from 'react-redux'
import { signin } from '../actions/index'
import { setCompanyDetails } from '../actions/index'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'//international phone number input
import { isValidPhoneNumber } from 'react-phone-number-input'//used for phone no validation on sign up

//CSS
import styles from '../../utils/css/loginPage.module.css'
//Cookies
const mapDispatchToProps = (dispatch) =>
{
    return {
        logIn: (payload) => { dispatch(signin(payload)) },
        setCompanyDetails: (payload) => { dispatch(setCompanyDetails(payload)) }
    }
}
const mapStateToProps = (state) =>
{
    return {
        isLogged: state.isLogged
    }
}
class LoginForm extends Component
{
    constructor(props)
    {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            email: '',
            password: '',
            userLoggedInStatus: false,
            forgotPassword: false,
            forgotPassEmail: null,
            forgotPassEmailError: null,
            emailError: null,
            passwordError: null,
        }//necessary input fields and error messages
    }
    defaultTheme = {
        '--primary-accent': '#9500ff',
        '--primary-active': '#8100dd',
        '--primary-hover': '#a322ff',
        '--reacttable-odd': '#e0b5ff59',
        '--reacttable-hover': '#bf64ff59',
        '--mainfont-color': '#ffffff',
        '--reactcalendar-now': '#fad2ff',
        '--reactcalendar-now-hover': '#f4bfff'
    }
    lightMode = {
        '--normalfont-color': '#000000',
        '--default-bg': '#ffffff',
        '--body-bg': '#e6e6e6',
        '--settings-bg': '#f3f3f3',
        '--reacttable-input': '#ffffff',
        '--reactcalendar-navbutton': 'none',
        '--reactcalendar-navbutton-hover': '#e6e6e6',
        '--reactcalendar-navbutton-disabled': '#f0f0f0',
        '--reactcalendar-navbutton-disabled2': '#f0f0f0',
        '--tableborder-color':'#dbdbdb'
    }
    darkMode = {
        '--normalfont-color': '#ffffff',
        '--default-bg': '#363636',
        '--body-bg': '#232323',
        '--settings-bg': '#474747',
        '--reacttable-odd': 'transparent',
        '--reacttable-input': '#ffffff15',
        '--reactcalendar-navbutton': '#e6e6e60e',
        '--reactcalendar-navbutton-hover': '#e6e6e634',
        '--reactcalendar-navbutton-disabled': '#f0f0f000',
        '--reactcalendar-navbutton-disabled2': '#e6e6e60e',
        '--tableborder-color':'#6b6b6b'
    }//to set theme according to local storage on login and sign up page, else default theme
    componentDidMount()
    {

        axios({
            url: 'login/',
            method: 'GET',
            withCredentials: true,
            credentials: 'include'
        })
            .catch((error) =>
            {
                console.log("error GET Request");
            })
        this.setState({ csrftoken: Cookies.get('csrftoken') })
        console.log('csrftoken: ', Cookies.get('csrftoken'))

        let theme = JSON.parse(localStorage.getItem('currentTheme'))//reading theme from local storage

        if (theme !== null)
        {
            Object.keys(theme).map(key =>
            {
                const value = theme[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        }
        else theme = this.defaultTheme

        const uiBgNo = parseInt(localStorage.getItem('uiBgNo'))

        if (uiBgNo === 0)
        {
            let temp = this.lightMode;
            if (theme !== null)
            {
                temp['--reacttable-odd'] = theme['--reacttable-odd']
                temp['--reactcalendar-now'] = theme['--reactcalendar-now']
                temp['--reactcalendar-now-hover'] = theme['--reactcalendar-now-hover']
            }
            Object.keys(temp).map(key =>
            {
                const value = temp[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        } else if (uiBgNo === 1)
        {
            if (theme !== null)
            {
                this.darkMode['--reactcalendar-now'] = theme['--reactcalendar-now'] + '6c'
                this.darkMode['--reactcalendar-now-hover'] = theme['--reactcalendar-now-hover'] + '98'
            }
            Object.keys(this.darkMode).map(key =>
            {
                const value = this.darkMode[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        }
        //above is necessary logic for setting the necessary theme variables
        console.log('THE THEME IS ', theme)
    }



    handleFields = e => this.setState({
        [e.target.name]: e.target.value
    });
    // to handle change event in the input fields

    handleSubmit = (event) =>//to perform input validation, submit on success
    {
        event.preventDefault();

        if (this.state.email === 'admin@admin.com' && this.state.password === 'admin')
        {
            // this.setState({ userLoggedInStatus: true })
            this.props.logIn("admin");
            this.props.setCompanyDetails('ZALT Admin')
        }
        else
            if (this.state.email === "superadmin@admin.com" && this.state.password === "admin")
            {
                this.props.logIn("superadmin");
                this.props.setCompanyDetails('Super Admin')
            }
            else
            {
                let emailError=null, passwordError=null;
                // eslint-disable-next-line
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// regex for email validation
                if (!re.test(String(this.state.email).toLowerCase()))
                    emailError='Incorrect email format';

                if (this.state.password.length < 5)
                    passwordError='Password length should be greater than 5';
                else
                {
                    let formdata = new FormData()
                    formdata.append("csrfmiddlewaretoken", Cookies.get('csrftoken'))
                    formdata.append("email", this.state.email)
                    formdata.append("password", this.state.password)
                    formdata.append("api", "true")
                    // await axios({
                    //     url: '/login/',
                    //     method: 'POST',
                    //     withCredentials: true,
                    //     data: formdata
                    // })
                    //     .then((response) =>
                    //     {
                    //         console.log(response.data.status);
                    //         response.data.status
                    //             ? this.setState({ userLoggedInStatus: true })
                    //             : this.setState({ passwordError: "Invalid Credentials! Please Try Again" })
                    //     })
                    //     .catch((error) =>
                    //     {
                    //         console.log("Error Loggin in");
                    //         window.alert("Server Side Error");
                    //     })
                    passwordError="Invalid Credentials! Please Try Again"
                }
                this.setState({ passwordError:passwordError, emailError:emailError})

            }


    }
    handleForgotPasswordSubmit = (event) =>//forgot password validation and submit on success
    {
        event.preventDefault()
        // eslint-disable-next-line
        let forgotPassEmailError=null;
        // eslint-disable-next-line
        if (this.state.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            console.log(this.state.email)
        }
        else
        {
            forgotPassEmailError='Invalid email id';
        }
        this.setState({forgotPassEmailError:forgotPassEmailError})
    }
    render()
    {
        if (this.props.isLogged.superAdminStatus === true)//redirect to superadmin portal on login
            return <Redirect to='/AdminDashboard'></Redirect>
        if (this.props.isLogged.adminStatus === true || this.props.isLogged.userStatus === true)//login to user portal
        {
            return <Redirect to="/Dashboard" />
        }
        return (
            <div style={{ height: '100%', width: '100%' }}>

                {this.state.forgotPassword ?
                    <form className={styles.innerContainer2}
                        onSubmit={(event) => { this.handleForgotPasswordSubmit(event) }}>

                        <div className={styles.loginInputLabel}>Email Id</div>
                        <input type='email' placeholder='Email Id' onChange={this.handleFields} name="email"></input>
                        <div className='error'>{this.state.forgotPassEmailError}</div>

                        <div className={styles.buttoncont}>
                            <button type='submit' className={styles.primarybtn}>Submit</button>
                            <button type='button' className={styles.normalbtn} onClick={() => { this.setState({ forgotPassword: false }) }}>Back</button>
                        </div>
                    </form>
                    :
                    null
                }
                {/* show forgot password form is clicked on forgot password button */}
                {this.state.forgotPassword ?
                    null
                    :
                    <form className={styles.innerContainer2} onSubmit={this.handleSubmit}>
                        <div className={styles.loginInputLabel}>Email Id</div>
                        <input type='email' required placeholder='Email Id' onChange={this.handleFields} name="email"></input>
                        <div className='error'>{this.state.emailError}</div>

                        <div className={styles.loginInputLabel}>Password</div>
                        <input type='password' required placeholder='Password' onChange={this.handleFields} name="password"></input>
                        <div className='error'>{this.state.passwordError}</div>
                        <div className={styles.buttoncont}>
                            <button type='submit' className={styles.primarybtn} >Sign In</button>
                            <button type='button' className={styles.normalbtn} onClick={() => { this.setState({ forgotPassword: true }) }}>Forgot Password</button>
                        </div>
                    </form>
                }

            </div>
        )
    }
}
class SignUpForm extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            showMessage: false,
            phoneno: null,
            email: null,
            vno: null,
            phonenoError: null,
            emailError: null,
            vnoError: null,
        }
    }//necessary input fields and validation error message fields
    handleSubmit = (event) =>
    {
        event.preventDefault()
        let phonenoError = false, emailError = null, vnoError = null;
        console.log('REDPH', this.state.phoneno)
        if (!isValidPhoneNumber(this.state.phoneno) || this.state.phoneno === null)
            phonenoError = 'Entered phone number is invalid!'
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log('REDEX', !re.test(String(this.state.email).toLowerCase()))
        console.log('REDEX', this.state.email)
        if (!re.test(String(this.state.email).toLowerCase()) || this.state.email === null)
            emailError = 'Invalid Email entered!'

        if (parseInt(this.state.vno) <= 0 || this.state.vno === null)
            vnoError = 'Please enter number of vehicles greater than 0'

        if (phonenoError || vnoError || emailError)
        {
            this.setState({ phonenoError, emailError, vnoError })
        }
        else
            this.setState({ showMessage: true })

    }
    render()
    {
        return (
            <div className='innerContainer3'>
                {this.state.showMessage ?
                    <div className={styles.loginInputLabel} style={{ fontWeight: 'normal' }}>
                        Details submitted! <br></br> You will be contacted by our team soon.
            </div>
                    :
                    <form className={styles.innerContainer2} onSubmit={(event) => { this.handleSubmit(event) }}>

                        <div className={styles.loginInputLabel}>Email Id</div>
                        <input type='email' placeholder='Email Id' onChange={(e) => this.setState({ email: e.target.value })}></input>
                        <div className='error'>{this.state.emailError}</div>

                        <div className={styles.loginInputLabel}>Phone No</div>
                        <PhoneInput
                            placeholder="Enter phone number"
                            countries={['US', 'IN']}
                            defaultCountry="IN"
                            // value={this.state.phoneno}
                            onChange={(e) => { console.log('RED PH', e); this.setState({ phoneno: e }) }}
                        />
                        <div className='error'>{this.state.phonenoError}</div>

                        <div className={styles.loginInputLabel}>No of Vehicles</div>
                        <input type='number' placeholder='No of Vehicles' onChange={(e) => this.setState({ vno: e.target.value })}></input>
                        <div className='error'>{this.state.vnoError}</div>

                        <button type='submit' className={styles.primarybtn}>Submit Details</button>
                    </form>
                }
            </div>
        )
    }
}
export class Login extends Component//final class to be exported
{
    constructor(props)
    {
        super(props);
        this.state = {
            showSignup: false,
        }
    }
    render()
    {
        return (
            <div className={styles.parentContainer}>
                <div className={styles.bgimgContainer}>
                </div>
                <div className={styles.formContainer}>
                    <div class={styles.innerContainer}>
                        <div className={styles.switch}>
                            <input type='radio' name='switch' id='SignIn' onClick={() => { this.setState({ showSignup: false }) }} defaultChecked={true}></input>
                            <label for='SignIn' className={styles.loginLabel}>Sign In</label>
                            <input type='radio' name='switch' id='SignUp' onClick={() => { this.setState({ showSignup: true }) }}></input>
                            <label for='SignUp' className={styles.loginLabel}>Sign Up</label>
                        </div>
                        {this.state.showSignup ? <SignUpForm logIn={this.props.logIn} /> : <LoginForm logIn={this.props.logIn} isLogged={this.props.isLogged} setCompanyDetails={this.props.setCompanyDetails} />}
                    </div>
                    {/* show sign up or log in form based on radio button click */}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);