import loggedReducer from './isLogged';
import counterReducer from './counter';
import companyDetails from './companyDetails'
import {combineReducers} from 'redux';

const allReducers = combineReducers({
	isLogged: loggedReducer,
	counter: counterReducer,
	companyDetails:companyDetails
})

export default allReducers;