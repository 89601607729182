// comments done
import React, { Component } from 'react';
import Navbar from '../Navbar';
import ReactTable from 'react-table/react-table';
import TopNavbar from '../pages/TopNavbar';
// import $ from "jquery"
import 'react-table/react-table.css';
import '../../utils/css/customReact-table.css'
import '../../utils/css/main.css';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../utils/additional components/LoaderComponent'

const mapStateToProps = (state) =>
{
	return { isLogged: state.isLogged }
}

class DeliveryHistory extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			showTable: false,
			date: null,
			loading: false,
		}
	}
	innerRoutes = null;

	showTable = () =>
	{
		this.setState({
			showTable: true,
		})
	}
	//this function recieves the data and send the necessary post request with the date
	fetchData = (date) =>
	{
		console.log(date);
		this.setState({ loading: true })
		const url = "https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/DeliveryHistory.json";
		axios.get(url)
			.then((response) => response.data)
			.then(routes =>
			{
				console.log('RED', routes)
				this.innerRoutes = routes;
				this.setState({
					loading: false,
					date: date
				})
			})
			.catch(error => this.setState({
				error
			}))

	}
	render()
	{
		if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
			return <Redirect to='/' />
		const columns = [
			{
				Header: "Delivery ID",
				accessor: "id",
				width: 120,
				maxWidth: 120,
				minWidth: 120,
				filterable: true
			},
			{
				Header: "Date and Time of Delivery",
				accessor: 'date_time',
				width: 240,
				maxWidth: 240,
				minWidth: 240,
				filterable: true
			},
			{
				Header: "Delivery Status",
				accessor: "del_stat",
				width: 150,
				maxWidth: 150,
				minWidth: 150,
				filterable: true
			},
			{
				Header: "Delivery Boy Name",
				accessor: 'boy_name',
				width: 180,
				maxWidth: 180,
				minWidth: 180,
				filterable: true
			},
			{
				Header: "Delivery Boy Id",
				accessor: 'boy_id',
				width: 150,
				maxWidth: 150,
				minWidth: 150,
				filterable: true
			},
			{
				Header: "Signature POD",
				accessor: "sign",
				width: 150,
				maxWidth: 150,
				minWidth: 150
			},
			{
				Header: "Picture POD",
				accessor: "pic",
				width: 120,
				maxWidth: 120,
				minWidth: 120
			},
			{
				Header: "Original Delivery Address",
				accessor: 'orig_add',
				width: 240,
				maxWidth: 240,
				minWidth: 240

			},
			{
				Header: "Delivered Address",
				accessor: 'actual_add',
				width: 180,
				maxWidth: 180,
				minWidth: 180
			},
			{
				Header: "Distance b/w addresses",
				accessor: 'dist',
				width: 240,
				maxWidth: 240,
				minWidth: 240
			},
		];
		let data = []
		// prepping table data
		for (let id in this.innerRoutes)
		{

			let temp = this.innerRoutes[id]
			let obj = {}
			obj["id"] = temp["id"]
			obj["date_time"] = temp["date_time"]
			obj["del_stat"] = temp["del_stat"]
			obj["boy_name"] = temp["boy_name"]
			obj["boy_id"] = temp["boy_id"]
			obj["sign"] = <a href={temp["sign"]} target="_blank" rel="noopener noreferrer">{temp["sign"]}</a>
			obj["pic"] = <a href={temp["pic"]} target="_blank" rel="noopener noreferrer">{temp["pic"]}</a>
			obj["orig_add"] = temp["orig_add"]
			obj["actual_add"] = temp["actual_add"]
			obj["dist"] = temp["dist"]
			data.push(obj)
		}
		return (
			<div>

				<TopNavbar title={"Delivery History"} />

				<Navbar highlighted={'DeliveryHistory'} />
				<div className="wrapper">
					<div className="main_content">
						<div className='pad' style={{ 'paddingTop': '0.5rem' }}>
							<div className="header">
								<div className="welcomeContainer" >
									<div className="mainLine" >
										Pick Date
										</div>
									<div className='subLine' >
										To view deliveries
										</div>
								</div>

							</div>
							{this.state.loading && <Loader />}
							<div class="date-group" style={{ marginLeft: '1%', marginTop: '2%', width: '18%' }}>
								{/* <div for="pickdate" className='mainLine' style={{'width':'100%'}}>Pick Date</div> */}
								{/* <div for="pickdate" className='subLine' style={{'width':'100%'}}>Select date to view deliveries</div> */}
								<input type="date" class="form-control" id="pickdate" aria-describedby="pick date" placeholder="Enter the project date" required onChange={(e) => { this.showTable(); this.fetchData(e.target.value) }} max={new Date().toISOString().substring(0, 10)}></input>
							</div>
							{this.state.showTable &&
								<div className='rtContainer2'>
									<ReactTable
										columns={columns}
										data={data}
										showPaginationBottom={true}
										noDataText={"Loading..."}
										showPageSizeOptions={true}
										pageSizeOptions={[5, 10, 20, 25]}
										defaultPageSize={10}
										className='-striped -highlight -width1'
									// loading={this.state.loading}
									>

									</ReactTable>
								</div>
							}
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default connect(mapStateToProps)(DeliveryHistory);
