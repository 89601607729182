// comments done
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { signout } from '../actions/index'
import styles from '../../utils/css/settingsSubMenu.module.css'

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: (payload) => { dispatch(signout(payload)) }
	}
}
const mapStateToProps = (state) => {
	return {
		isLogged: state.isLogged
	}
}
// this is the left side menu visible on the settings page, onClick function is received as a prop from the SettingsPage
class SettingsSubMenu extends Component {
	render() {
		return (
			<div className={styles.wrapperSettingMenu}>

				<div className={styles.SettingMenu}>
					<ul>

						<li className={this.props.highlighted === 'Profile' ? styles.highlighted : ''} onClick={()=>this.props.onClick('Profile')}><Link to="#" >Profile
						</Link></li>


						<li className={this.props.highlighted === 'ChangePassword' ? styles.highlighted : ''} onClick={()=>this.props.onClick('ChangePassword')}>
							<Link to="#">Change Password
							</Link>
						</li>

                        <li className={this.props.highlighted === 'Appearance' ? styles.highlighted : ''} onClick={()=>this.props.onClick('Appearance')}>
							<Link to="#">Appearance
							</Link>
						</li>

					</ul>

				</div>
			</div >
		);
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(SettingsSubMenu);
