// comments done
/*global google*/
import React from "react";
import
{
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
} from "react-google-maps";
import MarkerWithInfoWindow from '../additional components/MarkerWithInfoWindowOneAtaTime.js'
import '../../utils/css/main.css'
import axios from 'axios'
import Loader from '../../utils/additional components/LoaderComponent'
const stylesLight = require('../json/mapColors.json')
const stylesDark = require('../json/mapColorsDark.json')
let styles = null
class Map extends React.Component
{
    constructor(props)
    {
        // mostly same as DashboardMap
        super(props);
        this.state = {
            allDirectionsReady: false,
            allDirectionsRendered: false,
            directionService: [],
            driver: [],
            loading: false,
            newMarkers: false,// flags for the destination markers when new data fetched
            currentOpenInfoWindow: 0,
            markerClicked: false,// flags for the destination markers when a marker is clicked
            avg_lat: 13.0827,
            avg_long: 80.2707,
            renderMarkerSwitch: false,//now using a switch to control the rendering of the destination markers on the map
            selectedDriver: null,//keeps track of the driver which is highlighted when the mouse is hovering on any marker belonging to this driver
            activeDriver: null,//keeps track of which driver is activated so that even if you hover out from the driver's marker, the numbered marker along with his route will still be visible
            searchText: '',//search text from input text field used to filter/search for drivers
            liveNewMarkers: false,// flags for the live markers when new data fetched
            liveMarkersClicked: false,// flags for the live markers when a marker is clicked
            liveRenderMarkerSwitch: false//now using a switch to control the rendering of the live markers on the map
        };
    }
    innerRoutes = null;
    distances = null;//dictionary of driver id and distance
    phoneno = null;//dictionary of driver id and phone no
    total_distance = null;//total distances of all drivers
    driverWaypts = []
    colors = [
        "black", "blue", "brown", "cyan", "green", "indigo", "limegreen", "magenta", "navy", "olive", "orange", "purple", "red", "teal", "turquoise", "yellow", "grey"
    ];
    markerRendered = [];//array of detination markers
    liveMarkers = []//array of live markers
    driverCtr = 0;
    noOfRoutes = null;
    allDirections = [];
    allDrivers = [];
    id_ctr = 0;
    driver_color = {}//dictionary mapping driver id to his color
    disabledMarkers = []
    allowedMarkers = []
    driver_li = []//array of list elements to be rendered in the side menu
    driverid_name = {}//dictionary mapping driver id to his name
    interval_id = null
    driverLive = []//data fetched for live markers
    liveLocationKeyOffset = 0
    fetchData1 = () =>
    {
        console.log('FETCH 1')
        if (this.state.allDirectionsReady === false)
        {

            console.log('RED((( setting state')
            this.setState({ loading: true })

            const url = "https://live-tracking-zalt.an.r.appspot.com/getlocation";
            axios.post(url)
                .then((response) => response.data)
                .then((routes) =>
                {
                    console.log('CONTENT IMPORTANT', routes)
                    this.innerRoutes = routes['drivers'];
                    this.noOfRoutes = Object.keys(this.innerRoutes).length;
                    this.distances = {}
                    this.phoneno = {}
                    for (let driverId in this.innerRoutes)
                    {
                        this.distances[driverId] = this.innerRoutes[driverId]["distance"]
                        this.phoneno[driverId] = this.innerRoutes[driverId]["phoneno"]
                    }
                    console.log('NEW', this.distances, this.phoneno)
                    const DirectionsService = new google.maps.DirectionsService();

                    for (let driver in this.innerRoutes)
                    {
                        let waypts = [];
                        // creating array of waypts except first and last stop
                        for (var i = 1; i < this.innerRoutes[driver]["routes"].length - 1; i++)
                        {
                            waypts.push({
                                location: {
                                    lat: this.innerRoutes[driver]["routes"][i][0],
                                    lng: this.innerRoutes[driver]["routes"][i][1],
                                },
                                stopover: true,
                            });
                        }
                        let last = this.innerRoutes[driver]["routes"].length - 1;
                        DirectionsService.route({
                            origin: new google.maps.LatLng(
                                this.innerRoutes[driver]["routes"][0][0],
                                this.innerRoutes[driver]["routes"][0][1]
                            ),
                            destination: new google.maps.LatLng(
                                this.innerRoutes[driver]["routes"][last][0],
                                this.innerRoutes[driver]["routes"][last][1]
                            ),
                            waypoints: waypts,
                            optimizeWaypoints: true,
                            travelMode: google.maps.TravelMode.DRIVING,
                        },
                            (result, status) =>
                            {
                                if (status === google.maps.DirectionsStatus.OK)
                                {
                                    this.allDirections = this.allDirections.concat(result);
                                    this.allDrivers = this.allDrivers.concat(driver);
                                    this.driverCtr = this.driverCtr + 1;
                                    this.driver_color[driver] = this.colors[this.driverCtr % 17]
                                    this.driverid_name[driver] = this.innerRoutes[driver]["name"]
                                    if (this.driverCtr === this.noOfRoutes)
                                    {
                                        console.log('CONTENT:::', this.allDirections)
                                        console.log('RED((( setting state')
                                        this.setState({
                                            directionService: this.allDirections,
                                            allDirectionsReady: true,
                                            driver: this.allDrivers,
                                            loading: false,
                                            newMarkers: true,
                                            renderMarkerSwitch: false
                                        });
                                        console.log('RED((( after setting above state renderMarkerSwitch:', this.state.renderMarkerSwitch)
                                    }
                                } else
                                {
                                    console.error(`error fetching directions ${result}`);
                                }
                            }
                        );
                    }
                })
                .catch(error => console.log(error))

        }
    }
    fetchData2 = () =>
    {
        console.log('FETCH 2')
        axios.post('https://live-tracking-zalt.an.r.appspot.com/getnextlocation')
            .then((response) => response.data)
            .then(
                (data) =>
                {
                    this.driverLive = data['drivers'];
                    console.log('FETECH2', data["drivers"], this.driverLive)

                    this.setState({ liveNewMarkers: true, liveRenderMarkerSwitch: false })
                }
            )
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error }))
    }
    fetchData3 = () =>
    {
        console.log('FETCH 3')
        axios.post('https://live-tracking-zalt.an.r.appspot.com/getnextlocation')
            .then((response) => response.data)
            .then(
                (data) =>
                {
                    console.log('IMPORTANT', data)
                    this.driverLive = data['drivers'];
                    console.log('FETECH2', data["drivers"], this.driverLive)

                    this.setState({ liveNewMarkers: true, liveRenderMarkerSwitch: false })
                }
            )
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error }))
    }
    componentDidMount = () =>
    {
        let uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
        if (uiBgNo === 1)
            styles = stylesDark
        else
            styles = stylesLight


        this.fetchData1();
        setTimeout(() =>
        {
            this.fetchData2();
            this.interval_id = setInterval(() =>
            {
                this.fetchData3();
            }, 3000);

        }, 1000);

    };
    componentWillUnmount = () =>
    {
        clearInterval(this.interval_id)
    }

    // function to openwindow
    openInfoWindow = (markerKey) =>
    {
        // if there is driver which is being hovered on initiall and then its marker clicked, then that driver will become active
        if (this.state.selectedDriver)
            this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true, renderMarkerSwitch: false, activeDriver: this.state.selectedDriver, selectedDriver: null, liveMarkersClicked: true, liveRenderMarkerSwitch: false })
        else // if I click on a different marker of an already active driver, then the active driver remains the same
            this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true, renderMarkerSwitch: false, activeDriver: this.state.activeDriver, selectedDriver: null, liveMarkersClicked: true, liveRenderMarkerSwitch: false })
    }

    closeOpenWindow = () =>
    {
        console.log('RED((( setting state')
        this.setState({ currentOpenInfoWindow: 0, markerClicked: true, renderMarkerSwitch: false, liveMarkersClicked: true, liveRenderMarkerSwitch: false })
        console.log('RED((( after setting close infowindow state: ', this.state.renderMarkerSwitch)
    }

    // functions defined for when you hover on a driver either on a map marker or via the left side menu li items
    displayMarkerHover = (driver) =>
    {
        console.log('SELECTED:', driver, "ACTIVE:", this.state.activeDriver)
        this.setState({ selectedDriver: driver, newMarkers: true, liveNewMarkers: true })
    }

    hideMarkerHover = () =>
    {
        this.setState({ selectedDriver: null, newMarkers: true, liveNewMarkers: true })
    }

    // functions defined when you want to make a driver active either on a map marker or via the left side menu li items
    displayMarkerActive = (driver) =>
    {
        this.setState({ activeDriver: driver, newMarkers: true, currentOpenInfoWindow: 0, liveNewMarkers: true })
    }

    hideMarkerActive = () =>
    {
        this.setState({ activeDriver: null, newMarkers: true, currentOpenInfoWindow: 0, liveNewMarkers: true })
    }


    renderCtr = 0;
    render = () =>
    {
        console.log('RED((( $render called')
        let avg_lat = 13.0827;
        let avg_long = 80.2707;
        let no_of_waypts = 0;
        var color = "#ff0000";
        let pathsRendered = [];
        if (this.state.allDirectionsReady === true)
        {

            console.log('RED((( inside outerif')
            // eslint-disable-next-line
            pathsRendered = this.state.directionService.map( // eslint-disable-next-line
                (direction, i) =>
                {
                    color = this.driver_color[this.state.driver[i]];
                    // will render paths only if driver selected or actie
                    if (this.state.driver[i] === this.state.selectedDriver || this.state.driver[i] === this.state.activeDriver)
                        return (
                            <DirectionsRenderer
                                directions={direction}
                                options={{
                                    polylineOptions: { strokeColor: color },
                                    suppressMarkers: true,
                                    preserveViewport: true
                                }}
                            />
                        );
                }
            );

            if (this.state.newMarkers === true || this.state.markerClicked === true)
            {
                this.driver_li = []
                this.renderCtr = this.renderCtr + 1;
                console.log('RED(((0', this.renderCtr, this.state.newMarkers, this.state.markerClicked, this.state.renderMarkerSwitch)
                this.markerRendered = []
                let markerKey = 0;
                avg_lat = 0; avg_long = 0;
                this.total_distance = 0;
                for (let driver in this.innerRoutes)
                {
                    this.total_distance = this.total_distance + parseFloat(this.distances[driver]);
                    // if the search key is present in this driver's name, then this driver's markers will be rendered
                    if (this.innerRoutes[driver]["name"].toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1)
                    {
                        color = this.driver_color[driver];

                        this.driver_li = this.driver_li.concat(
                            <li onClick={(e) =>
                            {
                                console.log('CLICKED', driver)
                                // TOGGLE logic for side menu
                                if (this.state.activeDriver !== driver)
                                    this.displayMarkerActive(driver)
                                else
                                    this.hideMarkerActive()
                            }}
                                className={this.state.activeDriver === driver ? 'li_active' : this.state.selectedDriver === driver ? 'li_highlighted' : ''}
                                onMouseOver={() => this.displayMarkerHover(driver)} onMouseOut={() => this.hideMarkerHover()}
                            >
                                <div>
                                    <i class="fas fa-motorcycle" style={{ marginRight: '0.5rem' }}></i>{this.innerRoutes[driver]["name"]}
                                </div>
                                <div className='menuSubline'>
                                    <div>
                                        <i class="fas fa-road" style={{ marginRight: '0.2rem' }}></i>{this.distances[driver]} km
                                    </div>
                                    <div>
                                        <i class="fas fa-map-marker-alt" style={{ marginRight: '0.2rem' }}></i>{this.innerRoutes[driver]["routes"].length} stops
                                    </div>
                                    <div>
                                        <i class="fas fa-phone-alt" style={{ marginRight: '0.2rem' }}></i>{this.phoneno[driver]}
                                    </div>
                                </div>
                            </li>
                        );

                        for (let j = 0; j < this.innerRoutes[driver]["routes"].length; j++)
                        {
                            markerKey += 1;
                            if (driver === this.state.selectedDriver || driver === this.state.activeDriver)
                            {
                                let loc = this.innerRoutes[driver]["routes"][j];
                                console.log('AVERAGE:', avg_lat, parseFloat(loc[0]), avg_lat + parseFloat(loc[0]))
                                avg_lat += parseFloat(loc[0]);
                                avg_long += parseFloat(loc[1]);
                                no_of_waypts += 1;

                                let content =
                                    <div style={{ 'color': 'black' }}>
                                        <span style={{ fontWeight: 'bold' }}>Driver Name : </span><span >{this.innerRoutes[driver]["name"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: 'bold' }}>Address : </span><span >{this.innerRoutes[driver]["routes"][j][3]}</span>
                                    </div>

                                console.log('CONTENT', j, (content))


                                this.markerRendered = this.markerRendered.concat(
                                    <MarkerWithInfoWindow
                                        loc={loc}
                                        color={color}
                                        j={j}
                                        content={content}
                                        flag={'location'}

                                        key={markerKey}
                                        // props for hover and active functionality
                                        driver={driver}
                                        selectedDriver={this.state.selectedDriver}
                                        activeDriver={this.state.activeDriver}
                                        onMouseOver={() => this.displayMarkerHover(driver)}
                                        onMouseOut={() => this.hideMarkerHover()}
                                        // props for opening of info window
                                        markerKey={markerKey}
                                        currentOpenInfoWindow={this.state.currentOpenInfoWindow}
                                        openInfoWindow={(mK) => this.openInfoWindow(mK)}
                                        closeOpenWindow={() => this.closeOpenWindow()}
                                    />
                                );
                            }

                        }
                        this.liveLocationKeyOffset = markerKey
                        // offset to set live marker keys starting from this number
                    }

                }
                console.log('AVERAGE CENTER', avg_lat, avg_long, no_of_waypts)
                avg_lat /= no_of_waypts;
                avg_long /= no_of_waypts;
                console.log('AVERAGE CENTER', avg_lat, avg_long)

                // eslint-disable-next-line
                this.state.newMarkers = false;
                // eslint-disable-next-line
                this.state.markerClicked = false;
                // eslint-disable-next-line
                this.state.allDirectionsRendered = true;
                // eslint-disable-next-line
                this.state.avg_lat = avg_lat;
                // eslint-disable-next-line
                this.state.avg_long = avg_long;
                // eslint-disable-next-line
                this.state.renderMarkerSwitch = true;
                // this.setState({
                // 	newMarkers: false,
                // 	markerClicked: false,
                // 	renderMarkerSwitch: true
                // })
            }


            if (this.state.liveNewMarkers === true || this.state.liveMarkersClicked === true)
            {
                this.liveMarkers = []
                let markerKey = 0;

                for (let driver in this.driverLive)
                {
                    if (this.driverLive[driver]["name"].toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1)
                    {
                        color = this.driver_color[driver];

                        let arr = [this.driverLive[driver]["CurrentLocation"]]

                        for (let k = 0; k < arr.length; k++)
                        {
                            markerKey += 1;
                            let markerIcon = 'normal';
                            let loc = arr[k].split(", ")
                            let content = <div style={{ color: "black" }}>{this.driverLive[driver]["NextDeliveryAddress"]}</div>
                            if (k === 0)
                            {
                                content =
                                    <div style={{ color: "black" }}>
                                        <span style={{ fontWeight: "bold" }}>Driver Name: </span><span>{this.driverLive[driver]["name"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Battery Status: </span><span>{this.driverLive[driver]["BatteryStatus"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Next Delivery Address: </span><span>{this.driverLive[driver]["NextDeliveryAddress"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Last Updated: </span><span>{this.driverLive[driver]["LastUpdated"]}</span>
                                    </div>
                                markerIcon = 'bike';
                            }
                            let mk = markerKey + this.liveLocationKeyOffset
                            console.log('FETECH keys live', mk)
                            this.liveMarkers = this.liveMarkers.concat(
                                <MarkerWithInfoWindow
                                    loc={loc}
                                    color={color}
                                    j={k}
                                    content={content}
                                    flag={markerIcon}

                                    key={mk}

                                    markerKey={mk}
                                    currentOpenInfoWindow={this.state.currentOpenInfoWindow}
                                    openInfoWindow={(markerKey) => this.openInfoWindow(markerKey)}
                                    closeOpenWindow={() => this.closeOpenWindow()}

                                    driver={driver}
                                    selectedDriver={this.state.selectedDriver}
                                    activeDriver={this.state.activeDriver}
                                    onMouseOver={() => this.displayMarkerHover(driver)}
                                    onMouseOut={() => this.hideMarkerHover()}
                                />
                            )
                        }

                    }

                }
                // eslint-disable-next-line
                this.state.liveNewMarkers = false;
                // eslint-disable-next-line
                this.state.liveMarkersClicked = false;
                // eslint-disable-next-line
                this.state.liveRenderMarkerSwitch = true;
                console.log('FETECH 2', this.liveMarkers, this.state.liveRenderMarkerSwitch)

            }
        }

        return (
            <div className='DMwrapper'>
                <div className='wrapperSideMenu2'>

                    <div className='sideMenu'>
                        <div className="sideMenuHeading">
                            Drivers
                            <br></br>
                            <div className="menuSubline2">
                                <div>
                                    <span>Total Distance:</span>{this.total_distance} km
                                </div>
                                <div>
                                    <span>Total Routes:</span>{this.noOfRoutes} routes
                                </div>
                            </div>
                            <div className='form-group'>
                                <input type='text' placeholder='Search' onChange={(e) => { console.log(e.target.value); this.setState({ searchText: e.target.value, newMarkers: true, activeDriver: null, selectedDriver: null, liveNewMarkers: true }) }}></input>
                            </div>
                        </div>
                        <ul className='sideMenuList'>
                            {this.driver_li}
                        </ul>

                    </div>
                </div>

                {this.state.loading ?
                    null
                    :
                    <GoogleMap
                        defaultZoom={12}
                        defaultCenter={{ lat: this.state.avg_lat, lng: this.state.avg_long }}
                        defaultOptions={{
                            disableDefaultUI: false, // disable default map UI
                            draggable: true, // make map draggable
                            keyboardShortcuts: true, // disable keyboard shortcuts
                            scaleControl: true, // allow scale controle
                            scrollwheel: true, // allow scroll wheel
                            styles: styles // change default map styles
                        }}
                    >
                        {this.state.renderMarkerSwitch ? this.markerRendered : null}
                        {this.state.liveRenderMarkerSwitch ? this.liveMarkers : null}
                        {pathsRendered}
                    </GoogleMap>

                }

                {this.state.loading && <Loader />}


            </div>
        );
    };
}

const MapComponent = withScriptjs(withGoogleMap((props) => <Map />));

export default () => (
    <MapComponent
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj7orpbCxKhi3BugBoGMO9sXwIJC4EWzo&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
            <div className="sideMapContainer2" />
        }
        mapElement={<div className='mapElement' style={{ height: `100%`, width: '100%' }} />}
    />
);
