// no comments required - pretty straightfoward
import React, { Component } from 'react';
import '../../utils/css/main.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
class ModalCreateProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pname: 'add',
            pdate: 'date',
            isOpen: true,
        }
    }
    handleFormSubmit = () => {
        this.props.onSubmit(this.state.pname, this.state.pdate);
        this.props.onClick();
    }
    render() {

        return (
            <Modal isOpen={this.state.isOpen} toggle={() => { this.props.onClick() }} backdrop={'static'} keyboard={false} >
                <ModalHeader toggle={() => { this.props.onClick() }}>Create Project</ModalHeader>
                <form class='text-left' onSubmit={() => { this.handleFormSubmit() }}>
                <ModalBody>
                    <div class="form-group">
                        <label for="ProjectName">Project Name</label>
                        <input type="text" class="form-control" id="ProjectName" placeholder="Enter Project Name" required onChange={(e) => { this.setState({ pname: e.target.value }) }}></input>
                    </div>
                    <div class="form-group">
                        <label for="pickdate">Pick Date</label>
                        <input type="date" class="form-control" id="pickdate" aria-describedby="pick date" placeholder="Enter the project date" required
                            onChange={(e) => {
                                this.setState({ pdate: e.target.value })
                            }}
                            min={new Date().toISOString().substring(0, 10)}
                            max={new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().substring(0, 10)}
                        ></input>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" class="btn btn-primary float-left primary-btn">Submit</button>
                    <button type="button" className="btn btn-default normal-btn" onClick={() => { this.props.onClick() }}>Close</button>
                </ModalFooter>
                </form>

            </Modal >

        );
    }
}
export default ModalCreateProject