//comments done
import React, { Component } from 'react';

import Navbar from '../Navbar';
import '../../utils/css/main.css';
import TopNavbar from '../pages/TopNavbar'

//Packages
import Papa from 'papaparse';
import axios from 'axios';
import Cookies from 'js-cookie';

//ReactTable@6.8.6
import ReactTable from 'react-table/react-table';
import 'react-table/react-table.css';
import '../../utils/css/customReact-table.css'
import ModalCreateProject from '../../utils/additional components/ModalCreateProject'
// import sampleData from '../../resources/createProjectSample.json'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Loader from '../../utils/additional components/LoaderComponent'

const mapStateToProps = (state) => {
	return { isLogged: state.isLogged }
}

class CreateProject extends Component {

	constructor() {
		super();
		this.state = {
			csvfile: undefined,
			jsondata: [],
			isFileChosen: false,
			isFileUploaded: false,
			fileTobeViewed: false,
			i: 1,
			posts: [],
			loading: false,
			goToChooseProject: false,
			mountModal: false,
		};
		this.updateCSVData = this.updateCSVData.bind(this);
	}
	unmountModal = () => {
		this.setState({
			mountModal: false
		})
	}
	componentDidMount() {
		this.setState({ loading: true })
		const url = "https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/CreateProjectSample.json";
		axios.get(url)
        .then((response) => response.data)
		.then(posts => {
			console.log('RED', posts)
			this.setState({ posts: posts, loading: false })
		})
		.catch(error => this.setState({
					error
				}))

	}

	handleCSVUpload = event => {
		this.setState({
			csvfile: event.target.files[0],
			isFileChosen: true
		});
	};

	importCSVToJSON = () => {
		const { csvfile } = this.state;
		Papa.parse(csvfile, {
			complete: this.updateCSVData,
			header: true,
			skipEmptyLines: true
		});
		this.setState({ isFileUploaded: true })
		this.setState({ fileTobeViewed: true })
	};

	updateCSVData(result) {
		var data = result.data;
		this.setState({ jsondata: data });
		console.log(this.state.jsondata);
	}

	chooseProject = (rid, name) => {//rid->row id and name
		console.log(rid, name)
		let value = "all"
		let csrftoken = Cookies.get('csrftoken')
		console.log(csrftoken)
		let formdata = new FormData()
		formdata.append("value", value)
		formdata.append("csrfmiddlewaretoken", csrftoken)
		formdata.append("api", "true")

		axios({
			url: '/route_planner/projects_list/',
			method: 'POST',
			withCredentials: true,
			data: formdata,
			credentials: 'include'
		})
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				console.log("error logging in");
			})
	}
	//function handling creation of new project from create project button
	createProject = (pname, pdate) => {
		console.log('PNAME:', pname)
		console.log("PDATE", pdate)
		let project_name = pname
		let type = "C"
		let csrftoken = Cookies.get('csrftoken')
		let sessionid = Cookies.get('sessionid')
		console.log(csrftoken)
		let formdata = new FormData()
		formdata.append("csrfmiddlewaretoken", csrftoken)
		formdata.append("sessionid", sessionid)
		formdata.append("project_name", project_name)
		formdata.append("type", type)
		formdata.append("api", "true")
		axios({
			url: '/route_planner/project/',
			method: 'POST',
			withCredentials: true,
			data: formdata
		}).then((response) => {
			console.log(response.data);
		}).catch((error) => {
			console.log("error logging in");
		})
		this.goToChooseProject()
	}

	goToChooseProject = () => {
		console.log('Choose project called!');
		this.setState({ goToChooseProject: true });
	}
	pushCSV = () => {
		let uploadFile = this.state.csvfile
		console.log(uploadFile)
		let formdata = new FormData()
		let csrftoken = Cookies.get('csrftoken')
		let sessionid = Cookies.get('sessionid')
		formdata.append("csrfmiddlewaretoken", csrftoken)
		formdata.append("sessionid", sessionid)
		formdata.append('file', uploadFile)

		axios({
			url: '/route_planner/upload_targets/',
			method: 'POST',
			withCredentials: true,
			data: formdata,
		})
			.then((response) => {
				console.log(response.data);
			})

			.catch((error) => {
				console.log("error logging in");
			})

	};


	render() {
		//return to login pae if not logged in
		if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
			return <Redirect to='/' />
		if (this.state.goToChooseProject)
			return <Redirect to='/ChooseProject' />
		const columns = [
			{
				Header: "Order Id",
				accessor: "id",
			},
			{
				Header: "Order Name",
				accessor: "name",
			}
		];
		let data = []//data prep for react table
		for (let rid in this.state.posts['list']) {
			data.push({ 'id': rid, 'name': this.state.posts['list'][rid] })
		}
		return (
			<div>
				<TopNavbar title={"Create Project"} />
				<Navbar highlighted={'CreateProject'} />
				<div className="wrapper">
					<div className="main_content">
						<div className='pad'>
							<div className="header">
								<div className="welcomeContainer" >
									<div className="mainLine" >
										Create a new project
										</div>
									<div className='subLine' >
										Or choose an old one
										</div>
								</div>
								{/* this is button below is used for mounting the create project modal */}
								<div className='buttonText' data-toggle="modal" data-target="#myModal">
									<div className='buttonContainer' onClick={() => { this.setState({ mountModal: true }); }}>
										<div className='buttonText' ><span style={{ marginRight: '8px' }}><i class="fas fa-plus"></i></span>Create Project</div>
									</div>
								</div>
							</div>
							{this.state.loading && <Loader />}
							{this.state.mountModal ?
								<ModalCreateProject onSubmit={(a, b) => { this.createProject(a, b) }} onClick={() => { this.unmountModal() }} />
								:
								null
							}



							<div className='rtContainer2'>
								<ReactTable
									columns={columns}
									data={data}
									showPaginationBottom={true}
									noDataText={"Loading..."}
									showPageSizeOptions={true}
									pageSizeOptions={[5, 10, 20, 25]}
									defaultPageSize={10}
									// loading={this.state.loading}
									className='-striped -highlight'
									sortable={true}
									filterable={true}
									getTrProps={(state, rowInfo, column, instance) => {
										return {
											onClick: (e, handleOriginal) => {
												this.chooseProject(rowInfo['original']['id'], rowInfo['original']['name']);
												// IMPORTANT! React-Table uses onClick internally to trigger
												// events like expanding SubComponents and pivots.
												// By default a custom 'onClick' handler will override this functionality.
												// If you want to fire the original onClick handler, call the
												// 'handleOriginal' function.
												this.goToChooseProject();
												if (handleOriginal) {
													handleOriginal()
												}
											}

										}
									}}
								>

								</ReactTable>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

export default connect(mapStateToProps)(CreateProject);
