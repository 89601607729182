// no comments required
import React, { Component } from 'react';

import '../../utils/css/main.css';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'

import Map from '../../utils/additional components/Orders2Map';
import '../../utils/css/MapStyle.css';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
const mapStateToProps = (state) =>
{
    return { isLogged: state.isLogged }
}
class Orders extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            driverNames:null
        }
    }
    render()
    {
        if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
            return <Redirect to='/' />
        return (
            <div>
                <TopNavbar title={"Orders 2"} buttons={[{ title: "Orders", Link: "/Orders" }]} />
                <Navbar highlighted={'Orders'} />

                <div className="wrapper text-center">
                    <div className="main_content" style={{ height: '97vh', display: 'flex', flexDirection: 'row' }}>

                            <Map />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Orders);
