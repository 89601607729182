// comments done
import React, { Component } from 'react';

import '../../utils/css/main.css';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'
import styles from '../../utils/css/settingsSubMenu.module.css'
import SettingsSubMenu from "../pages/SettingsSubMenu";
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import SettingsChangePasswordComponent from "./SettingsChangePasswordComponent";
import SettingsProfileComponent from "./SettingsProfileComponent";
import SettingsAppearanceComponent from "./SettingsAppearanceComponent";
const mapStateToProps = (state) => {
	return { isLogged: state.isLogged }
}
class Orders extends Component {
	constructor(props) {
		super(props)
		this.state = {
            settingsOption:'Profile'
		}
    }
    changeSettingSubPage = (page)=>{
        this.setState({settingsOption:page})
        console.log('IMP:', this.state)
    }
	render() {
		if (this.props.isLogged.adminStatus===false&&this.props.isLogged.userStatus===false)
			return <Redirect to='/' />
		return (
			<div>
				<TopNavbar title={"Settings"}/>
				<Navbar/>

				<div className="wrapper">
					<div className={styles.main_content} style={{ height: '97vh' }}>
                        <SettingsSubMenu onClick={(page)=>{this.changeSettingSubPage(page)}} highlighted={this.state.settingsOption}/>
						{/* displaying different components on the basis of value selected in the state settingsOption variable */}
                        <div className={styles.settingsOptionComponent}>
                            {this.state.settingsOption==='ChangePassword' &&
                                <SettingsChangePasswordComponent />
                            }
                            {this.state.settingsOption==='Profile' &&
                                <SettingsProfileComponent />
                            }
                            {this.state.settingsOption==='Appearance' &&
                                <SettingsAppearanceComponent />
                            }
                        </div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(Orders);
