const initialState = {
    companyName:null,
}
const companyDetails = (state = initialState, action) => {
	switch(action.type){
		case "COMPANY_LOGGED_IN":
			return Object.assign({}, state, {
				companyName:action.payload
			})
		default:
			return state;
	}
}

export default companyDetails;