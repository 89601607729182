// comments done
import React, { Component } from 'react';

import '../../utils/css/main.css';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'

//ReactTable@6.8.6
import ReactTable from 'react-table/react-table';
import 'react-table/react-table.css';
import '../../utils/css/customReact-table.css'
import ModalEditDriver from '../../utils/additional components/ModalEditOnboardRequests'
import sampleData from '../../resources/OnboardRequests.json'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Loader from '../../utils/additional components/LoaderComponent'

const mapStateToProps = (state) =>
{
    return { isLogged: state.isLogged }
}

class OnboardRequests extends Component
{
    constructor()
    {
        super();
        this.state = {
            driverDetailsObj: null,
            showEditModal: false,
            posts: [],
            deleted_phnos: [],
            loading: false
        };
    }

    componentDidMount()
    {
        this.setState({ loading: true })
        const url = "https://jsonplaceholder.typicode.com/posts";
        fetch(url, {
            method: "GET"
        }).then(response => response.json()).then(posts =>
        {
            this.setState({ posts: posts, loading: false })
        })

    }
    unmountModal = () =>
    {
        this.setState({
            showEditModal: false
        })
    }
    deleteId = (phoneno) =>
    {
        this.setState(
            {
                deleted_phnos: this.state.deleted_phnos.concat(phoneno)
            }
        )
    }
    driver_obj = {}
    render()
    {
        if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
            return <Redirect to='/' />
        const columns = [
            {
                Header: "No",
                accessor: "no",
                width: 60,
            },
            {
                Header: "Name",
                accessor: "name",
                width: 270,
                filterable: true
            },
            {
                Header: "Email Id",
                accessor: "email",
                width: 240,
            },
            {
                Header: "Phone No",
                accessor: "phoneno",
                width: 150,
                filterable: true
            },
            {
                Header: "Present Address",
                accessor: "present_addr",
                width: 400,
            }
        ];
        let data = []
        let index = 0;
        for (let rid in sampleData)
        {
            let obj = {
                'name': sampleData[rid]['name'],
                'email': sampleData[rid]['email'],
                'phoneno': sampleData[rid]['phoneno'],
                'perm_addr': sampleData[rid]['perm_addr'],
                'id_proof': sampleData[rid]['id_proof'],
                'perm_addr_proof': sampleData[rid]['perm_addr_proof'],
                'dl_no': sampleData[rid]['dl_no'],
                'dl_proof': sampleData[rid]['dl_proof'],
                'dl_expiry_proof': sampleData[rid]['dl_expiry_proof'],
                'rc_proof': sampleData[rid]['rc_proof'],
                'insr_company': sampleData[rid]['insr_company'],
                'insr_policy_no': sampleData[rid]['insr_policy_no'],
                'insr_expiry_date': sampleData[rid]['insr_expiry_date'],
                'insr_proof': sampleData[rid]['insr_proof'],
                'v_type': sampleData[rid]['v_type'],
                'v_brand': sampleData[rid]['v_brand'],
                'v_model': sampleData[rid]['v_model'],
                'v_no': sampleData[rid]['v_no'],
                'v_proof': sampleData[rid]['v_proof'],
                'puc_proof': sampleData[rid]['puc_proof'],
                "puc_expiry": sampleData[rid]['puc_expiry'],
                'dob': sampleData[rid]['dob'],
                'blood_group': sampleData[rid]['blood_group'],
                'mother_tongue': sampleData[rid]['mother_tongue'],
                'ed_qual': sampleData[rid]['ed_qual'],
                'gender': sampleData[rid]['gender'],
                'mother_name': sampleData[rid]['mother_name'],
                'father_name': sampleData[rid]['father_name'],
                'present_addr': sampleData[rid]['present_addr'],
                'state': sampleData[rid]['state'],
                'city': sampleData[rid]['city'],
                'pincode': sampleData[rid]['pincode'],
                'pan_no': sampleData[rid]['pan_no'],
                'pan_proof': sampleData[rid]['pan_proof'],
                'aadhaar_no': sampleData[rid]['aadhaar_no'],
                'aadhaar_proof': sampleData[rid]['aadhaar_proof'],
                'bank_acno': sampleData[rid]['bank_acno'],
                'bank_ifsc': sampleData[rid]['bank_ifsc'],
                'bank_proof': sampleData[rid]['bank_proof'],
            }
            this.driver_obj[obj["phoneno"]] = obj;
            // creating driver id-driver details dictionary to be used to send data to the edit onboard request modal
            if (this.state.deleted_phnos.includes(rid))
                continue
            index++;
            data.push(
                {
                    'no': index,//just used to number rows in table data
                    'name': sampleData[rid]['name'],
                    'email': sampleData[rid]['email'],
                    'phoneno': sampleData[rid]['phoneno'],
                    'present_addr': sampleData[rid]['present_addr']
                }
            )
        }

        return (
            <div>
                <TopNavbar title={"Onboard Requests"} buttons={[{ title: "Driver Details", Link: "/DriverDetails" }]} />
                <Navbar highlighted={'DriverDetails'} />
                <div className="wrapper">
                    <div className="main_content">
                        <div className='pad'>
                            <div className="header">
                                <div className="welcomeContainer" >
                                    <div className="mainLine" >
                                        Click on row
    									</div>
                                    <div className='subLine' >
                                        To accept, edit or delete onboard requests.
    									</div>
                                </div>
                            </div>
                            {this.state.loading && <Loader />}

                            {this.state.showEditModal ? <ModalEditDriver details={this.state.driverDetailsObj} onClick={() => { this.unmountModal() }} deleteId={(phoneno) => { this.deleteId(phoneno) }} /> : null}

                            <div className='rtContainer2'>
                                <ReactTable
                                    columns={columns}
                                    data={data}
                                    showPaginationBottom={true}
                                    noDataText={"Loading..."}
                                    showPageSizeOptions={true}
                                    pageSizeOptions={[5, 10, 20, 25]}
                                    defaultPageSize={10}
                                    // loading={this.state.loading}
                                    className='-striped -highlight -width1'
                                    getTrProps={(state, rowInfo, column, instance) =>
                                    {
                                        return {
                                            'data-toggle': "modal",
                                            'data-target': "#editDriverModal",
                                            onClick: (e, handleOriginal) =>
                                            {

                                                let obj = this.driver_obj[rowInfo['original']['phoneno']]
                                                this.setState({
                                                    driverDetailsObj: obj,
                                                    showEditModal: true,
                                                })
                                                // IMPORTANT! React-Table uses onClick internally to trigger
                                                // events like expanding SubComponents and pivots.
                                                // By default a custom 'onClick' handler will override this functionality.
                                                // If you want to fire the original onClick handler, call the
                                                // 'handleOriginal' function.
                                                if (handleOriginal)
                                                {
                                                    handleOriginal()
                                                }
                                            }

                                        }
                                    }}
                                >

                                </ReactTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(mapStateToProps)(OnboardRequests);
