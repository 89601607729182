//comments done
import React, { Component } from 'react';

import Navbar from '../Navbar';
import '../../utils/css/main.css';

//Packages
import Papa from 'papaparse';
import axios from 'axios';
//import Cookies from 'js-cookie';

//ReactTable@6.8.6
import ReactTable from 'react-table/react-table';
import '../../utils/css/customReact-table.css'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import TopNavbar from '../pages/TopNavbar'
// dropzone for dragging and dropping files
import Dropzone from 'react-dropzone'
import Loader from '../../utils/additional components/LoaderComponent'
const mapStateToProps = (state) =>
{
	return { isLogged: state.isLogged }
}

class ChooseProject extends Component
{

	constructor()
	{
		super();
		this.state = {
			csvfile: [],//for storing the files in csv format
			jsondata: [],//for storing all the data from all the files directly concatenated to each other
			jsonfiles: [],//for storing all the data from the files as distinct subarrays within this parent array
			isFileChosen: false,//flags for
			isFileUploaded: false,//flags for
			fileTobeViewed: false,//flags for
			i: 1,//not defined by me, existed in previous code
			posts: [],//not defined by me, existed in previous code
			loading: false,
			no_of_files_uploaded: 0,
			tabledata: [],
			fileNoActive: 0,
			fileNames: [],
			errorMessage: null,
			successMessage: null,
			showLoader: false,
			optimizerText: false//flag to decide whether to show "Optimising..." or "Loading..." in the loader
		};
		this.updateCSVData = this.updateCSVData.bind(this);
	}

	componentDidMount()
	{
		this.setState({ loading: true, showLoader: true })
		//below is not my code, was present from before
		const url = "https://jsonplaceholder.typicode.com/posts";
		fetch(url, {
			method: "GET"
		}).then(response => response.json()).then(posts =>
		{
			this.setState({ posts: posts, loading: false, showLoader: false })
		})
	}



	importCSVToJSON = () =>
	{
		let csvfiles = this.state.csvfile;//all the files uploaded in csv format
		// this.setState({ jsondata: [], no_of_files_uploaded: 0, jsonfiles: [] })
		for (let i = 0; i < csvfiles.length; i++)
		{
			let csvfile = csvfiles[i];
			Papa.parse(csvfile, {
				complete: (result) => { this.updateCSVData(result, csvfile) },
				header: true,
				skipEmptyLines: true
			});
		}

		this.setState({ isFileUploaded: true })
		this.setState({ fileTobeViewed: true, isFileChosen: false, csvfile: [] })
	};

	updateCSVData(result, csvfile)
	{
		var data = result.data;
		console.log('RED', data)
		//note the difference how you are concatenating data to jsondata and jsonfiles
		this.setState({ jsondata: this.state.jsondata.concat(data), no_of_files_uploaded: this.state.no_of_files_uploaded + 1, jsonfiles: this.state.jsonfiles.concat([data]), fileNames: this.state.fileNames.concat(csvfile.name) });
		this.setState({ tabledata: this.state.jsonfiles[0], fileNoActive: 0 })
		console.log(this.state.jsonfiles);
	}

	pushCSV = () =>
	{
		let uploadFile = this.state.jsondata//all the data concatenated together
		console.log('pushCSV()', uploadFile)
		let formdata = new FormData()
		formdata.append('csvfile', uploadFile)
		axios({
			url: '/route_planner/upload_targs/',
			method: 'POST',

			data: "asdhbsakdj",
			files: {
				"file":
				{
					filename: formdata
				}
			}


		})
			.then((response) =>
			{
				console.log(response.data);
			})
			.catch((error) =>
			{
				console.log("error logging in");
			})
		console.log('csv pushed!')

		//temporary code for showing loader for 2 seconds after clicking on optimise routes
		this.setState({ showLoader: true, optimizerText: true })
		setTimeout(() =>
		{
			this.setState({ showLoader: false, optimizerText: false })
		}, 2000);
	};

	handleCSVUpload = (event) =>
	{
		console.log('RED csvfile', event.target.files)
		this.setState({
			csvfile: event.target.files,
			isFileChosen: true
		});
	};
	//below is slightly different from one above, handling error messages and success messages
	//above one is redundant, and can be removed(need to check again to be sure)
	handleCSVUpload2 = (files) =>
	{
		console.log('RED csvfile', files)
		this.setState({
			csvfile: this.state.csvfile.concat(files),
			isFileChosen: true,
			errorMessage: null,
			successMessage: "SUCCESS: You may upload more csv files."
		});
	};
	// setting a particular file to be shown in the table
	setTableData = (i) =>
	{
		this.setState({ tabledata: this.state.jsonfiles[i], fileNoActive: i })
	}
	//handler function when clicking on cross svg to delete an uploaded file
	handleSvgClick = () =>
	{
		console.log('SVG CLICKED', this.state.fileNoActive)
		let jsonfiles = []
		let fileNames = []
		let jsondata = []
		console.log('RED BEFORE', this.state.jsonfiles)
		for (let i = 0; i < this.state.jsonfiles.length; i++)
		{
			if (i === this.state.fileNoActive)
				continue;
			jsonfiles = jsonfiles.concat([this.state.jsonfiles[i]]);
			jsondata = jsondata.concat(this.state.jsonfiles[i])
			fileNames = fileNames.concat(this.state.fileNames[i]);
		}
		let tabledata = [];
		let fileTobeViewed = true;
		let isFileUploaded = true;
		console.log('RED AFTER', jsonfiles)
		if (this.state.no_of_files_uploaded === 1)
		{
			tabledata = [];
			fileTobeViewed = false;
			isFileUploaded = false;
		}
		else
			tabledata = jsonfiles[0];

		this.setState({
			jsonfiles: jsonfiles,
			jsondata: jsondata,
			fileNames: fileNames,
			tabledata: tabledata,
			fileTobeViewed: fileTobeViewed,
			isFileUploaded: isFileUploaded,
			fileNoActive: 0,
			no_of_files_uploaded: this.state.no_of_files_uploaded - 1
		})
	}
	render()
	{
		if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
			return <Redirect to='/' />
		const columns = [
			{
				Header: "Order Id",
				accessor: "Consigner Order Id",
				width: 200,
				maxWidth: 200,
				minWidth: 200
			},
			{
				Header: "Origin",
				accessor: "From Warehouse",
				width: 125,
				maxWidth: 125,
				minWidth: 125
			},
			{
				Header: "City",
				accessor: "City",
				width: 125,
				maxWidth: 125,
				minWidth: 125
			},
			{
				Header: "Detailed Address",
				accessor: "Detail Address",
				width: 400,
				maxWidth: 400,
				minWidth: 400
			},
			{
				Header: "manifest route code",
				accessor: "manifest route code",
				width: 250,
				maxWidth: 250,
				minWidth: 250
			},
			{
				Header: "Order Amount",
				accessor: "Order Amount",
				width: 200,
				maxWidth: 200,
				minWidth: 200
			},

		];
		let radios = []
		//have a separate case for only file uploaded and multiple files uploaded becausein the case where suppose 2 files were uploaded, and imagine I am currently viewing the 2nd file, so the radio button 2 is selected. Ondeleteion of this 2nd file, although the contents of the first file will be displayed automatically, the 1st radio button is not selected, since previously before deleting the radio button2 was selected. Hence therefore to force the radio button1 to be selected, not how I am using checked instead of defaultChecked in the first and second case resp. A better solution can be found I guess, this was just a temporary fix
		if (this.state.no_of_files_uploaded === 1)
		{
			let temp = this.state.no_of_file_uploaded;
			radios = this.state.jsonfiles.map((file, i) =>
			{
				console.log('radios rerendered', i)
				return <div style={{ marginRight: '1rem' }}>
					{i === 0 ?
						<input type="radio" id={i + 1} name={String(temp)} value={i + 1} checked={true} onClick={() => { this.setTableData(i) }}></input>
						:
						<input type="radio" id={i + 1} name={String(temp)} value={i + 1} onClick={() => { this.setTableData(i) }}></input>
					}
					<label for={i + 1}>File {i + 1}</label>
				</div>
			})
		}
		else
			if (this.state.no_of_files_uploaded > 1)
			{
				let temp = this.state.no_of_file_uploaded;
				console.log('rendering radios!', this.state.jsonfiles)
				radios = this.state.jsonfiles.map((file, i) =>
				{
					console.log('radios rerendered', i)
					return <div style={{ marginRight: '1rem' }}>
						{i === 0 ?
							<input type="radio" id={i + 1} name={String(temp)} value={i + 1} defaultChecked={true} onClick={() => { this.setTableData(i) }}></input>
							:
							<input type="radio" id={i + 1} name={String(temp)} value={i + 1} onClick={() => { this.setTableData(i) }}></input>
						}
						<label for={i + 1}>File {i + 1}</label>
					</div>
				})
				console.log('RED', this.state.fileNames[this.state.fileNoActive])
			}
		return (
			<div>
				<TopNavbar title={"Choose Project"} buttons={[{ title: "Create Project", Link: "/CreateProject" }]} />
				<Navbar highlighted={'CreateProject'} />
				<div className="wrapper">
					<div className="main_content">
						{this.state.showLoader &&
							<Loader text={this.state.optimizerText ? "Optimizing" : "Loading"} />
						}
						<div className='pad'>
							<div className="header text-center">
								<div className="welcomeContainer" >
									<div className="mainLine" >
										Upload Data
									</div>
									<div className='subLine' >
										For current project
									</div>
								</div>
							</div>
							<div style={{ paddingTop: '5vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
								<div style={{ 'width': '90%' }}>
									<div className="text-black left font-weight-600" style={{ marginBottom: '0.5rem' }}>Import CSV File</div>

									<Dropzone onDrop={acceptedFiles => { console.log(acceptedFiles); this.handleCSVUpload2(acceptedFiles) }} accept={['.csv']} onDropRejected={() => { this.setState({ errorMessage: 'WARNING: File rejected. Only csv files are allowed.', successMessage: null }) }}>
										{({
											getRootProps,
											getInputProps,
											isDragActive,
											isDragAccept,
											isDragReject
										}) =>
										{
											// additional CSS depends on dragging status and file acceptance status
											const additionalClass = isDragAccept
												? 'accept'
												: isDragReject
													? 'reject'
													: '';
											const additionalClass2 = isDragActive ?
												'dragActive' : ''
											return (
												<div
													{...getRootProps({
														className: `dropzone ${additionalClass} ${additionalClass2}`
													})}
												>
													<input {...getInputProps()} />
													<span>{isDragActive ? "📂" : "📁"}</span>
													<p>{isDragActive ? "Drop file(s) here!" : "Drag 'n' drop csv files, or click to select files"}</p>
													<div className='success'>{this.state.successMessage}</div>
													<div className='error'>{this.state.errorMessage}</div>
												</div>
											);
										}}
									</Dropzone>

									<div>
										<div className='text-dark2' style={{ fontWeight: 'bold', marginTop: '0.5rem' }}>Files:</div>
										<ul>
											{this.state.csvfile.map(fileName => (
												<li key={fileName.name} className='text-dark2'>{fileName.name}</li>
											))}
										</ul>
									</div>

									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
										{this.state.isFileChosen ?
											<button className="btn primary-btn" onClick={() => { this.importCSVToJSON() }} style={{ marginTop: '0.5rem' }}>
												View now
											</button>
											:
											<button className="btn primary-btn" onClick={(e) => { (window.alert('Please Upload a CSV File!')) }} style={{ marginTop: '0.5rem' }}>
												View now
											</button>
										}
										{this.state.isFileUploaded ?
											<div>
												<button className="btn primary-btn" onClick={() => this.pushCSV()} style={{ marginTop: '0.5rem' }}>Optimize Routes</button>
											</div>
											:
											null
										}
									</div>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: "flex-start", marginTop: '1rem' }}>
										{radios}
									</div>
									{/* cross svg */}
									<div className='text-dark2 fileNameContainer'>
										{this.state.fileTobeViewed && this.state.fileNames[this.state.fileNoActive]}
										{this.state.fileTobeViewed && <svg height="16" viewBox="0 0 512 512" width="16" xmlns="http://www.w3.org/2000/svg" onClick={() => this.handleSvgClick()}><path className='path1' d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#8a8a8a" /><path className='path2' d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg>}
									</div>
								</div>
							</div>


							<div className='rtContainer2'>
								<ReactTable
									columns={columns}
									data={this.state.tabledata}
									showPaginationBottom={true}
									noDataText={"Please upload CSV file"}
									showPageSizeOptions={true}
									pageSizeOptions={[5, 10, 20, 25]}
									defaultPageSize={10}
									className='-striped -highlight -width1'
									sortable={true}
									filterable={true}
								// loading={this.state.loading}
								>

								</ReactTable>
							</div>


							{/* <div className="text-white left">

							</div> */}
						</div>
					</div>
				</div>
			</div >

		);
	}
}

export default connect(mapStateToProps)(ChooseProject);
