// comments done
/*global google*/
import React from "react";
// import routes from "../../resources/OrdersPage.json";
import
{
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	DirectionsRenderer,
} from "react-google-maps";
import MarkerWithInfoWindow from '../additional components/MarkerWithInfoWindowOneAtaTime.js'
import Timeline, { DateHeader, TimelineHeaders } from 'react-calendar-timeline'
import '../../utils/css/OrderPageTimeline.css'
import '../../utils/css/main.css'
import moment from 'moment'
import axios from 'axios'
import Loader from '../../utils/additional components/LoaderComponent'
const stylesLight = require('../json/mapColors.json')
const stylesDark = require('../json/mapColorsDark.json')
let styles = null
class Map extends React.Component
{
	// refer dashboard map, pretty similar
	constructor(props)
	{
		super(props);
		this.state = {
			allDirectionsReady: false,
			allDirectionsRendered: false,
			directionService: [],
			driver: [],
			loading: false,
			groups: [],
			items: [],
			newMarkers: false,
			currentOpenInfoWindow: 0,
			markerClicked: false,
			avg_lat: 13.0827,
			avg_long: 80.2707,
			renderMarkerSwitch: false
		};
	}
	innerRoutes = null;
	colors = [
		"black", "blue", "brown", "cyan", "green", "indigo", "limegreen", "magenta", "navy", "olive", "orange", "purple", "red", "teal", "turquoise", "yellow", "grey"
	];
	markerRendered = [];
	driverCtr = 0;
	noOfRoutes = null;
	allDirections = [];
	allDrivers = [];
	allGroups = [];
	allItems = [];
	id_ctr = 0;
	driver_color = {}
	componentDidMount = () =>
	{
		let uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
		if (uiBgNo === 1)
			styles = stylesDark
		else
			styles = stylesLight

		if (this.state.allDirectionsReady === false)
		{

			console.log('RED((( setting state')
			this.setState({ loading: true })

			const url = "https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/OrdersPage.json";
			axios.get(url)
				.then((response) => response.data)
				.then((routes) =>
				{
					console.log('CONTENT IMPORTANT', routes)
					this.innerRoutes = routes['routes'];
					this.noOfRoutes = Object.keys(this.innerRoutes).length;

					const DirectionsService = new google.maps.DirectionsService();

					for (let driver in this.innerRoutes)
					{
						let waypts = [];

						for (var i = 1; i < this.innerRoutes[driver].length - 1; i++)
						{
							waypts.push({
								location: {
									lat: this.innerRoutes[driver][i][0],
									lng: this.innerRoutes[driver][i][1],
								},
								stopover: true,
							});
						}
						let last = this.innerRoutes[driver].length - 1;
						DirectionsService.route({
							origin: new google.maps.LatLng(
								this.innerRoutes[driver][0][0],
								this.innerRoutes[driver][0][1]
							),
							destination: new google.maps.LatLng(
								this.innerRoutes[driver][last][0],
								this.innerRoutes[driver][last][1]
							),
							waypoints: waypts,
							optimizeWaypoints: true,
							travelMode: google.maps.TravelMode.DRIVING,
						},
							(result, status) =>
							{
								if (status === google.maps.DirectionsStatus.OK)
								{
									this.allDirections = this.allDirections.concat(result);
									this.allDrivers = this.allDrivers.concat(driver);
									this.driverCtr = this.driverCtr + 1;
									this.allGroups = this.allGroups.concat(
										{
											'id': this.driverCtr,
											'title': driver
										}
									)
									this.driver_color[driver] = this.colors[this.driverCtr % 17]

									for (let w = 0; w < this.innerRoutes[driver].length; w++)
									{
										let time = this.innerRoutes[driver][w][2]

										this.allItems = this.allItems.concat({
											id: this.id_ctr,
											group: this.driverCtr,
											title: w + ' :' + this.innerRoutes[driver][w][3],
											start_time: moment().hour(time.substring(0, time.indexOf(':'))).minute(time.substring(time.indexOf(':') + 1)),
											end_time: moment().hour(time.substring(0, time.indexOf(':'))).minute(time.substring(time.indexOf(':') + 1)).add(15, 'minutes'),
											canMove: false,
											canResize: false,
											canChangeGroup: false,
											itemProps: {
												style: {
													background: this.driver_color[driver],
													color: 'white',
													textAlign: 'left',
													border: 'none',
													borderRadius: '3px',
													fontWeight: 'bold',
												}
											}
										})
										this.id_ctr = this.id_ctr + 1;
									}
									if (this.driverCtr === this.noOfRoutes)
									{
										console.log('CONTENT:::', this.allDirections)
										console.log('RED((( setting state')
										this.setState({
											directionService: this.allDirections,
											allDirectionsReady: true,
											driver: this.allDrivers,
											loading: false,
											groups: this.allGroups,
											items: this.allItems,
											newMarkers: true,
											renderMarkerSwitch: false
										});
										console.log('RED((( after setting above state renderMarkerSwitch:', this.state.renderMarkerSwitch)
									}
								} else
								{
									console.error(`error fetching directions ${result}`);
								}
							}
						);
					}
				})
				.catch(error => console.log(error))

		}
	};
	openInfoWindow = (markerKey) =>
	{
		console.log('RED((( setting state')
		this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true, renderMarkerSwitch: false })
		console.log('RED((( after setting openinfowindow state: ', this.state.currentOpenInfoWindow, this.state.markerClicked, this.state.renderMarkerSwitch)
	}
	closeOpenWindow = () =>
	{
		console.log('RED((( setting state')
		this.setState({ currentOpenInfoWindow: 0, markerClicked: true, renderMarkerSwitch: false })
		console.log('RED((( after setting close infowindow state: ', this.state.renderMarkerSwitch)
	}
	deleteMarkers = () =>
	{
		this.setState({ renderMarkerSwitch: false })
	}
	renderCtr = 0;
	render = () =>
	{
		console.log('RED((( $render called')
		let avg_lat = 13.0827;
		let avg_long = 80.2707;
		let no_of_waypts = 0;
		var color = "#ff0000";
		let pathsRendered = [];
		if (this.state.allDirectionsReady === true)
		{

			console.log('RED((( inside outerif')
			pathsRendered = this.state.directionService.map(
				(direction, i) =>
				{
					color = this.driver_color[this.state.driver[i]];
					return (
						<DirectionsRenderer
							directions={direction}
							options={{
								polylineOptions: { strokeColor: color },
								suppressMarkers: true,
							}}
						/>
					);
				}
			);

			if (this.state.newMarkers === true || this.state.markerClicked === true)
			{
				this.renderCtr = this.renderCtr + 1;
				console.log('RED(((0', this.renderCtr, this.state.newMarkers, this.state.markerClicked, this.state.renderMarkerSwitch)
				this.markerRendered = []
				let markerKey = 0;
				avg_lat=0; avg_long=0;
				for (let driver in this.innerRoutes)
				{
					color = this.driver_color[driver];
					for (var j = 0; j < this.innerRoutes[driver].length; j++)
					{
						markerKey += 1;
						let loc = this.innerRoutes[driver][j];
						avg_lat += parseFloat(loc[0]);
						avg_long += parseFloat(loc[1]);
						no_of_waypts += 1;

						let content =
							<div style={{ 'color': 'black' }}>
								<span style={{ fontWeight: 'bold' }}>Driver Name : </span><span >{driver}</span>
								<br></br>
								<span style={{ fontWeight: 'bold' }}>Address : </span><span >{this.innerRoutes[driver][j][3]}</span>
							</div>

						console.log('CONTENT', j, (content))

						this.markerRendered = this.markerRendered.concat(
							<MarkerWithInfoWindow loc={loc} color={color} j={j} content={content} flag={'normal'} markerKey={markerKey} currentOpenInfoWindow={this.state.currentOpenInfoWindow} openInfoWindow={(markerKey) => this.openInfoWindow(markerKey)} closeOpenWindow={() => this.closeOpenWindow()} />
						);
					}
				}
				avg_lat /= no_of_waypts;
				avg_long /= no_of_waypts;
				console.log('AVERAGE CENTER', avg_lat, avg_long)
				console.log('RED((( setting state')

				// eslint-disable-next-line
				this.state.newMarkers = false;
				// eslint-disable-next-line
				this.state.markerClicked = false;
				// eslint-disable-next-line
				this.state.allDirectionsRendered = true;
				// eslint-disable-next-line
				this.state.avg_lat = avg_lat;
				// eslint-disable-next-line
				this.state.avg_long = avg_long;
				// eslint-disable-next-line
				this.state.renderMarkerSwitch = true;
				// this.setState({
				// 	newMarkers: false,
				// 	markerClicked: false,
				// 	renderMarkerSwitch: true
				// })
				console.log('RED(((2', this.renderCtr, this.state.newMarkers, this.state.markerClicked)

			}
		}
		console.log('RED(((3 renderMarkerSwitch', this.state.renderMarkerSwitch, this.markerRendered)
		return (
			<div className='DMwrapper'>
				{this.state.loading && <Loader />}
				{this.state.loading ?
					<div className='loadingContainer'>
						Loading...
					</div>
					:
					null
				}
				{this.state.loading ?
					null
					:
					<GoogleMap
						defaultZoom={8}
						defaultCenter={{ lat: this.state.avg_lat, lng: this.state.avg_long }}
						defaultOptions={{
							disableDefaultUI: false, // disable default map UI
							draggable: true, // make map draggable
							keyboardShortcuts: true, // disable keyboard shortcuts
							scaleControl: true, // allow scale controle
							scrollwheel: true, // allow scroll wheel
							styles: styles // change default map styles
						}}
					>
						{this.state.renderMarkerSwitch ? this.markerRendered : null}
						{pathsRendered}
					</GoogleMap>

				}
				{!this.state.loading ?
					<div className='timeLineContainer'>
						<div className='timeLineContainerHeader'>
							<span className='mainLine'>Timeline</span>
							<br></br>
							<span className='subLine' style={{ fontSize: '1rem' }}>{moment().format("dddd, MMMM Do YYYY")}</span>
						</div>
						<div>
							<Timeline
								groups={this.state.groups}
								items={this.state.items}
								defaultTimeStart={moment().hour(7).minute(0)}
								defaultTimeEnd={moment().hour(20).minute(0)}
								minZoom={2 * 60 * 60 * 1000}
								maxZoom={24 * 60 * 60 * 1000}
							>
								<TimelineHeaders>
									<DateHeader labelFormat='HH:mm' />
								</TimelineHeaders>
							</Timeline>
						</div>
					</div>
					:
					null
				}
			</div>
		);
	};
}

const MapComponent = withScriptjs(withGoogleMap((props) => <Map />));

export default () => (
	<MapComponent
		googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj7orpbCxKhi3BugBoGMO9sXwIJC4EWzo&v=3.exp&libraries=geometry,drawing,places"
		loadingElement={<div style={{ height: `100%` }} />}
		containerElement={
			<div className="mapContainer" style={{ height: '100%', width: '100%' }} />
		}
		mapElement={<div className='mapElement' style={{ height: `80%`, width: '100%' }} />}
	/>
);