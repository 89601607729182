const initialState = {
	adminStatus: false,
	userStatus: false,
	superAdminStatus: false,
}
const loggedReducer = (state = initialState, action) => {
	switch (action.type) {
		case "USER_SIGN_IN":
			return Object.assign({}, state, {
				userStatus: true,
				adminStatus: false,
				superAdminStatus: false,
			});
		case "USER_SIGN_OUT":
			return Object.assign({}, state, {
				userStatus: false,
				adminStatus: false,
				superAdminStatus: false,
			});
		case "ADMIN_SIGN_IN":
			return Object.assign({}, state, {
				adminStatus: true,
				userStatus: false,
				superAdminStatus: false,
			});
		case "ADMIN_SIGN_OUT":
			return Object.assign({}, state, {
				adminStatus: false,
				userStatus: false,
				superAdminStatus: false,
			});
		case "SUPERADMIN_SIGN_IN":
			return Object.assign({}, state, {
				superAdminStatus: true,
				adminStatus: false,
				userStatus: false,
			});
		case "SUPERADMIN_SIGN_OUT":
			return Object.assign({}, state, {
				superAdminStatus: false,
				adminStatus: false,
				userStatus: false,
			});
		default:
			return state;
	}
}

export default loggedReducer;