/*global google*/
// comments done
import React from 'react';
import
{
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer
} from "react-google-maps";
import MarkerWithInfoWindow from '../additional components/MarkerWithInfoWindowOneAtaTime.js'
import '../../utils/css/main.css'
import moment from 'moment'
import axios from "axios";
import ReactTable from 'react-table/react-table';
import '../../utils/css/customReact-table.css'
import Loader from '../../utils/additional components/LoaderComponent'
const stylesLight = require('../json/mapColors.json')
const stylesDark = require('../json/mapColorsDark.json')
let styles = null
class Map extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            allDirectionsReady: false,
            allDirectionsRendered: false,
            directionService: [],
            driver: [],
            loading: false,
            newMarkers: false,//to indicate whether to render new markers when new data is fetched
            currentOpenInfoWindow: 0,//to indicate which info window has to be opened on the basis of marker key
            markerClicked: false,//to indicate whether to render new markers when a marker is clicked, so that an info window can be opened
            avg_lat: 13.0827,
            avg_long: 80.2707,
            driverClicked: null,//to keep trackof which driver has been clicked in the table
            tableData: [],
            pathsRendered: [],
            selectedRow: -1//to keep track of which row has been clicked in the table so that its css can be changed
        }
    }
    innerRoutes = null
    colors = [
        "olive", "orange", "purple", "black", "blue", "brown", "cyan", "green", "indigo", "limegreen", "magenta", "navy", "red", "teal", "turquoise", "yellow", "grey"
    ]
    markerRendered = []
    driverCtr = 0
    noOfRoutes = null // used to determine when all the requests' responses have been received from the google api
    allDirections = []
    allDrivers = []
    driver_color = {}
    liveLocationMarkers = []
    interval_id = null
    pathsRendered = []
    fetchIntervalData1 = () =>
    {

        if (this.state.allDirectionsReady === false)
        {
            this.setState({ loading: true })

            axios.post('https://live-tracking-zalt.an.r.appspot.com/getnextlocation')
                .then((response) => response.data)
                .then(
                    (data) =>
                    {
                        this.innerRoutes = data['drivers'];
                        this.noOfRoutes = Object.keys(this.innerRoutes).length;

                        const DirectionsService = new google.maps.DirectionsService();

                        for (let driver in this.innerRoutes)
                        {
                            let str1 = this.innerRoutes[driver]["CurrentLocation"]
                            let str2 = this.innerRoutes[driver]["NextDeliveryLocation"]

                            DirectionsService.route(
                                {
                                    origin: new google.maps.LatLng(parseFloat(str1.substring(0, str1.indexOf(","))), parseFloat(str1.substring(str1.indexOf(",") + 2))),
                                    destination: new google.maps.LatLng(str2.substring(0, str2.indexOf(",")), str2.substring(str2.indexOf(",") + 2)),
                                    travelMode: google.maps.TravelMode.DRIVING,
                                    optimizeWaypoints: true,

                                }, (result, status) =>
                            {
                                if (status === google.maps.DirectionsStatus.OK)
                                {
                                    this.allDirections = this.allDirections.concat(result)
                                    this.allDrivers = this.allDrivers.concat(driver)
                                    this.driverCtr = this.driverCtr + 1

                                    this.driver_color[driver] = this.colors[this.driverCtr % 17]
                                    // once all the responses have been received for all the drivers then set the state
                                    if (this.driverCtr === this.noOfRoutes)
                                    {
                                        this.setState({
                                            directionService: this.state.directionService.concat(this.allDirections),
                                            allDirectionsReady: true,
                                            driver: this.state.driver.concat(this.allDrivers),
                                            loading: false,
                                            newMarkers: true//this set to true to start marker rendering process
                                        })
                                    }
                                }
                                else
                                {
                                    console.error(`error fetching directions ${result}`);
                                }
                            }
                            );
                        }
                    }
                )
                // Catch any errors we hit and update the app
                .catch(error => this.setState({ error }))
        }

    }
    fetchIntervalData2 = () =>
    {
        axios.post('https://live-tracking-zalt.an.r.appspot.com/getnextlocation')
            .then((response) => response.data)
            .then(
                (data) =>
                {
                    this.innerRoutes = data['drivers'];
                    this.noOfRoutes = Object.keys(this.innerRoutes).length
                    this.setState({ newMarkers: true })
                }
            )
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error }))
    }
    openInfoWindow = (markerKey) =>
    {
        this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true })
    }
    closeOpenWindow = () =>
    {
        this.setState({ currentOpenInfoWindow: 0, markerClicked: true })
    }
    componentDidMount = () =>
    {
        this.fetchIntervalData1();
        //fetching data for live location every 20s
        this.interval_id = setInterval(() =>
        {
            this.fetchIntervalData2()
        }, 3000);
        let uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
        if (uiBgNo === 1)
            styles = stylesDark
        else
            styles = stylesLight
    }

    componentWillUnmount = () =>
    {
        clearInterval(this.interval_id)
    }
    render = () =>
    {
        let avg_lat = 13.0827;
        let avg_long = 80.2705;
        let no_of_waypts = 0;
        var color = '#ff0000'//random color just to initialise the variables
        let data = []
        //only when all responses have been received from the google direction api
        if (this.state.allDirectionsReady === true)
        {
            this.pathsRendered = []
            this.pathsRendered = this.state.directionService.map((direction, i) =>
            {
                if (this.state.driverClicked === this.state.driver[i] || this.state.driverClicked === null)
                {
                    color = this.driver_color[this.state.driver[i]]
                    return <DirectionsRenderer directions={direction} options={{
                        polylineOptions: { strokeColor: color },
                        suppressMarkers: true
                    }} />
                }
                else return null;
            });
            if (this.state.newMarkers === true || this.state.markerClicked === true)
            {
                this.markerRendered = []
                let markerKey = 0;
                data = []
                avg_lat = 0; avg_long = 0;
                for (let driver in this.innerRoutes)
                {
                    color = this.driver_color[driver]
                    let arr = [this.innerRoutes[driver]["CurrentLocation"], this.innerRoutes[driver]["NextDeliveryLocation"]]
                    let temp = this.innerRoutes[driver]
                    data.push({
                        'no': driver,
                        'name': temp['name'],
                        'bat_stat': temp['BatteryStatus'],
                        'curr_loc': temp['CurrentLocation'],
                        'next_deliv_loc': temp['NextDeliveryLocation'],
                        'next_deliv_addr': temp['NextDeliveryAddress'],
                        'last_up': temp['LastUpdated']
                    })
                    //only if a driver is selected or no driver is selected is when the markers of that driver will be rendered
                    if (this.state.driverClicked === driver || this.state.driverClicked === null)
                    {
                        for (let j = 0; j < arr.length; j++)
                        {
                            markerKey += 1;
                            let markerIcon = 'normal';
                            let loc = arr[j].split(", ")
                            avg_lat += parseFloat(loc[0]);
                            avg_long += parseFloat(loc[1]);
                            no_of_waypts += 1;
                            let content = <div style={{ color: "black" }}>{this.innerRoutes[driver]["NextDeliveryAddress"]}</div>
                            if (j === 0)
                            {
                                content =
                                    <div style={{ color: "black" }}>
                                        <span style={{ fontWeight: "bold" }}>Driver Name: </span><span>{this.innerRoutes[driver]["name"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Battery Status: </span><span>{this.innerRoutes[driver]["BatteryStatus"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Next Delivery Address: </span><span>{this.innerRoutes[driver]["NextDeliveryAddress"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Last Updated: </span><span>{this.innerRoutes[driver]["LastUpdated"]}</span>
                                    </div>
                                markerIcon = 'bike';
                            }
                            this.markerRendered = this.markerRendered.concat(
                                <MarkerWithInfoWindow loc={loc} color={color} j={j} content={content} flag={markerIcon} markerKey={markerKey} currentOpenInfoWindow={this.state.currentOpenInfoWindow} openInfoWindow={(markerKey) => this.openInfoWindow(markerKey)} closeOpenWindow={() => this.closeOpenWindow()} />
                            )
                        }
                    }
                    avg_lat /= no_of_waypts;
                    avg_long /= no_of_waypts
                    this.setState({ newMarkers: false, allDirectionsRendered: true, markerClicked: false, avg_lat, avg_long, tableData: data, pathsRendered: this.pathsRendered })

                }
                this.setState({ newMarkers: false, allDirectionsRendered: true, markerClicked: false, pathsRendered: this.pathsRendered })

            }

        }
        const columns = [
            {
                Header: "Driver No",
                accessor: "no",
            },
            {
                Header: "Driver Name",
                accessor: "name",
            },
            {
                Header: "Battery Status",
                accessor: "bat_stat",
            },
            {
                Header: "Current Location",
                accessor: "curr_loc",
            },
            {
                Header: "Next Delivery Location",
                accessor: "next_deliv_loc",
            },
            {
                Header: "Next Delivery Address",
                accessor: "next_deliv_addr",
            },
            {
                Header: "Last Updated",
                accessor: "last_up",
            }
        ]
        return (
            <div className='DMwrapper'>

                {this.state.loading ?
                    <div className='loadingContainer'>
                        Loading...
                    </div>
                    :
                    null
                }
                {this.state.loading && <Loader />}
                {this.state.loading ?
                    null
                    :
                    <GoogleMap
                        defaultZoom={8}
                        defaultCenter={{ lat: this.state.avg_lat, lng: this.state.avg_long }}
                        defaultOptions={{
                            disableDefaultUI: false, // disable default map UI
                            draggable: true, // make map draggable
                            keyboardShortcuts: true, // disable keyboard shortcuts
                            scaleControl: true, // allow scale controle
                            scrollwheel: true, // allow scroll wheel
                            styles: styles // change default map styles
                        }}
                    >
                        {this.markerRendered}
                        {this.state.pathsRendered}
                    </GoogleMap>
                }
                {!this.state.loading ?
                    <div className='timeLineContainer'>
                        <div className='timeLineContainerHeader'>
                            <span className='mainLine'>Date</span>
                            <br></br>
                            <span className='subLine' style={{ fontSize: '1rem' }}>{moment().format("dddd, MMMM Do YYYY")}</span>
                        </div>
                        <div className='rtContainer2'>
                            <ReactTable
                                columns={columns}
                                data={this.state.tableData}
                                showPaginationBottom={true}
                                noDataText={"Loading..."}
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 20, 25]}
                                defaultPageSize={10}
                                // loading={this.state.loading}
                                className='-striped -highlight'
                                sortable={true}
                                filterable={true}
                                getTrProps={(state, rowInfo, column, instance) =>
                                {
                                    if (rowInfo && rowInfo.row)
                                    {
                                        return {
                                            onClick: (e, handleOriginal) =>
                                            {
                                                let val = rowInfo['original']['no'];
                                                // logic to TOGGLE row selection
                                                if (this.state.driverClicked === val)
                                                    val = null;
                                                let index = rowInfo.index;
                                                if (this.state.selectedRow === index)
                                                    index = -1;

                                                this.setState({ driverClicked: val, markerClicked: true, selectedRow: index })
                                                // IMPORTANT! React-Table uses onClick internally to trigger
                                                // events like expanding SubComponents and pivots.
                                                // By default a custom 'onClick' handler will override this functionality.
                                                // If you want to fire the original onClick handler, call the
                                                // 'handleOriginal' function.
                                                if (handleOriginal)
                                                {
                                                    handleOriginal()
                                                }
                                            },
                                            style: {
                                                fontWeight: rowInfo.index === this.state.selectedRow ? '600' : 'normal',
                                                backgroundColor: rowInfo.index === this.state.selectedRow ? 'var(--reacttable-hover)' : '',
                                            }

                                        }
                                    }
                                    else return {}
                                }}
                            >

                            </ReactTable>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    };
}

const MapComponent = withScriptjs(withGoogleMap(Map));

export default () => (
    <MapComponent
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj7orpbCxKhi3BugBoGMO9sXwIJC4EWzo&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className='mapContainer' style={{ height: '100%', width: '100%' }} />}
        mapElement={<div className='mapElement' style={{ height: `80%`, width: '100%' }}></div>}
    />
);
