export const increment = () => {
	return {
		type: "INCREMENT"
	}
}

export const decrement = () => {
	return {
		type: "DECREMENT"
	}
}

export const signin = (payload) => {
	if (payload === 'admin')
		return {
			type: "ADMIN_SIGN_IN"
		}
	else if (payload === 'superadmin')
		return {
			type: "SUPERADMIN_SIGN_IN"
		}
	return {
		type: "USER_SIGN_IN"
	}
}
export const signout = (payload) => {
	if (payload === 'admin')
		return {
			type: "ADMIN_SIGN_OUT"
		}
	else if (payload === 'superadmin')
		return {
			type: "SUPERADMIN_SIGN_OUT"
		}
	return {
		type: "USER_SIGN_OUT"
	}
}
export const reset = () => {
	return {
		type: "RESET"
	}
}
export const setCompanyDetails = (payload) => {
	return {
		type: "COMPANY_LOGGED_IN",
		payload: payload
	}
}