// comments done
/*global google*/
import React from 'react';
import { Marker, InfoWindow } from "react-google-maps";
class MarkerWithInfoWindow extends React.Component
{
    // these markers are used for info window one at a time, animation only while info window open
    // markers info window openeed based on its marker key and the currentInfoWindowOpen key
    // also has options for rendering different marker images on the basis of the flag prop passed
    // also has option to be integrated with orders map2 and dashboard map3 hover functionality , need to just pass th appropriate props
    constructor(props)
    {
        super(props);
        this.state = {
            isOpen: false,
            markerKey: this.props.markerKey,
            animation: -1
        }
    }
    handleToggleOn = () =>
    {
        this.setState({
            isOpen: true
        })
    }
    handleToggleOff = () =>
    {
        this.setState({
            isOpen: false
        })
    }
    render = () =>
    {
        let url = "https://raw.githubusercontent.com/anantshukla/Markers-For-Google-Maps-ZALT/master/images/temp/marker_" + this.props.color + "" + (this.props.j + 1) + ".png";

        if (this.props.flag === "location")
        {
            url = "https://raw.githubusercontent.com/anantshukla/Markers-For-Google-Maps-ZALT/master/images/temp/marker_pink_round.png";
        }
        if (this.props.driver && this.props.selectedDriver)
            if (this.props.driver === this.props.selectedDriver)
                url = "https://raw.githubusercontent.com/anantshukla/Markers-For-Google-Maps-ZALT/master/images/temp/marker_" + this.props.color + "" + (this.props.j + 1) + ".png";
        if (this.props.driver && this.props.activeDriver)
            if (this.props.driver === this.props.activeDriver)
                url = "https://raw.githubusercontent.com/anantshukla/Markers-For-Google-Maps-ZALT/master/images/temp/marker_" + this.props.color + "" + (this.props.j + 1) + ".png";

        if (this.props.flag === 'bike')
        {
            url = "https://raw.githubusercontent.com/anantshukla/Markers-For-Google-Maps-ZALT/master/images/temp/marker_bike_" + this.props.color + ".png";
        }
        let animation = -1;
        if (this.state.markerKey === this.props.currentOpenInfoWindow)
            animation = google.maps.Animation.BOUNCE
        return (
            <Marker
                key={this.props.markerKey}
                position={{ lat: parseFloat(this.props.loc[0]), lng: parseFloat(this.props.loc[1]) }}
                options={{ icon: url }}

                onClick={() =>
                {
                    if (this.state.markerKey === this.props.currentOpenInfoWindow)
                        this.props.closeOpenWindow();
                    else
                        this.props.openInfoWindow(this.state.markerKey);
                }}

                onMouseOver={() =>
                {
                    if (this.props.onMouseOver)
                        // if (this.props.driver !== this.props.activeDriver)
                        this.props.onMouseOver()
                }}

                onMouseOut={() =>
                {
                    if (this.props.onMouseOut)
                        // if (this.props.driver !== this.props.activeDriver)
                        this.props.onMouseOut()
                }}
                animation={animation}
            >

                {this.state.markerKey === this.props.currentOpenInfoWindow &&
                    <InfoWindow onCloseClick={() => this.props.closeOpenWindow()}>
                        {this.props.content}
                    </InfoWindow>
                }

            </Marker>
        );
    }
}
export default MarkerWithInfoWindow