import React, { Component } from 'react';
import '../../utils/css/mains.module.css';
class ModalAddDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dno_of_vehicles: 0,
            demail: 'email',
            dphoneno: 0,
        }
    }
    handleFormSubmit = () => {
        console.log(this.state);
    }
    render() {

        return (
            <div id="addRequestModal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Sign Up Request</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <form class='text-left' onSubmit={() => { this.handleFormSubmit() }}>
                            <div className="modal-body">
                                <div class="form-group">
                                    <label for="emailid">Email Id</label>
                                    <input type="email" class="form-control" id="emailid" placeholder="Email Id" required onChange={(e) => {
                                        this.setState({ demail: e.target.value })
                                    }}></input>
                                </div>
                                <div class="form-group">
                                    <label for="phoneno">Phone No</label>
                                    <input type="text" class="form-control" id="phoneno" placeholder="Phone No" required onChange={(e) => { this.setState({ dphoneno: e.target.value }) }} minLength="10" maxLength="10"></input>
                                </div>
                                <div class="form-group">
                                    <label for="no_of_vehicles">No Of Vehicles</label>
                                    <input type="number" class="form-control" id="no_of_vehicles" placeholder="No of Vehicles" required onChange={(e) => { this.setState({ dno_of_vehicles: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className="modal-footer custom-footer">
                                <button type="submit" class="btn btn-primary float-left primary-btn" >Add</button>
                                <button type="button" className="btn btn-default normal-btn" data-dismiss="modal">Close</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}
export default ModalAddDriver