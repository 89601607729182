// comments done
import React, { Component } from 'react';
import '../../utils/css/main.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MapComponent from './DriverAnalyticsMap'
class ModalMap extends Component
{
    // just a wrapper for the driver analytics map
    constructor(props)
    {
        super(props);
        this.state={
            isOpen: true,
        }
    }
    componentWillUnmount=()=>{
    }
    toggle=()=>{
        this.setState({isOpen:!this.state.isOpen})
    }
    render()
    {
		return(
            <Modal isOpen={this.state.isOpen} toggle={()=>{this.props.onClick()}} backdrop={'static'} keyboard={false} size={'xl'} >
                <ModalHeader toggle={()=>{this.props.onClick()}}>Driver Analytics</ModalHeader>
                <ModalBody style={{height:'80vh'}}>
                <MapComponent
		                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj7orpbCxKhi3BugBoGMO9sXwIJC4EWzo&v=3.exp&libraries=geometry,drawing,places"
		                    loadingElement={<div style={{ height: `100%`, width: "100%" }} />}
		                    containerElement={
			                    <div className="mapElement" style={{ height: "100%", width: "100%"}} />
		                    }
                            mapElement={<div style={{ height: `75%`, width: "100%" }} />}
                            routes={this.props.routes}
	                    />
                </ModalBody>
                <ModalFooter>
                  <button type="button" className="btn btn-default normal-btn" data-dismiss="modal" onClick={()=>{this.props.onClick()}}>Close</button>
                </ModalFooter>
            </Modal>
		);
	}
}
export default ModalMap