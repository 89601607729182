// comments done
import React, { Component } from 'react';

import '../../utils/css/main.css';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'

import CsvDownload from 'react-json-to-csv'
//ReactTable@6.8.6
import ReactTable from 'react-table/react-table';
import 'react-table/react-table.css';
import '../../utils/css/customReact-table.css'
import ModalDriver from '../../utils/additional components/ModalAddDriver'
import ModalEditDriver from '../../utils/additional components/ModalEditDriver'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import Loader from '../../utils/additional components/LoaderComponent'

const mapStateToProps = (state) =>
{
	return { isLogged: state.isLogged }
}

class DriverDetails extends Component
{
	constructor()
	{
		super();
		this.state = {
			driverDetailsObj: null,
			showEditModal: false,
			posts: [],
			deleted_ids: [],
			loading: false,
			sampleData: null
		};
	}
	sampleData = null;
	driver_obj = {}
	array_driver_details = []
	data = []
	componentDidMount = () =>
	{
		this.setState({ loading: true })
		const url = "https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/DriverDetails.json";
		axios.get(url)
			.then((response) => response.data)
			.then(routes =>
			{
				console.log('RED', routes)
				this.setState({ sampleData: routes })
				// this is preppiing data for csv download
				for (let rid in this.state.sampleData)
				{
					let obj = {
						'id': this.state.sampleData[rid]['id'],
						'name': this.state.sampleData[rid]['name'],
						'email': this.state.sampleData[rid]['email'],
						'phoneno': this.state.sampleData[rid]['phoneno'],
						'perm_addr': this.state.sampleData[rid]['perm_addr'],
						'id_proof': this.state.sampleData[rid]['id_proof'],
						'perm_addr_proof': this.state.sampleData[rid]['perm_addr_proof'],
						'dl_no': this.state.sampleData[rid]['dl_no'],
						'dl_proof': this.state.sampleData[rid]['dl_proof'],
						'dl_expiry_proof': this.state.sampleData[rid]['dl_expiry_proof'],
						'rc_proof': this.state.sampleData[rid]['rc_proof'],
						'insr_company': this.state.sampleData[rid]['insr_company'],
						'insr_policy_no': this.state.sampleData[rid]['insr_policy_no'],
						'insr_expiry_date': this.state.sampleData[rid]['insr_expiry_date'],
						'insr_proof': this.state.sampleData[rid]['insr_proof'],
						'v_type': this.state.sampleData[rid]['v_type'],
						'v_brand': this.state.sampleData[rid]['v_brand'],
						'v_model': this.state.sampleData[rid]['v_model'],
						'v_no': this.state.sampleData[rid]['v_no'],
						'v_proof': this.state.sampleData[rid]['v_proof'],
						'puc_proof': this.state.sampleData[rid]['puc_proof'],
						"puc_expiry": this.state.sampleData[rid]['puc_expiry'],
						'dob': this.state.sampleData[rid]['dob'],
						'blood_group': this.state.sampleData[rid]['blood_group'],
						'mother_tongue': this.state.sampleData[rid]['mother_tongue'],
						'ed_qual': this.state.sampleData[rid]['ed_qual'],
						'gender': this.state.sampleData[rid]['gender'],
						'mother_name': this.state.sampleData[rid]['mother_name'],
						'father_name': this.state.sampleData[rid]['father_name'],
						'present_addr': this.state.sampleData[rid]['present_addr'],
						'state': this.state.sampleData[rid]['state'],
						'city': this.state.sampleData[rid]['city'],
						'pincode': this.state.sampleData[rid]['pincode'],
						'pan_no': this.state.sampleData[rid]['pan_no'],
						'pan_proof': this.state.sampleData[rid]['pan_proof'],
						'aadhaar_no': this.state.sampleData[rid]['aadhaar_no'],
						'aadhaar_proof': this.state.sampleData[rid]['aadhaar_proof'],
						'bank_acno': this.state.sampleData[rid]['bank_acno'],
						'bank_ifsc': this.state.sampleData[rid]['bank_ifsc'],
						'bank_proof': this.state.sampleData[rid]['bank_proof'],
					}
					this.driver_obj[obj["id"]] = obj;
					this.array_driver_details = this.array_driver_details.concat(obj)
				}
				console.log('RED###', this.data, this.driver_obj)
				this.setState({
					loading: false
				})
			})
			.catch(error => this.setState({
				error
			}))

	}
	unmountModal = () =>
	{
		this.setState({
			showEditModal: false
		})
	}
	deleteId = (d_id) =>
	{
		console.log('id being deleted:', d_id, typeof (d_id))
		this.setState(
			{
				deleted_ids: this.state.deleted_ids.concat(d_id)//making an array of deleted ids
			}
		)
	}
	render()
	{
		if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
			return <Redirect to='/' />
		const columns = [
			{
				Header: "Id",
				accessor: "id",
				width: 60,
				filterable: true
			},
			{
				Header: "Name",
				accessor: "name",
				width: 270,
				filterable: true
			},
			{
				Header: "Vehicle Type",
				accessor: "v_type",
				width: 120,
				filterable: true
			},
			{
				Header: "Email Id",
				accessor: "email",
				width: 240,
			},
			{
				Header: "Phone No",
				accessor: "phoneno",
				width: 150,
				filterable: true
			},
			{
				Header: "Address",
				accessor: "present_addr",
			}
		];
		this.data = []
		for (let rid in this.state.sampleData)
		{
			if (this.state.deleted_ids.includes(rid))
				continue
			this.data.push({
				'id': this.state.sampleData[rid]['id'],
				'name': this.state.sampleData[rid]['name'],
				'v_type': this.state.sampleData[rid]['v_type'],
				'email': this.state.sampleData[rid]['email'],
				'phoneno': this.state.sampleData[rid]['phoneno'],
				'present_addr': this.state.sampleData[rid]['present_addr'],
			})
		}
		return (
			<div>
				<TopNavbar title={"Driver Details"} buttons={[{ title: "Onboard Requests", Link: "/OnboardRequests" }]} />
				<Navbar highlighted={'DriverDetails'} />
				<div className="wrapper">
					<div className="main_content">
						<div className='pad'>
							<div className="header">
								<div className="welcomeContainer" >
									<div className="mainLine" >
										Click on row
										</div>
									<div className='subLine' >
										To edit or delete driver details
										</div>
								</div>
								<div className='driverDetailsButtons'>
									<CsvDownload filename="Driver Details Master.csv" data={this.array_driver_details} className='downloadCsvButton' />
									<div className='buttonText' data-toggle="modal" data-target="#addDriverModal">
										<div className='buttonContainer'>
											<div className='buttonText'><span style={{ marginRight: '8px' }}><i class="fas fa-plus"></i></span>Add Driver  </div>
										</div>
									</div>
								</div>

							</div>
							{this.state.loading && <Loader />}

							{/* this modal is toggled by the add driver button */}
							<ModalDriver />

							{this.state.showEditModal ? <ModalEditDriver details={this.state.driverDetailsObj} onClick={() => { this.unmountModal() }} deleteId={(d_id) => { this.deleteId(d_id) }} /> : null}
							<div className='rtContainer2'>
								<ReactTable
									columns={columns}
									data={this.data}
									showPaginationBottom={true}
									noDataText={"Loading..."}
									showPageSizeOptions={true}
									pageSizeOptions={[5, 10, 20, 25]}
									defaultPageSize={10}
									// loading={this.state.loading}
									className='-striped -highlight -width1'
									getTrProps={(state, rowInfo, column, instance) =>
									{
										return {
											'data-toggle': "modal",
											'data-target': "#editDriverModal",
											onClick: (e, handleOriginal) =>
											{
												let obj = this.driver_obj[rowInfo['original']['id']]
												// getting driver details on the basis of the id of the row clicked to be passed to the edit driver modal
												this.setState({
													driverDetailsObj: obj,
													showEditModal: true,
												})
												// IMPORTANT! React-Table uses onClick internally to trigger
												// events like expanding SubComponents and pivots.
												// By default a custom 'onClick' handler will override this functionality.
												// If you want to fire the original onClick handler, call the
												// 'handleOriginal' function.
												if (handleOriginal)
												{
													handleOriginal()
												}
											}

										}
									}}
								>

								</ReactTable>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

export default connect(mapStateToProps)(DriverDetails);
