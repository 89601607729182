// comments done
import React, { Component } from 'react';
import Calendar from 'react-calendar';
import TopNavbar from '../pages/TopNavbar';
import Navbar from '../Navbar';
// import routes from '../../resources/DeliveryStatistics.json'
import ReactTable from 'react-table/react-table';
import ModalMap from '../../utils/additional components/ModalMap'
import 'react-table/react-table.css';
import '../../utils/css/customReact-table.css'
import '../../utils/css/main.css';
import '../../utils/css/DriverAnalyticsCalendar.css'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import moment from 'moment'
import '../../utils/css/OrderPageTimeline.css';
import Loader from '../../utils/additional components/LoaderComponent'
import axios from 'axios'
const mapStateToProps = (state) =>
{
	return { isLogged: state.isLogged }
}

class DriverAnalytics extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			showTable: false,
			date: null,
			mountMap: false,
			routes: null,//used to pass data to the map modal
			loading: false
		}
	}
	innerRoutes = null;

	fetchData = (value) =>
	{
		console.log('FETCH', value)
		this.setState({ loading: true })
		const url = "https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/DeliveryAnalytics.json";
		axios
			.get(url)
			.then((response) => response.data)
			.then((routes) =>
			{
				console.log("RED", routes);
				this.innerRoutes = routes;
				this.setState({ loading: false })

			})
			.catch((error) =>
				this.setState({
					error,
				})
			);
	}

	showTable = (date) =>
	{
		this.setState({
			showTable: true,
			date: date,
			routes: {}
		})
	}
	unmountModal = () =>
	{
		this.setState({
			mountMap: false
		})
	}
	handleModal = () =>
	{
		if (this.state.mountMap === true)
			return
	}
	handleCalendarClick = (value, event) =>
	{
		console.log(value);
		this.showTable(value);
		this.fetchData(value)
	}
	componentDidMount = () =>
	{

	}
	render()
	{
		if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
			return <Redirect to='/' />
		const columns = [
			{
				Header: "Delivery Boy ID",
				accessor: "id",
			},
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Number of Packages Delivered",
				accessor: "pack",
			},
			{
				Header: "Distance Travelled",
				accessor: "dist",
			},
			{
				Header: "Login Time",
				accessor: "lin",
			},
			{
				Header: "Logout Time",
				accessor: "lout",
			}
		];
		let data = []
		// prepping table data
		for (let driver in this.innerRoutes)
		{
			if (driver === "status" || driver === "time_to_process")
				continue
			let temp = this.innerRoutes[driver]
			// let obj = {}
			// obj[temp["DriverName"]] = temp["route"]
			data.push({
				'id': driver,
				'name': temp["DriverName"],
				'pack': temp["NumberofPackagesDelivered"],
				'dist': temp["DistanceTravelled"],
				'lin': temp["LoginTime"],
				'lout': temp["LogoutTime"],
			})
		}
		return (
			<div>
				<TopNavbar title={"Driver Analytics"} />
				<Navbar highlighted={'DriverAnalytics'} />
				<div className="wrapper">
					<div className="main_content">
						<div className='pad'>
							<div className="header">
								<div className="welcomeContainer" >
									<div className="mainLine" >
										Select date
										</div>
									<div className='subLine' >
										To view deliveries made on that day
										</div>
								</div>
							</div>
							{this.state.loading && <Loader />}
							{this.state.mountMap ? <ModalMap routes={this.state.routes} show={true} onClick={() => { this.unmountModal() }} /> : null}

							<div className='calendarContainer'>
								<Calendar maxDate={new Date()}
									onClickDay={(value, event) => { this.handleCalendarClick(value, event) }}
								/>
							</div>

							{this.state.showTable &&
								<div className='dateLabel'>
									<div className='welcomeContainer'>
										<div className="mainLine" >
											Chosen Date:
										</div>
										<div className='subLine' >
											{moment(this.state.date).format("dddd, MMMM Do YYYY") + ' (' + moment(this.state.date).format('MM/DD/YYYY') + ')'}
										</div>
										<div className='subLine' >
											<strong>Note:</strong> Click on row to view route on map
										</div>
									</div>
								</div>
							}

							{this.state.showTable &&
								<div className='rtContainer2'>
									<ReactTable
										columns={columns}
										data={data}
										showPaginationBottom={true}
										noDataText={"Loading..."}
										showPageSizeOptions={true}
										pageSizeOptions={[3, 5, 10, 20, 25]}
										defaultPageSize={10}
										className='-striped -highlight'
										// loading={this.state.loading}
										sortable={true}
										filterable={true}
										getTrProps={(state, rowInfo, column, instance) =>
										{
											return {
												onClick: (e, handleOriginal) =>
												{
													for (let driver in this.innerRoutes)
													{
														if (driver === rowInfo['original']['id'])
														{
															let temp = this.innerRoutes[driver]
															let obj = {}
															obj[temp["DriverName"]] = temp["route"]
															this.setState({
																routes: obj,
																mountMap: true,
															})
															break
														}
													}
													if (handleOriginal)
													{
														handleOriginal()
													}
												}

											}
										}}
									>

									</ReactTable>
								</div>
							}
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default connect(mapStateToProps)(DriverAnalytics);
