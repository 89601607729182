import React, { Component } from 'react';
import styles from '../utils/css/mains.module.css';
import Navbar from './Navbar'
import TopNavbar from './TopNavbar'
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
const mapStateToProps = (state) =>
{
    return {
        isLogged: state.isLogged,
        companyDetails: state.companyDetails
    }
}
class ViewUsers extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
        }
    }
    componentDidMount()
    {
    }
    render()
    {
        if (this.props.isLogged.superAdminStatus === false)
            return <Redirect to='/' />
        return (
            <div>
                <Navbar highlighted={'ViewUsers'} />
                <TopNavbar title={'View Users'} />
                <div className={styles.wrapper}>
                    <div className={styles.main_content}>
                        <div className={styles.pad}>
                            {/* <div className="header">
                                <div className="welcomeContainer" >
                                    <div className="mainLine" >
                                        Create a new project
										</div>
                                    <div className='subLine' >
                                        Or choose an old one
										</div>
                                </div>
                                <div className='buttonText' data-toggle="modal" data-target="#myModal">
                                    <div className='buttonContainer' onClick={() => { this.setState({ mountModal: true }); }}>
                                        <div className='buttonText' ><span style={{ marginRight: '8px' }}><i class="fas fa-plus"></i></span>Create Project</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ViewUsers);
