// comments done
import React from 'react';
import { Marker, InfoWindow } from "react-google-maps";
class MarkerWithInfoWindow extends React.Component {
    // here the marker info windows will only open on hover, no animation
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.show,
        }
    }
    handleToggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    handleToggleOn = () => {
        console.log('mouse over')
        this.setState({
            isOpen:true
        })
    }
    handleToggleOff = () => {
        this.setState({
            isOpen:false
        })
    }
    render = () => {
        let url = "https://raw.githubusercontent.com/anantshukla/Markers-For-Google-Maps-ZALT/master/images/temp/marker_"+this.props.color+""+(this.props.j + 1)+".png"
        if(this.props.type==="location")
            url = "https://raw.githubusercontent.com/anantshukla/Markers-For-Google-Maps-ZALT/master/images/temp/marker_pink_round.png"
        return (
            <div>
                <Marker
                    position={{ lat: parseFloat(this.props.loc[0]), lng: parseFloat(this.props.loc[1]) }}
                    options={{icon:url}}
                    onClick={() => this.handleToggle()}
                    onMouseOver={() => this.handleToggleOn()}
                    onMouseOut={() => this.handleToggleOff()}
                >
                    {this.state.isOpen &&
                        <InfoWindow onCloseClick={() => this.handleToggleOff()}>
                            {this.props.content}
                        </InfoWindow>
                    }
                </Marker>
            </div>

        );
    }
}
export default MarkerWithInfoWindow