// comments done
/*global google*/
import React from "react";
import
{
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
} from "react-google-maps";
import MarkerWithInfoWindow from '../additional components/MarkerWithInfoWindowOneAtaTime.js'
import '../../utils/css/main.css'
// import moment from 'moment'
import axios from 'axios'
import Loader from '../../utils/additional components/LoaderComponent'
const stylesLight = require('../json/mapColors.json')
const stylesDark = require('../json/mapColorsDark.json')
let styles = null
class Map extends React.Component
{
    // refer DashboardMap3
    constructor(props)
    {
        super(props);
        this.state = {
            allDirectionsReady: false,
            allDirectionsRendered: false,
            directionService: [],
            driver: [],
            loading: false,
            newMarkers: false,
            currentOpenInfoWindow: 0,
            markerClicked: false,
            avg_lat: 13.0827,
            avg_long: 80.2707,
            renderMarkerSwitch: false,
            selectedDriver: null,
            li_click_active: true,
            activeDriver: null,
            searchText: ''
        };
    }
    innerRoutes = null;
    distances = null;
    phoneno = null;
    total_distance = null;
    driverWaypts = []
    colors = [
        "black", "blue", "brown", "cyan", "green", "indigo", "limegreen", "magenta", "navy", "olive", "orange", "purple", "red", "teal", "turquoise", "yellow", "grey"
    ];
    markerRendered = [];
    driverCtr = 0;
    noOfRoutes = null;
    allDirections = [];
    allDrivers = [];
    id_ctr = 0;
    driver_color = {}
    disabledMarkers = []
    allowedMarkers = []
    driver_li = []
    driverid_name = {}
    componentDidMount = () =>
    {
        let uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
        if (uiBgNo === 1)
            styles = stylesDark
        else
            styles = stylesLight

        if (this.state.allDirectionsReady === false)
        {

            console.log('RED((( setting state')
            this.setState({ loading: true })

            const url = "https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/OrdersPage2.json";
            axios.get(url)
                .then((response) => response.data)
                .then((routes) =>
                {
                    console.log('CONTENT IMPORTANT', routes)
                    this.innerRoutes = routes['drivers'];
                    this.noOfRoutes = Object.keys(this.innerRoutes).length;
                    this.distances = {}
                    this.phoneno = {}
                    for(let driverId in this.innerRoutes)
                    {
                        this.distances[driverId] = this.innerRoutes[driverId]["distance"]
                        this.phoneno[driverId] = this.innerRoutes[driverId]["phoneno"]
                    }
                    console.log('NEW', this.distances, this.phoneno)
                    const DirectionsService = new google.maps.DirectionsService();

                    for (let driver in this.innerRoutes)
                    {
                        let waypts = [];

                        for (var i = 1; i < this.innerRoutes[driver]["routes"].length - 1; i++)
                        {
                            waypts.push({
                                location: {
                                    lat: this.innerRoutes[driver]["routes"][i][0],
                                    lng: this.innerRoutes[driver]["routes"][i][1],
                                },
                                stopover: true,
                            });
                        }
                        let last = this.innerRoutes[driver]["routes"].length - 1;
                        DirectionsService.route({
                            origin: new google.maps.LatLng(
                                this.innerRoutes[driver]["routes"][0][0],
                                this.innerRoutes[driver]["routes"][0][1]
                            ),
                            destination: new google.maps.LatLng(
                                this.innerRoutes[driver]["routes"][last][0],
                                this.innerRoutes[driver]["routes"][last][1]
                            ),
                            waypoints: waypts,
                            optimizeWaypoints: true,
                            travelMode: google.maps.TravelMode.DRIVING,
                        },
                            (result, status) =>
                            {
                                if (status === google.maps.DirectionsStatus.OK)
                                {
                                    this.allDirections = this.allDirections.concat(result);
                                    this.allDrivers = this.allDrivers.concat(driver);
                                    this.driverCtr = this.driverCtr + 1;
                                    this.driver_color[driver] = this.colors[this.driverCtr % 17]
                                    this.driverid_name[driver]=this.innerRoutes[driver]["name"]
                                    if (this.driverCtr === this.noOfRoutes)
                                    {
                                        console.log('CONTENT:::', this.allDirections)
                                        console.log('RED((( setting state')
                                        this.setState({
                                            directionService: this.allDirections,
                                            allDirectionsReady: true,
                                            driver: this.allDrivers,
                                            loading: false,
                                            newMarkers: true,
                                            renderMarkerSwitch: false
                                        });
                                        console.log('RED((( after setting above state renderMarkerSwitch:', this.state.renderMarkerSwitch)
                                    }
                                } else
                                {
                                    console.error(`error fetching directions ${result}`);
                                }
                            }
                        );
                    }
                })
                .catch(error => console.log(error))

        }
    };
    openInfoWindow = (markerKey) =>
    {
        // if (this.state.activeDriver === this.state.selectedDriver)
        //     this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true, renderMarkerSwitch: false, activeDriver: this.state.activeDriver, selectedDriver: null })
        // else if (this.state.activeDriver !== this.state.selectedDriver)
        if (this.state.selectedDriver)
            this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true, renderMarkerSwitch: false, activeDriver: this.state.selectedDriver, selectedDriver: null })
        else
            this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true, renderMarkerSwitch: false, activeDriver: this.state.activeDriver, selectedDriver: null })
    }
    closeOpenWindow = () =>
    {
        console.log('RED((( setting state')
        this.setState({ currentOpenInfoWindow: 0, markerClicked: true, renderMarkerSwitch: false })
        console.log('RED((( after setting close infowindow state: ', this.state.renderMarkerSwitch)
    }
    displayMarkerHover = (driver) =>
    {
        console.log('SELECTED:', driver, "ACTIVE:", this.state.activeDriver)
        this.setState({ selectedDriver: driver, newMarkers: true })
    }
    hideMarkerHover = () =>
    {
        this.setState({ selectedDriver: null, newMarkers: true })
    }
    displayMarkerActive = (driver) =>
    {
        this.setState({ activeDriver: driver, newMarkers: true, currentOpenInfoWindow: 0 })
    }
    hideMarkerActive = () =>
    {
        this.setState({ activeDriver: null, newMarkers: true, currentOpenInfoWindow: 0 })
    }
    renderCtr = 0;
    render = () =>
    {
        console.log('RED((( $render called')
        let avg_lat = 13.0827;
        let avg_long = 80.2707;
        let no_of_waypts = 0;
        var color = "#ff0000";
        let pathsRendered = [];
        if (this.state.allDirectionsReady === true)
        {

            console.log('RED((( inside outerif')
            // eslint-disable-next-line
            pathsRendered = this.state.directionService.map( // eslint-disable-next-line
                (direction, i) =>
                {
                    color = this.driver_color[this.state.driver[i]];
                    if (this.state.driver[i] === this.state.selectedDriver || this.state.driver[i] === this.state.activeDriver)
                        return (
                            <DirectionsRenderer
                                directions={direction}
                                options={{
                                    polylineOptions: { strokeColor: color },
                                    suppressMarkers: true,
                                    preserveViewport: true
                                }}
                            />
                        );
                }
            );

            if (this.state.newMarkers === true || this.state.markerClicked === true)
            {
                this.driver_li = []
                this.renderCtr = this.renderCtr + 1;
                console.log('RED(((0', this.renderCtr, this.state.newMarkers, this.state.markerClicked, this.state.renderMarkerSwitch)
                this.markerRendered = []
                let markerKey = 0;
                avg_lat = 0; avg_long = 0;
                this.total_distance = 0;
                for (let driver in this.innerRoutes)
                {
                    this.total_distance = this.total_distance + parseFloat(this.distances[driver]);
                    if (this.innerRoutes[driver]["name"].toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1)
                    {
                        color = this.driver_color[driver];
                        this.driver_li = this.driver_li.concat(
                            <li onClick={(e) =>
                            {
                                console.log('CLICKED', driver)
                                if (this.state.activeDriver !== driver)
                                    this.displayMarkerActive(driver)
                                else
                                    this.hideMarkerActive()
                            }} className={this.state.activeDriver === driver ? 'li_active' : this.state.selectedDriver === driver ? 'li_highlighted' : ''}
                                onMouseOver={() => this.displayMarkerHover(driver)} onMouseOut={() => this.hideMarkerHover()}
                            >
                                <div>
                                    <i class="fas fa-motorcycle" style={{ marginRight: '0.5rem' }}></i>{this.innerRoutes[driver]["name"]}
                                </div>
                                <div className='menuSubline'>
                                    <div>
                                        <i class="fas fa-road" style={{ marginRight: '0.2rem' }}></i>{this.distances[driver]} km
                                    </div>
                                    <div>
                                        <i class="fas fa-map-marker-alt" style={{ marginRight: '0.2rem' }}></i>{this.innerRoutes[driver]["routes"].length} stops
                                    </div>
                                    <div>
                                        <i class="fas fa-phone-alt" style={{ marginRight: '0.2rem' }}></i>{this.phoneno[driver]}
                                    </div>
                                </div>
                            </li>
                        )

                        for (var j = 0; j < this.innerRoutes[driver]["routes"].length; j++)
                        {
                            markerKey += 1;
                            let loc = this.innerRoutes[driver]["routes"][j];
                            console.log('AVERAGE:', avg_lat, parseFloat(loc[0]), avg_lat + parseFloat(loc[0]))
                            avg_lat += parseFloat(loc[0]);
                            avg_long += parseFloat(loc[1]);
                            no_of_waypts += 1;

                            let content =
                                <div style={{ 'color': 'black' }}>
                                    <span style={{ fontWeight: 'bold' }}>Driver Name : </span><span >{this.innerRoutes[driver]["name"]}</span>
                                    <br></br>
                                    <span style={{ fontWeight: 'bold' }}>Address : </span><span >{this.innerRoutes[driver]["routes"][j][3]}</span>
                                </div>

                            console.log('CONTENT', j, (content))
                            this.markerRendered = this.markerRendered.concat(
                                <MarkerWithInfoWindow loc={loc} color={color} j={j} content={content} flag={'location'} driver={driver} selectedDriver={this.state.selectedDriver} activeDriver={this.state.activeDriver} markerKey={markerKey} currentOpenInfoWindow={this.state.currentOpenInfoWindow} openInfoWindow={(markerKey) => this.openInfoWindow(markerKey)} closeOpenWindow={() => this.closeOpenWindow()} onMouseOver={() => this.displayMarkerHover(driver)} onMouseOut={() => this.hideMarkerHover()} />
                            );
                        }
                    }

                }
                console.log('AVERAGE CENTER', avg_lat, avg_long, no_of_waypts)
                avg_lat /= no_of_waypts;
                avg_long /= no_of_waypts;
                console.log('AVERAGE CENTER', avg_lat, avg_long)
                console.log('RED((( setting state')

                // eslint-disable-next-line
                this.state.newMarkers = false;
                // eslint-disable-next-line
                this.state.markerClicked = false;
                // eslint-disable-next-line
                this.state.allDirectionsRendered = true;
                // eslint-disable-next-line
                this.state.avg_lat = avg_lat;
                // eslint-disable-next-line
                this.state.avg_long = avg_long;
                // eslint-disable-next-line
                this.state.renderMarkerSwitch = true;
                // this.setState({
                // 	newMarkers: false,
                // 	markerClicked: false,
                // 	renderMarkerSwitch: true
                // })
                console.log('RED(((2', this.renderCtr, this.state.newMarkers, this.state.markerClicked)

            }
        }
        console.log('RED(((3 renderMarkerSwitch', this.state.renderMarkerSwitch, this.markerRendered)
        return (
            <div className='DMwrapper'>
                <div className='wrapperSideMenu'>

                    <div className='sideMenu'>
                        <div className="sideMenuHeading">
                            Drivers
                            <br></br>
                            <div className="menuSubline2">
                                <div>
                                    <span>Total Distance:</span>{this.total_distance} km
                                </div>
                                <div>
                                    <span>Total Routes:</span>{this.noOfRoutes} routes
                                </div>
                            </div>
                            <div className='form-group'>
                                <input type='text' placeholder='Search' onChange={(e) => { console.log(e.target.value); this.setState({ searchText: e.target.value, newMarkers:true, activeDriver:null, selectedDriver:null }) }}></input>
                            </div>
                        </div>
                        <ul className='sideMenuList'>
                            {this.driver_li}
                        </ul>

                    </div>
                </div>

                {this.state.loading ?
                    null
                    :
                    <GoogleMap
                        defaultZoom={12}
                        defaultCenter={{ lat: this.state.avg_lat, lng: this.state.avg_long }}
                        defaultOptions={{
                            disableDefaultUI: false, // disable default map UI
                            draggable: true, // make map draggable
                            keyboardShortcuts: true, // disable keyboard shortcuts
                            scaleControl: true, // allow scale controle
                            scrollwheel: true, // allow scroll wheel
                            styles: styles // change default map styles
                        }}
                    >
                        {this.state.renderMarkerSwitch ? this.markerRendered : null}
                        {pathsRendered}
                    </GoogleMap>

                }

                {this.state.loading && <Loader />}


            </div>
        );
    };
}

const MapComponent = withScriptjs(withGoogleMap((props) => <Map />));

export default () => (
    <MapComponent
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj7orpbCxKhi3BugBoGMO9sXwIJC4EWzo&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
            <div className="sideMapContainer" />
        }
        mapElement={<div className='mapElement' style={{ height: `100%`, width: '100%' }} />}
    />
);