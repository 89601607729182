import React, { Component } from 'react';
import '../../utils/css/main.css';
import styles from '../../utils/css/settingsChangePasswordComponent.module.css'
// import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom';
class SettingsChangePasswordComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: ''
        }
    }
    handleFormSubmit = () => {
        console.log(this.state)
        let oldPasswordError='', newPasswordError='', confirmPasswordError='';

        if (this.state.oldPassword.length === 0)
            oldPasswordError = 'Required. ';

        if (this.state.newPassword.length === 0)
            newPasswordError = 'Required. ';
        else if (this.state.newPassword.length < 5)
            newPasswordError = 'Minimum length should be 5 characters. ';

        if (this.state.confirmPassword.length === 0)
            confirmPasswordError = 'Required. ';
        else if (this.state.confirmPassword.length < 5)
            confirmPasswordError = 'Minimum length should be 5 characters. ';
        if (this.state.confirmPassword !== this.state.newPassword)
            confirmPasswordError += 'Passwords entered do not match. ';


        if(oldPasswordError.length === 0 && newPasswordError.length===0 && confirmPasswordError.length===0)
            console.log('SUCCESS')

        this.setState({ oldPasswordError, newPasswordError, confirmPasswordError });
    }
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    Change Password
                </div>
                <div className={styles.formBody}>
                    <form onSubmit={() => { this.handleFormSubmit() }} style={{ width: '100%' }}>
                        <div className={styles.formGroup}>
                            <label for='OldPassword'>Old Password</label>
                            <div className={styles.innerCont}>
                                <input className={styles.input} type='password' name='OldPassword' placeholder='Old Password' onChange={(e) => this.setState({ oldPassword: e.target.value })}></input>
                                <span>{this.state.oldPasswordError}</span>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <label for='NewPassword'>New Password</label>
                            <div className={styles.innerCont}>
                                <input className={styles.input} type='password' name='NewPassword' placeholder='New Password' onChange={(e) => this.setState({ newPassword: e.target.value })}></input>
                                <span>{this.state.newPasswordError}</span>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <label for='ConfirmPassword'>Confirm Password</label>
                            <div className={styles.innerCont}>
                                <input className={styles.input} type='password' name='ConfirmPassword' placeholder='Confirm Password' onChange={(e) => this.setState({ confirmPassword: e.target.value })}></input>
                                <span>{this.state.confirmPasswordError}</span>
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <button type='button' className='btn primary-btn' style={{ marginTop: '1rem' }} onClick={() => { this.handleFormSubmit() }}>Change</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default SettingsChangePasswordComponent