import React, { Component } from 'react';
import '../../utils/css/main.css';
import styles from '../../utils/css/settingsChangeAppearanceComponent.module.css'
// import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom';
class SettingsAppearanceComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uiAccentNo: 0,
            uiBgNo: 0,
        }
    }
    componentDidMount = () => {
        let temp1 = localStorage.getItem('uiAccentNo')
        let temp3 = localStorage.getItem('uiBgNo')
        if (temp1 === null)
            temp1 = 0;
        if (temp3 === null)
            temp3 = 0;
        this.setState(
            {
                uiAccentNo: parseInt(temp1),
                uiBgNo: parseInt(temp3)
            }
        )
        console.log('RED cdm set state!')
    }
    defaultTheme = {
        '--primary-accent': '#9500ff',
        '--primary-active': '#8100dd',
        '--primary-hover': '#a322ff',
        '--reacttable-odd': '#e0b5ff59',
        '--reacttable-hover': '#bf64ff59',
        '--mainfont-color': '#ffffff',
        '--reactcalendar-now': '#fad2ff',
        '--reactcalendar-now-hover': '#f4bfff'
    }
    redTheme = {
        '--primary-accent': '#ff0000',
        '--primary-active': '#dd0000',
        '--primary-hover': '#ff2c2c',
        '--reacttable-odd': '#ffb5b559',
        '--reacttable-hover': '#ff646459',
        '--mainfont-color': '#ffffff',
        '--reactcalendar-now': '#ffd2d2',
        '--reactcalendar-now-hover': '#ffbfbf'
    }

    yellowTheme = {
        '--primary-accent': '#ffd600',
        '--primary-active': '#e7c100',
        '--primary-hover': '#ffe149',
        '--reacttable-odd': '#fffeb559',
        '--reacttable-hover': '#fff56459',
        '--mainfont-color': '#3a3a3a',
        '--reactcalendar-now': '#fffcd2',
        '--reactcalendar-now-hover': '#fffebf'
    }
    blueTheme = {
        '--primary-accent': '#0047ff',
        '--primary-active': '#003fdd',
        '--primary-hover': '#2361ff',
        '--reacttable-odd': '#b6b5ff59',
        '--reacttable-hover': '#6473ff59',
        '--mainfont-color': '#ffffff',
        '--reactcalendar-now': '#d2d5ff',
        '--reactcalendar-now-hover': '#c0bfff'
    }
    orangeTheme = {
        '--primary-accent': '#ff7a00',
        '--primary-active': '#ec7200',
        '--primary-hover': '#ff9028',
        '--reacttable-odd': '#ffd6b559',
        '--reacttable-hover': '#ffb76459',
        '--mainfont-color': '#ffffff',
        '--reactcalendar-now': '#ffead2',
        '--reactcalendar-now-hover': '#ffdebf'
    }
    greenTheme = {
        '--primary-accent': '#20E700',
        '--primary-active': '#1cd300',
        '--primary-hover': '#3aff1c',
        '--reacttable-odd': '#b5ffb559',
        '--reacttable-hover': '#83ff6459',
        '--mainfont-color': '#ffffff',
        '--reactcalendar-now': '#e6ffd2',
        '--reactcalendar-now-hover': '#d6ffbf'
    }
    lightMode = {
        '--normalfont-color': '#000000',
        '--default-bg': '#ffffff',
        '--body-bg': '#e6e6e6',
        '--settings-bg': '#f3f3f3',
        '--reacttable-input': '#ffffff',
        '--reactcalendar-navbutton': 'none',        '--reactcalendar-navbutton-hover': '#e6e6e6','--reactcalendar-navbutton-disabled': '#f0f0f0',
        '--reactcalendar-navbutton-disabled2': '#f0f0f0',
        '--tableborder-color':'#dbdbdb'
    }
    darkMode = {
        '--normalfont-color': '#ffffff',
        '--default-bg': '#363636',
        '--body-bg': '#232323',
        '--settings-bg': '#474747',
        '--reacttable-odd': 'transparent',
        '--reacttable-input': '#ffffff15',
        '--reactcalendar-navbutton': '#e6e6e60e',        '--reactcalendar-navbutton-hover': '#e6e6e634',
        '--reactcalendar-navbutton-disabled': '#f0f0f000',
        '--reactcalendar-navbutton-disabled2': '#e6e6e60e',
        '--tableborder-color':'#6b6b6b'
    }
    array_themes = [this.defaultTheme, this.redTheme, this.yellowTheme, this.blueTheme, this.orangeTheme, this.greenTheme]
    applyTheme = (currentTheme, uiAccentNo, uiBgNo) => {
        const theme = currentTheme;
        if (theme !== null) {
            Object.keys(theme).map(key => {
                const value = theme[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
            localStorage.setItem('currentTheme', JSON.stringify(theme))
        }
        if (uiBgNo === 0) {
            let temp = this.lightMode;
            temp['--reacttable-odd'] = this.array_themes[uiAccentNo]['--reacttable-odd']
            temp['--reactcalendar-now']=this.array_themes[uiAccentNo]['--reactcalendar-now']
            temp['--reactcalendar-now-hover']=this.array_themes[uiAccentNo]['--reactcalendar-now-hover']
            Object.keys(temp).map(key => {
                const value = temp[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        } else if (uiBgNo === 1) {
            this.darkMode['--reactcalendar-now']=this.array_themes[uiAccentNo]['--reactcalendar-now']+'6c'
            this.darkMode['--reactcalendar-now-hover']=this.array_themes[uiAccentNo]['--reactcalendar-now-hover']+'98'
            Object.keys(this.darkMode).map(key => {
                const value = this.darkMode[key];
                document.documentElement.style.setProperty(key, value);
                return null;
            });
        }
        localStorage.setItem('uiAccentNo', uiAccentNo)
        localStorage.setItem('uiBgNo', uiBgNo)
        console.log('GETTING THEME!!', JSON.parse(localStorage.getItem('currentTheme')));
        this.setState({ uiAccentNo, uiBgNo })
    };
    render() {
        let uiColorButtonClasses = []
        console.log('RED SELECTED', this.state.uiAccentNo)
        for (let i = 0; i < 6; i++) {
            if (i === this.state.uiAccentNo)
                uiColorButtonClasses = uiColorButtonClasses.concat(styles.colorButton_selected + ' ' + styles.lightCheck)
            else
                uiColorButtonClasses = uiColorButtonClasses.concat(styles.colorButton)
        }
        let uiBgButtonClasses = []
        for (let i = 0; i < 2; i++) {
            if (i === this.state.uiBgNo) {
                if (i === 0)
                    uiBgButtonClasses = uiBgButtonClasses.concat(styles.colorButton_selected + ' ' + styles.darkCheck)
                else
                    uiBgButtonClasses = uiBgButtonClasses.concat(styles.colorButton_selected + ' ' + styles.lightCheck)

            }
            else
                uiBgButtonClasses = uiBgButtonClasses.concat(styles.colorButton)
        }
        console.log('RED', uiColorButtonClasses)
        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    Appearance
                </div>
                <br></br>
                <div className={styles.subHeader}>
                    UI Accent Color
                </div>
                <div className={styles.colorArea}>

                    <div onClick={() => this.applyTheme(this.defaultTheme, 0, this.state.uiBgNo)} className={uiColorButtonClasses[0] + ' ' + styles.defaultTheme}>
                        <i class="fas fa-check"></i>
                    </div>

                    <div onClick={() => this.applyTheme(this.redTheme, 1, this.state.uiBgNo)} className={uiColorButtonClasses[1] + ' ' + styles.redTheme}>
                        <i class="fas fa-check"></i>
                    </div>

                    <div onClick={() => this.applyTheme(this.yellowTheme, 2, this.state.uiBgNo)} className={uiColorButtonClasses[2] + ' ' + styles.yellowTheme}>
                        <i class="fas fa-check"></i>
                    </div>

                    <div onClick={() => this.applyTheme(this.blueTheme, 3, this.state.uiBgNo)} className={uiColorButtonClasses[3] + ' ' + styles.blueTheme}>
                        <i class="fas fa-check"></i>
                    </div>

                    <div onClick={() => this.applyTheme(this.orangeTheme, 4, this.state.uiBgNo)} className={uiColorButtonClasses[4] + ' ' + styles.orangeTheme}>
                        <i class="fas fa-check"></i>
                    </div>

                    <div onClick={() => this.applyTheme(this.greenTheme, 5, this.state.uiBgNo)} className={uiColorButtonClasses[5] + ' ' + styles.greenTheme}>
                        <i class="fas fa-check"></i>
                    </div>
                </div>

                <br></br>
                <div className={styles.subHeader}>
                    UI Background Color
                </div>
                <div className={styles.colorArea}>

                    <div onClick={() => this.applyTheme(null, this.state.uiAccentNo, 0)} className={uiBgButtonClasses[0] + ' ' + styles.defaultBg}>
                        <i class="fas fa-check"></i>
                    </div>

                    <div onClick={() => this.applyTheme(null, this.state.uiAccentNo, 1)} className={uiBgButtonClasses[1] + ' ' + styles.darkBg}>
                        <i class="fas fa-check"></i>
                    </div>
                </div>
            </div>
        )
    }
}
export default SettingsAppearanceComponent