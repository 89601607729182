import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { signout } from '../../components/actions/index'
import styles from '../../utils/css/settingsSubMenu.module.css'

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: (payload) => { dispatch(signout(payload)) }
	}
}
const mapStateToProps = (state) => {
	return {
		isLogged: state.isLogged
	}
}
class SettingsSubMenu extends Component {
	handleLogOut = () => {
		if (this.props.isLogged.adminStatus)
			this.props.signOut("admin")
		else if (this.props.isLogged.userStatus)
			this.props.signOut("user")
	}
	render() {
		return (
			<div className={styles.wrapperSettingMenu}>

				<div className={styles.SettingMenu}>
					<ul>

						<li className={this.props.highlighted === 'Profile' ? styles.highlighted : ''} onClick={()=>this.props.onClick('Profile')}><Link to="#" >Profile
						</Link></li>


						<li className={this.props.highlighted === 'ChangePassword' ? styles.highlighted : ''} onClick={()=>this.props.onClick('ChangePassword')}>
							<Link to="#">Change Password
							</Link>
						</li>

                        <li className={this.props.highlighted === 'Appearance' ? styles.highlighted : ''} onClick={()=>this.props.onClick('Appearance')}>
							<Link to="#">Appearance
							</Link>
						</li>

						<li className={this.props.highlighted === 'AddAdmin' ? styles.highlighted : ''} onClick={()=>this.props.onClick('AddAdmin')}>
							<Link to="#">Add Admin
							</Link>
						</li>
					</ul>

				</div>
			</div >
		);
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(SettingsSubMenu);
