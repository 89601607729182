// comments done
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../utils/css/topNavbar.css'
import { connect } from "react-redux";
import { signout } from '../actions/index'
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: (payload) => { dispatch(signout(payload)) }
    }
}
const mapStateToProps = (state) => {
    return {
        isLogged: state.isLogged,
        companyDetails: state.companyDetails
    }
}
class TopNavbar extends Component {
    // required since logout option in topnavbar drop down
    handleLogOut = () => {
        if (this.props.isLogged.adminStatus)
            this.props.signOut("admin")
        else if (this.props.isLogged.userStatus)
            this.props.signOut("user")
    }
    render() {
        let title =
            <div className='titleArea'>
                <span>{this.props.title}</span>
            </div>;
        let buttons = [];
        for (let page in this.props.buttons) {
            let temp = this.props.buttons[page]
            buttons = buttons.concat(
                <div className='buttonArea'>
                    <Link to={temp.Link}><span>{temp.title}</span></Link>
                </div>)
        }
        return (
            <div className='outerArea1'>
                <div className='logoArea'>
                    {/* <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 40L20.2759 2L40 40H32.1552L20.2759 16.8402L8.17241 40H1Z" fill="#9500FF" stroke="#9500FF" />
                    </svg> */}
                    <img src={require('../../utils/images/logo.png')} alt='Zalt Logo' width='50px'></img>
                </div>
                <div className='infoArea'>
                    <div className='allButtons'>
                        {/* positions of buttons and titles are interchangeable, decided on the basis of the page displayed */}
                        {this.props.title === "Choose Project" || this.props.title === "Onboard Requests" || this.props.title === '2D Heat Map'  || this.props.title==="Orders 2"?
                            buttons
                            :
                            title
                        }
                        {this.props.title === "Choose Project" || this.props.title === "Onboard Requests" || this.props.title === '2D Heat Map' || this.props.title==="Orders 2"?
                            title
                            :
                            buttons
                        }

                    </div>
                    <div className='userArea'>
                        <div className='companyArea'>
                            <div className='companyName'>
                                {this.props.companyDetails.companyName}
                            </div>
                            <div className='subText'>
                                Subline
                            </div>
                        </div>
                        <svg className='profileLogo' width="50" height="50" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.5" y="1.5" width="58" height="58" rx="3.5" stroke="black" stroke-width="3" />
                            <path d="M19.9531 44C19.8216 40.807 21.8469 34.421 30.9999 34.421C40.153 34.421 42.1782 40.807 42.0466 44" stroke="black" stroke-width="3" stroke-linecap="round" />
                            <circle cx="30.9998" cy="23.3684" r="5.86842" stroke="black" stroke-width="3" />
                        </svg>

                        <div class="dropdown show">
                            <button id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg id='dropDownArrow' width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 2L9 9L16 2" stroke="#8A8A8A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>

                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <Link to='/SettingsPage' className="dropdown-item">Settings</Link>
                                <Link to='/' onClick={() => { this.handleLogOut() }} className="dropdown-item">Log Out</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);
