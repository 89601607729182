import React, { Component } from 'react';
import sampleData from '../resources/SignUpRequests.json'
import styles2 from '../utils/css/mains.module.css';
import Navbar from './Navbar'
import TopNavbar from './TopNavbar'
import ModalAddRequest from '../utils/additional components/ModalAddRequest'
import ModalOnboardRequest from '../utils/additional components/ModalOnboardRequest'
import ReactTable from 'react-table/react-table';
import 'react-table/react-table.css';
import styles from '../utils/css/adminReact-table.module.css'
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
const mapStateToProps = (state) =>
{
    return {
        isLogged: state.isLogged,
        companyDetails: state.companyDetails
    }
}
class ManageRequests extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            loading: false,
            driverDetailsObj: null,
            showEditModal: false,
            deleted_phoneno: []
        }
    }
    componentDidMount()
    {
        this.setState({ loading: true })
        const url = "https://jsonplaceholder.typicode.com/posts";
        fetch(url, {
            method: "GET"
        }).then(response => response.json()).then(posts =>
        {
            this.setState({ posts: posts, loading: false })
        })

    }
    unmountModal = () =>
    {
        this.setState({
            showEditModal: false
        })
    }
    deleteId = (phoneno) =>
    {
        this.setState(
            {
                deleted_phoneno: this.state.deleted_phoneno.concat(phoneno)
            }
        )
    }
    driver_obj = {}
    render()
    {
        if (this.props.isLogged.superAdminStatus === false)
            return <Redirect to='/' />
        const columns = [
            {
                Header: "Email Id",
                accessor: "email",
            },
            {
                Header: "Phone No",
                accessor: "phoneno",
            },
            {
                Header: "No of Vehicles",
                accessor: "no_of_vehicles",
            }
        ];
        let data = []
        for (let phoneno in sampleData)
        {
            let obj = {
                'no_of_vehicles': sampleData[phoneno]['no_of_vehicles'],
                'email': sampleData[phoneno]['email'],
                'phoneno': sampleData[phoneno]['phoneno']
            }
            this.driver_obj[phoneno] = obj;
            if (this.state.deleted_phoneno.includes(phoneno))
                continue
            data.push(
                obj
            );
        }
        return (
            <div>
                <Navbar highlighted={'ManageRequests'} />
                <TopNavbar title={'Manage Requests'} />
                <div className={styles2.wrapper}>
                    <div className={styles2.main_content}>
                        <div className={styles2.pad}>
                            <div className={styles2.header}>
                                <div className={styles2.welcomeContainer} >
                                    <div className={styles2.mainLine} >
                                        Choose a request
								</div>
                                    <div className={styles2.subLine} >
                                        Or add a new user
								</div>
                                </div>
                                <div className={styles2.buttonText} data-toggle="modal" data-target="#addRequestModal">
                                    <div className={styles2.buttonContainer}>
                                        <div className={styles2.buttonText}><span style={{ marginRight: '8px' }}><i class="fas fa-plus"></i></span>Add Request</div>
                                    </div>
                                </div>
                            </div>

                            <ModalAddRequest />
                            {this.state.showEditModal ?
                                <ModalOnboardRequest details={this.state.driverDetailsObj} onClick={() => { this.unmountModal() }} deleteId={(d_id) => { this.deleteId(d_id) }} />
                                :
                                null
                            }



                            <div className={styles.rtContainer2}>
                                <ReactTable
                                    columns={columns}
                                    data={data}
                                    showPaginationBottom={true}
                                    noDataText={"Loading..."}
                                    showPageSizeOptions={true}
                                    pageSizeOptions={[5, 10, 20, 25]}
                                    defaultPageSize={10}
                                    // loading={this.state.loading}
                                    className='-striped -highlight'
                                    sortable={true}
                                    filterable={true}
                                    getTrProps={(state, rowInfo, column, instance) =>
                                    {
                                        return {
                                            onClick: (e, handleOriginal) =>
                                            {
                                                let obj = this.driver_obj[rowInfo['original']['phoneno']]
                                                this.setState({
                                                    driverDetailsObj: obj,
                                                    showEditModal: true,
                                                })
                                                if (handleOriginal)
                                                {
                                                    handleOriginal()
                                                }
                                            }

                                        }
                                    }}
                                >

                                </ReactTable>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={styles2.wrapper+", text-center"}>
                    <div className={styles2.main_content} style={{ height: '97vh' }}>
                        <div className={styles2.header}>
                            <div className={styles2.welcomeContainer} >
                                <div className={styles2.mainLine} >
                                    Admin Portal
								</div>
                                <div className={styles2.subLine} >
                                    Handle Sign Up Request
								</div>
                            </div>
                            <div className={styles2.buttonText} data-toggle="modal" data-target="#addRequestModal">
                                <div className={styles2.buttonContainer}>
                                    <div className={styles2.buttonText}><span style={{ marginRight: '8px' }}><i class="fas fa-plus"></i></span>Add Request</div>
                                </div>
                            </div>
                        </div>
                        <ModalAddRequest />
                        {this.state.showEditModal ? <ModalOnboardRequest details={this.state.driverDetailsObj} onClick={()=>{this.unmountModal()}} deleteId={(d_id)=>{this.deleteId(d_id)}}/>:null}
                        <div className={styles.rtContainer2}>
                            <ReactTable
                                columns={columns}
                                data={data}
                                showPaginationBottom={true}
                                noDataText={"Loading..."}
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 20, 25]}
                                defaultPageSize={10}
                                // loading={this.state.loading}
                                className='-striped -highlight'
                                sortable={true}
                                filterable={true}
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick: (e, handleOriginal) => {
                                            let obj = this.driver_obj[rowInfo['original']['phoneno']]
											this.setState({
												driverDetailsObj: obj,
												showEditModal: true,
											})
                                            if (handleOriginal) {
                                                handleOriginal()
                                            }
                                        }

                                    }
                                }}
                            >

                            </ReactTable>
                        </div>
                    </div>
                </div> */}

            </div>
        );
    }
}

export default connect(mapStateToProps)(ManageRequests);
