import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import '../utils/css/navbar.css';
import ReactTooltip from "react-tooltip";
import '../utils/css/navbar.module.css'
import { connect } from "react-redux";
import { signout } from '../../components/actions/index'
const mapDispatchToProps = (dispatch) =>
{
	return {
		signOut: (payload) => { dispatch(signout(payload)) }
	}
}
const mapStateToProps = (state) =>
{
	return {
		isLogged: state.isLogged
	}
}
class Navbar extends Component
{
	handleLogOut = () =>
	{
		if (this.props.isLogged.adminStatus)
			this.props.signOut("admin")
		else if (this.props.isLogged.userStatus)
			this.props.signOut("user")
	}
	render()
	{
		return (
			<div className="wrapperNavbar">

				<div className="sidebar">
					{/* <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 40L20.2759 2L40 40H32.1552L20.2759 16.8402L8.17241 40H1Z" fill="#9500FF" stroke="#9500FF" />
					</svg> */}

					<ul>

						<li className={this.props.highlighted === 'Dashboard' ? 'highlighted' : ''} data-tip data-for="Dashboard"><Link to="AdminDashboard">
							<svg width="25" height="25" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M24.9619 16.2354C22.8051 16.2354 21.0503 17.9908 21.0503 20.1486C21.0503 22.3064 22.8051 24.0619 24.9619 24.0619C27.1188 24.0619 28.8735 22.3064 28.8735 20.1486C28.8735 17.9908 27.1188 16.2354 24.9619 16.2354ZM24.9619 21.453C24.243 21.453 23.658 20.8679 23.658 20.1486C23.658 19.4294 24.2429 18.8442 24.9619 18.8442C25.6809 18.8442 26.2658 19.4293 26.2658 20.1486C26.2658 20.8678 25.6809 21.453 24.9619 21.453Z" fill="#8A8A8A" />
								<path d="M18.4072 30.7842H7.75069C7.03176 30.7842 6.4468 30.1991 6.4468 29.4798V12.8574L17.269 3.45527L23.141 8.55675C23.7343 8.46279 24.3424 8.41347 24.9618 8.41347C25.7289 8.41347 26.4786 8.48909 27.2045 8.63179L17.269 0L0.56543 14.5117L2.27533 16.4814L3.83907 15.1229V29.4798C3.83907 31.6375 5.59384 33.393 7.75069 33.393H20.963C20.2401 32.7204 19.3369 31.8293 18.4072 30.7842Z" fill="#8A8A8A" />
								<path d="M24.9616 11.022C20.0008 11.022 15.9648 15.0576 15.9648 20.018V20.2537C15.9648 22.7481 17.3669 25.6225 20.1321 28.7968C22.1076 31.0645 24.0603 32.6495 24.1424 32.716L24.9615 33.3777L25.7806 32.716C25.8627 32.6495 27.8154 31.0646 29.7908 28.7968C32.5561 25.6225 33.9581 22.7481 33.9581 20.2537V20.018C33.9583 15.0576 29.9224 11.022 24.9616 11.022ZM24.9657 29.9824C24.2564 29.3456 23.1754 28.3195 22.098 27.0828C19.7916 24.4353 18.5725 22.0737 18.5725 20.2537V20.0181C18.5725 16.4962 21.4386 13.6308 24.9615 13.6308C28.4844 13.6308 31.3505 16.4962 31.3505 20.0181V20.2537H31.3505C31.3505 23.5838 27.2955 27.8999 24.9657 29.9824Z" fill="#8A8A8A" />
							</svg>
						</Link></li>
						<ReactTooltip id="Dashboard" className='extraClass' place="right" effect="solid" type='dark'>
							Dashboard
      					</ReactTooltip>

						<li className={this.props.highlighted === 'ManageRequests' ? 'highlighted' : ''} data-tip data-for="ManageRequests"><Link to="AdminManageRequests">
							<svg width="24" height="25" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M28.7563 0.393066H3.76829C1.8864 0.393066 0.36084 1.99491 0.36084 3.97089V30.2083C0.36084 32.1842 1.8864 33.7861 3.76829 33.7861H28.7563C30.6381 33.7861 32.1637 32.1842 32.1637 30.2083V3.97089C32.1637 1.99491 30.6381 0.393066 28.7563 0.393066ZM29.892 30.2083C29.892 30.8669 29.3835 31.4009 28.7562 31.4009H3.76829C3.14097 31.4009 2.63245 30.8669 2.63245 30.2083V3.97089C2.63245 3.3122 3.14097 2.77826 3.76829 2.77826H28.7563C29.3836 2.77826 29.8921 3.3122 29.8921 3.97089V30.2083H29.892Z" fill="#8A8A8A" />
								<path d="M10.5828 7.54883H7.17539C6.54807 7.54883 6.03955 8.08278 6.03955 8.74146C6.03955 9.40014 6.54807 9.93409 7.17539 9.93409H10.5828C11.2102 9.93409 11.7187 9.40014 11.7187 8.74146C11.7187 8.08278 11.2102 7.54883 10.5828 7.54883Z" fill="#8A8A8A" />
								<path d="M25.3489 7.54883H15.1266C14.4992 7.54883 13.9907 8.08278 13.9907 8.74146C13.9907 9.40014 14.4992 9.93409 15.1266 9.93409H25.3489C25.9762 9.93409 26.4847 9.40014 26.4847 8.74146C26.4847 8.08278 25.9762 7.54883 25.3489 7.54883Z" fill="#8A8A8A" />
								<path d="M10.5828 15.8971H7.17539C6.54807 15.8971 6.03955 16.431 6.03955 17.0897C6.03955 17.7484 6.54807 18.2823 7.17539 18.2823H10.5828C11.2102 18.2823 11.7187 17.7483 11.7187 17.0897C11.7187 16.431 11.2102 15.8971 10.5828 15.8971Z" fill="#8A8A8A" />
								<path d="M25.3489 15.8971H15.1266C14.4992 15.8971 13.9907 16.431 13.9907 17.0897C13.9907 17.7484 14.4992 18.2823 15.1266 18.2823H25.3489C25.9762 18.2823 26.4847 17.7483 26.4847 17.0897C26.4847 16.431 25.9762 15.8971 25.3489 15.8971Z" fill="#8A8A8A" />
								<path d="M10.5828 24.2452H7.17539C6.54807 24.2452 6.03955 24.7792 6.03955 25.4379C6.03955 26.0966 6.54807 26.6305 7.17539 26.6305H10.5828C11.2102 26.6305 11.7187 26.0966 11.7187 25.4379C11.7187 24.7792 11.2102 24.2452 10.5828 24.2452Z" fill="#8A8A8A" />
								<path d="M25.3489 24.2452H15.1266C14.4992 24.2452 13.9907 24.7792 13.9907 25.4379C13.9907 26.0966 14.4992 26.6305 15.1266 26.6305H25.3489C25.9762 26.6305 26.4847 26.0966 26.4847 25.4379C26.4847 24.7792 25.9762 24.2452 25.3489 24.2452Z" fill="#8A8A8A" />
							</svg>
						</Link></li>
						<ReactTooltip id="ManageRequests" className='extraClass' place="right" effect="solid" type='dark'>
							Manage Requests
      					</ReactTooltip>

						<li className={this.props.highlighted === 'ViewUsers' ? 'highlighted' : ''} data-tip data-for="ViewUsers">
							<Link to="AdminViewUsers"><svg width="25" height="17" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M29.9275 25.7055H4.18721C2.07645 25.7055 0.36084 23.9899 0.36084 21.8792V4.48713C0.36084 2.37638 2.07645 0.660767 4.18721 0.660767H29.9275C32.0382 0.660767 33.7538 2.37638 33.7538 4.48713V21.8792C33.7538 23.9899 32.0382 25.7055 29.9275 25.7055ZM4.18721 2.74783C3.22851 2.74783 2.4479 3.52844 2.4479 4.48713V21.8792C2.4479 22.8378 3.22851 23.6185 4.18721 23.6185H29.9275C30.8862 23.6185 31.6668 22.8378 31.6668 21.8792V4.48713C31.6668 3.52844 30.8862 2.74783 29.9275 2.74783H4.18721Z" fill="#8A8A8A" />
								<path d="M10.7962 13.1832C8.87884 13.1832 7.31787 11.6219 7.31787 9.7048C7.31787 7.78742 8.87884 6.2262 10.7962 6.2262C12.7136 6.2262 14.2746 7.78742 14.2746 9.7048C14.2746 11.6219 12.7136 13.1832 10.7962 13.1832ZM10.7962 8.31326C10.0296 8.31326 9.40493 8.93795 9.40493 9.7048C9.40493 10.4714 10.0296 11.0961 10.7962 11.0961C11.5628 11.0961 12.1875 10.4714 12.1875 9.7048C12.1875 8.93795 11.5628 8.31326 10.7962 8.31326Z" fill="#8A8A8A" />
								<path d="M16.014 20.1403C15.438 20.1403 14.9705 19.6728 14.9705 19.0967V18.401C14.9705 17.4423 14.1899 16.6616 13.2312 16.6616H8.36152C7.40283 16.6616 6.62222 17.4423 6.62222 18.401V19.0967C6.62222 19.6728 6.15472 20.1403 5.57869 20.1403C5.00266 20.1403 4.53516 19.6728 4.53516 19.0967V18.401C4.53516 16.2902 6.25077 14.5746 8.36152 14.5746H13.2312C15.3419 14.5746 17.0575 16.2902 17.0575 18.401V19.0967C17.0575 19.6728 16.59 20.1403 16.014 20.1403Z" fill="#8A8A8A" />
								<path d="M28.5363 9.00906H20.8839C20.3078 9.00906 19.8403 8.54156 19.8403 7.96553C19.8403 7.3895 20.3078 6.922 20.8839 6.922H28.5363C29.1124 6.922 29.5799 7.3895 29.5799 7.96553C29.5799 8.54156 29.1124 9.00906 28.5363 9.00906Z" fill="#8A8A8A" />
								<path d="M28.5363 14.5745H20.8839C20.3078 14.5745 19.8403 14.107 19.8403 13.531C19.8403 12.9549 20.3078 12.4874 20.8839 12.4874H28.5363C29.1124 12.4874 29.5799 12.9549 29.5799 13.531C29.5799 14.107 29.1124 14.5745 28.5363 14.5745Z" fill="#8A8A8A" />
								<path d="M28.5363 20.14H20.8839C20.3078 20.14 19.8403 19.6725 19.8403 19.0965C19.8403 18.5205 20.3078 18.053 20.8839 18.053H28.5363C29.1124 18.053 29.5799 18.5205 29.5799 19.0965C29.5799 19.6725 29.1124 20.14 28.5363 20.14Z" fill="#8A8A8A" />
							</svg>
							</Link>
						</li>
						<ReactTooltip id="ViewUsers" className='extraClass' place="right" effect="solid" type='dark'>
							View Users
      					</ReactTooltip>

						<li className={this.props.highlighted === 'Analytics' ? 'highlighted' : ''} data-tip data-for="Analytics">
							<Link to="AdminAnalytics">
								<svg fill="#8a8a8a" width="30" height="30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m22.5 20h-21c-.827 0-1.5-.673-1.5-1.5v-13c0-.827.673-1.5 1.5-1.5h21c.827 0 1.5.673 1.5 1.5v13c0 .827-.673 1.5-1.5 1.5zm-21-15c-.276 0-.5.225-.5.5v13c0 .275.224.5.5.5h21c.276 0 .5-.225.5-.5v-13c0-.275-.224-.5-.5-.5z" /><path d="m16.5 24h-9c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h9c.276 0 .5.224.5.5s-.224.5-.5.5z" /><path d="m10.5 24c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z" /><path d="m13.5 24c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z" /><path d="m12 17c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm0-9c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z" /><path d="m8.818 15.682c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l3.036-3.035v-4.294c0-.276.224-.5.5-.5s.5.224.5.5v4.5c0 .133-.053.26-.146.354l-3.182 3.182c-.098.097-.226.146-.354.146z" /></svg>
							</Link>
						</li>
						<ReactTooltip id="Analytics" className='extraClass' place="right" effect="solid" type='dark'>
							Analytics
      					</ReactTooltip>
					</ul>
					<svg width="63" height="14" viewBox="0 0 68 17" fill="none" xmlns="http://www.w3.org/2000/svg" id='zaltFooter'>
						<path d="M2 4.03102V1.60327H16.3488L7.3703 13.0946H16.3488V15.6033H2L10.9785 4.03102H2Z" fill="#8A8A8A" stroke="#8A8A8A" />
						<path d="M19.6108 15.5225L26.8272 1.84619L34.2113 15.5225H31.2745L26.8272 7.18723L22.296 15.5225H19.6108Z" fill="#8A8A8A" stroke="#8A8A8A" />
						<path d="M39.991 1.60327H37.4736V11.7189C37.4736 14.5027 39.7672 15.3605 40.914 15.4414H49.3051V13.0946H41.5014C40.2259 13.0946 39.963 11.9617 39.991 11.3952V1.60327Z" fill="#8A8A8A" stroke="#8A8A8A" />
						<path d="M52.5674 4.19287V1.60327H67.0001V4.19287H61.0424V15.6033H58.4411V4.19287H52.5674Z" fill="#8A8A8A" stroke="#8A8A8A" />
					</svg>

				</div>
			</div >
		);
	}
}



export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
