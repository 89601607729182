// no comments required
import React, { Component } from 'react';
import '../../utils/css/main.css';
import styles from '../../utils/css/settingsChangePasswordComponent.module.css'
// import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom';
class SettingsProfileComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    Profile
                </div>
            </div>
        )
    }
}
export default SettingsProfileComponent