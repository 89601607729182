import React, { Component } from 'react';
import '../../utils/css/mains.module.css';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
class ModalOnboardRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.details['id'],
            v_type: this.props.details['v_type'],
            name: this.props.details['name'],
            email: this.props.details['email'],
            phoneno: this.props.details['phoneno'],
            present_addr: this.props.details['present_addr'],
            showWarning: false,
        }
    }
    handleFormSubmit = () => {
        console.log('IMPORTANT:', this.state);
        this.props.deleteId(this.state.phoneno);
        this.props.onClick();
    }
    render() {
        let button10 = <button type="button" class="btn btn-primary float-left edit-btn" onClick={() => { this.setState({ showWarning: false }) }}>No</button>
        let button11 = <button type="button" className="btn btn-primary delete-btn"
            onClick={
                () => {
                    this.props.deleteId(this.state.phoneno)
                    this.props.onClick()
                }
            }>Yes</button>
        let button00 = <button type="submit" class="btn btn-primary float-left edit-btn" ><span style={{ marginRight: '8px' }}><i class="fas fa-check"></i></span>Accept</button>
        let button01 = <button type="button" className="btn btn-primary delete-btn" onClick={() => { this.setState({ showWarning: true }) }}><span style={{ marginRight: '8px' }}><i class="fas fa-trash"></i></span>Delete</button>


        let selectInput =
            <select name="cars" id="cars" form="carform" className='form-control' onChange={(e) => { this.setState({ v_type: e.target.value }) }} required>
                <option value="Bike">Bike</option>
                <option value="Line Haul" selected>Line Haul</option>
            </select>

        if (this.state.v_type === "Bike")
            selectInput =
                <select name="cars" id="cars" form="carform" className='form-control' onChange={(e) => { this.setState({ v_type: e.target.value }) }} required>
                    <option value="Bike" selected>Bike</option>
                    <option value="Line Haul">Line Haul</option>
                </select>
        return (
            <Modal isOpen={true} toggle={() => { this.props.onClick() }} backdrop={'static'} keyboard={false} scrollable={true} size={'xl'} >
                <ModalHeader toggle={() => { this.props.onClick() }}>Add Driver Details</ModalHeader>
                <form class='text-left' onSubmit={() => { this.handleFormSubmit() }}>
                    {this.state.showWarning ?
                        <ModalBody>
                            Warning! Are you sure you want to delete the request?
                        </ModalBody>
                        :
                        <ModalBody style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
                            <div style={{ color: 'gray', fontSize: '0.9rem' }}>
                                You may change/delete the following details.
                            </div>
                            <br></br>
                            <div class="form-group">

                                <Row>
                                    <Col>
                                        <label for="name" className='font-weight-600'>Name</label>
                                        <input type="text" class="form-control" id="name" placeholder="Edit Name" required onChange={(e) => { this.setState({ name: e.target.value }) }} defaultValue={this.state.name}></input>
                                    </Col>

                                    <Col>
                                        <label for="phoneno" className='font-weight-600'>Phone No</label>
                                        <input type="text" class="form-control" id="phoneno" placeholder="Phone No" required onChange={(e) => { this.setState({ phoneno: e.target.value }) }} minLength="10" maxLength="10" defaultValue={this.state.phoneno}></input>
                                    </Col>
                                </Row>
                            </div>
                            <div class="form-group">
                                <Row>

                                    <Col>
                                        <label for="emailid" className='font-weight-600'>Email Id</label>
                                        <input type="email" class="form-control" id="emailid" placeholder="Email Id" required onChange={(e) => { this.setState({ email: e.target.value }) }} defaultValue={this.state.email}></input>
                                    </Col>
                                    <Col>
                                        <label className='font-weight-600'>Vehicle Type</label>
                                        {selectInput}

                                    </Col>
                                </Row>

                            </div>
                            <div class="form-group">
                                <Row>
                                    <Col>
                                        <label for="present_addr" className='font-weight-600'>Present Address</label>
                                        <input type="text" class="form-control" id="present_addr" placeholder="Present Address" required onChange={(e) => { this.setState({ present_addr: e.target.value }) }} defaultValue={this.state.present_addr}></input>
                                    </Col>
                                </Row>

                            </div>
                            <br></br>

                            {/* -------------------------------------------------------------------------------- */}

                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Date of Birth</div>
                                    <div className='text-dark2'>
                                        {this.props.details['dob']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Blood Group</div>
                                    <div className='text-dark2'>
                                        {this.props.details['blood_group']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Gender</div>
                                    <div className='text-dark2'>
                                        {this.props.details['gender']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Educational Qualifications</div>
                                    <div className='text-dark2'>
                                        {this.props.details['ed_qual']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Mother's Name</div>
                                    <div className='text-dark2'>
                                        {this.props.details['mother_name']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Father's Name</div>
                                    <div className='text-dark2'>
                                        {this.props.details['father_name']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Blood Group</div>
                                    <div className='text-dark2'>
                                        {this.props.details['blood_group']}
                                    </div>
                                </Col>
                            </Row>
                            {/* -------------------------------------------------------------------------------- */}

                            <hr></hr>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Permanent Address</div>
                                    <div className='text-dark2'>
                                        {this.props.details['perm_addr']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Permanent Address Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['perm_addr_proof']}>{this.props.details['perm_addr_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>City</div>
                                    <div className='text-dark2'>
                                        {this.props.details['city']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Pin Code</div>
                                    <div className='text-dark2'>
                                        {this.props.details['pincode']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>State</div>
                                    <div className='text-dark2'>
                                        {this.props.details['state']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>ID Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['id_proof']}>{this.props.details['id_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            {/* -------------------------------------------------------------------------------- */}

                            <hr></hr>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Pan Card No</div>
                                    <div className='text-dark2'>
                                        {this.props.details['pan_no']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Pan Card Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['pan_proof']}>{this.props.details['pan_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Aadhaar Card No</div>
                                    <div className='text-dark2'>
                                        {this.props.details['aadhaar_no']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Aadhaar Card Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['aadhaar_proof']}>{this.props.details['aadhaar_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            {/* -------------------------------------------------------------------------------- */}

                            <hr></hr>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>DL No</div>
                                    <div className='text-dark2'>
                                        {this.props.details['dl_no']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>DL Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['perm_addr_proof']}>{this.props.details['perm_addr_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>DL Expiry Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['dl_expiry_proof']}>{this.props.details['dl_expiry_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            {/* -------------------------------------------------------------------------------- */}

                            <hr></hr>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Bank A/C No</div>
                                    <div className='text-dark2'>
                                        {this.props.details['bank_acno']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Bank IFSC Code</div>
                                    <div className='text-dark2'>
                                        {this.props.details['bank_ifsc']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Bank A/C Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['bank_proof']}>{this.props.details['bank_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            {/* -------------------------------------------------------------------------------- */}

                            <hr></hr>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Insurance Company Name</div>
                                    <div className='text-dark2'>
                                        {this.props.details['insr_company']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Insurace Policy No</div>
                                    <div className='text-dark2'>
                                        {this.props.details['insr_policy_no']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Insurace Expiry Date</div>
                                    <div className='text-dark2'>
                                        {this.props.details['insr_expiry_date']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Insurance Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['insr_proof']}>{this.props.details['insr_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            {/* -------------------------------------------------------------------------------- */}
                            <hr></hr>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Vehicle Type</div>
                                    <div className='text-dark2'>
                                        {this.props.details['v_type']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Vehicle No</div>
                                    <div className='text-dark2'>
                                        {this.props.details['v_no']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Vehicle Brand</div>
                                    <div className='text-dark2'>
                                        {this.props.details['v_brand']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>Vehicle Model</div>
                                    <div className='text-dark2'>
                                        {this.props.details['v_model']}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>Vehicle Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['v_proof']}>{this.props.details['v_proof']}</a>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>RC Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['rc_proof']}>{this.props.details['rc_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='font-weight-600'>PUC Expiry</div>
                                    <div className='text-dark2'>
                                        {this.props.details['puc_expiry']}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='font-weight-600'>PUC Proof</div>
                                    <div className='text-dark2'>
                                        <a target='_blank' rel="noopener noreferrer" href={this.props.details['puc_proof']}>{this.props.details['puc_proof']}</a>
                                    </div>
                                </Col>
                            </Row>
                            {/* -------------------------------------------------------------------------------- */}

                        </ModalBody>
                    }

                    {this.state.showWarning ?
                        null
                        :
                        <ModalFooter className='custom-footer'>
                            {button00}
                            {button01}
                        </ModalFooter>
                    }
                    {this.state.showWarning ?
                        <ModalFooter className='custom-footer'>
                            {button10}
                            {button11}
                        </ModalFooter>
                        :
                        null
                    }
                </form>
            </Modal>
        );
    }
}
export default ModalOnboardRequest