// comments done
import React, { Component } from 'react';
import '../../utils/css/main.css'
import Navbar from '../Navbar'
import TopNavbar from './TopNavbar'
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import Loader from '../../utils/additional components/LoaderComponent'
const mapStateToProps = (state) =>
{
    return {
        isLogged: state.isLogged,
        companyDetails: state.companyDetails
    }
}
class SetParameters extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            currDay: null,
            currTimeSlot: -1,//timeslots are 0 - 5
            currRow: null,
            errorHighlight: false,
            noOfErrors: 0,
            inputValues: null,
            dataReady: false
        }
    }
    componentDidMount()
    {
        const url = "https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/SetParameters.json";
		axios.get(url)
        .then((response) => response.data)
		.then(inputValues => {
			this.setState({ inputValues: inputValues, dataReady: true })
		})
		.catch(error => this.setState({
					error
				}))

    }
    updateInput = (e, day, row, timeSlot) =>
    {
        console.log('Current', day, row, timeSlot, e.target.value)
        let a = parseInt(e.target.value), b = parseInt(e.target.value), c = parseInt(e.target.value);
        if (row !== 'a')
            a = this.state.inputValues[day]['a'][timeSlot]
        if (row !== 'b')
            b = this.state.inputValues[day]['b'][timeSlot]
        if (row !== 'c')
            c = this.state.inputValues[day]['c'][timeSlot]
        //above if else is actually getting the values of the rows of that particular day and timeslot
        console.log('Current', a, b, c, (a + b + c))
        let errorHighlight = false;
        let inputValues = this.state.inputValues
        inputValues[day]['a'][timeSlot] = a;
        inputValues[day]['b'][timeSlot] = b;
        inputValues[day]['c'][timeSlot] = c;
        if ((a + b + c) !== 100)
        {
            errorHighlight = true
        } else
        {
            errorHighlight = false
        }
        // Note: if sum of values is not 100, I am highlighting that entire time slot of that day, by making the board red, and all the other number inputs will be disabled as well as the submit button will be disbaled unless the current error is fixed by inputting appropriate values that add upto 100, then the error will be resolved and the submit and other number inputs will be enabled
        this.setState({
            currDay: day,
            currRow: row,
            currTimeSlot: parseInt(timeSlot),
            inputValues: inputValues,
            errorHighlight: errorHighlight
        })

    }
    handleSubmit=()=>{
        console.log('The new data is:', this.state.inputValues)
    }
    render()
    {
        if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
            return <Redirect to='/' />
        let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
        let tableElements = []
        if (this.state.dataReady)
        {
            for (let i = 0; i < 21; i++)//21 because for each of the 7days there are 3 rows
            {
                if (i % 3 === 0)
                    tableElements = tableElements.concat(<br></br>)
                    // adding a break line before the start of every first row of a new day, i.e. space between each day
                let day = days[parseInt(i / 3)]
                // i.e. for rows 0, 1, 2, the day will be Monday
                // i.e. for rows 3, 4, 5, the day will be Tuesday and so on..
                let category = "Ecommerce"
                let row = "a"
                if (i % 3 === 0)
                {
                    category = "Ecommerce"
                    row = "a"
                }
                else if (i % 3 === 1)
                {
                    category = "FMCG"
                    row = "b"
                }
                else if (i % 3 === 2)
                {
                    category = "Food"
                    row = "c"
                }
                tableElements = tableElements.concat(
                    // special css for the 3rd row of every day, hence below is conditional css
                    <tr className={category === 'Food' && 'thirdRow'}>

                        {i % 3 === 0 && <th rowSpan={3}>{day}</th>}

                        <td>{category}</td>

                        <td className={(this.state.currDay === day && this.state.currTimeSlot === (0) && this.state.errorHighlight === true) && (row + String(1))} >
                            <input type='number' defaultValue={this.state.inputValues[day][row][0]} disabled={(this.state.errorHighlight && (this.state.currDay !== day || this.state.currTimeSlot !== 0)) ? true : false} onChange={(e) => this.updateInput(e, day, row, (0))}></input>
                        </td>

                        <td className={(this.state.currDay === day && this.state.currTimeSlot === 1 && this.state.errorHighlight === true) && row + String(1)}>
                            <input type='number' defaultValue={this.state.inputValues[day][row][1]} disabled={(this.state.errorHighlight && (this.state.currDay !== day || this.state.currTimeSlot !== 1)) ? true : false} onChange={(e) => this.updateInput(e, day, row, (1))}></input>
                        </td>

                        <td className={(this.state.currDay === day && this.state.currTimeSlot === 2 && this.state.errorHighlight === true) && row + String(1)}>
                            <input type='number' defaultValue={this.state.inputValues[day][row][2]} disabled={(this.state.errorHighlight && (this.state.currDay !== day || this.state.currTimeSlot !== 2)) ? true : false} onChange={(e) => this.updateInput(e, day, row, (2))}></input>
                        </td>

                        <td className={(this.state.currDay === day && this.state.currTimeSlot === 3 && this.state.errorHighlight === true) && row + String(1)}>
                            <input type='number' defaultValue={this.state.inputValues[day][row][3]} disabled={(this.state.errorHighlight && (this.state.currDay !== day || this.state.currTimeSlot !== 3)) ? true : false} onChange={(e) => this.updateInput(e, day, row, (3))}></input>
                        </td>

                        <td className={(this.state.currDay === day && this.state.currTimeSlot === 4 && this.state.errorHighlight === true) && row + String(1)}>
                            <input type='number' defaultValue={this.state.inputValues[day][row][4]} disabled={(this.state.errorHighlight && (this.state.currDay !== day || this.state.currTimeSlot !== 4)) ? true : false} onChange={(e) => this.updateInput(e, day, row, (4))}></input>
                        </td>

                        <td className={(this.state.currDay === day && this.state.currTimeSlot === 5 && this.state.errorHighlight === true) && row + String(1)} onChange={(e) => this.updateInput(e, day, row, 5)}>
                            <input type='number' defaultValue={this.state.inputValues[day][row][5]} disabled={(this.state.errorHighlight && (this.state.currDay !== day || this.state.currTimeSlot !== 5)) ? true : false} onChange={(e) => this.updateInput(e, day, row, (5))}></input>
                        </td>
                    </tr>
                )
            }
        }
        console.log('Current', this.state)
        console.log('Current', tableElements)
        return (
            <div>
                <Navbar highlighted={'SetParameters'} />
                <TopNavbar title={'Set Parameters'} />
                <div className='wrapper'>
                    <div className='main_content'>
                        <div className='pad2'>
                            <div className="header">
                                <div className="welcomeContainer" >
                                    <div className="mainLine" >
                                        Adjust Ratios
									</div>
                                    <div className='subLine' >
                                        according to your needs
									</div>
                                </div>
                                <div className='buttonText'>
                                    <div className={this.state.errorHighlight?'buttonContainerDisabled':'buttonContainer'} onClick={this.state.errorHighlight?()=>{}:()=>this.handleSubmit()}>
                                        <div className='buttonText' >Save Changes</div>
                                    </div>
                                </div>
                            </div>
                            {this.state.dataReady===false&&<Loader />}
                            <div className='tableContainer'>
                                <table>
                                    <thead>
                                        <th className='emptyTh'></th>{/*emppty cells with defined width in css */}
                                        <th className='emptyTh'></th>{/*emppty cells with defined width in css */}
                                        <th>0000-0400 hrs</th>
                                        <th>0400-0800 hrs</th>
                                        <th>0800-1200 hrs</th>
                                        <th>1200-1600 hrs</th>
                                        <th>1600-2000 hrs</th>
                                        <th>2000-2400 hrs</th>
                                    </thead>
                                    <tbody>
                                        {this.state.dataReady && tableElements}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(SetParameters);
