// comments done
import React, { Component } from 'react';
import '../../utils/css/main.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'

class ModalAddDriver extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            dname: null,
            demail: null,
            dphoneno: 0,
            nameError: null,
            phonenoError: null,
            emailError: null,
        }
    }
    // form validation
    handleFormSubmit = (e) =>
    {
        e.preventDefault();
        let nameError = null, phonenoError = null, emailError = null;
        if (this.state.dname === null || !(this.state.dname.length > 0))
            nameError = 'Please enter a name'

        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.state.demail).toLowerCase()) || this.state.demail === null)
            emailError = 'Invalid Email entered!'

        if (!isValidPhoneNumber(this.state.dphoneno) || this.state.dphoneno === null)
            phonenoError = 'Entered phone number is invalid!'

        this.setState({
            nameError: nameError,
            emailError: emailError,
            phonenoError: phonenoError
        })

        if (!(emailError || phonenoError || nameError))
            console.log("success", this.state);

    }
    render()
    {

        return (
            <div id="addDriverModal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Add Driver</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>

                        <form class='text-left' onSubmit={(e) => { this.handleFormSubmit(e) }}>
                            <div className="modal-body">

                                <div class="form-group">
                                    <label for="Name">Name</label>
                                    <input type="text" class="form-control" id="Name" placeholder="Name" onChange={(e) => { this.setState({ dname: e.target.value }) }}></input>
                                    <div className='error'>{this.state.nameError}</div>
                                </div>

                                <div class="form-group">
                                    <label for="emailid">Email Id</label>
                                    <input type="email" class="form-control" id="emailid" placeholder="Email Id" onChange={(e) =>
                                    {
                                        this.setState({ demail: e.target.value })
                                    }}></input>
                                    <div className='error'>{this.state.emailError}</div>

                                </div>

                                <div class="form-group">
                                    <label for="phoneno">Phone No</label>
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        defaultCountry="IN"
                                        value={this.state.phoneno}
                                        countries={['US', 'IN']}
                                        minLength="10"
                                        onChange={(e) => { console.log('RED PH', e); this.setState({ dphoneno: e }) }
                                        }
                                        smartCaret={false} />
                                    <div className='error'>{this.state.phonenoError}</div>

                                </div>

                            </div>
                            <div className="modal-footer custom-footer">
                                <button type="submit" class="btn btn-primary float-left primary-btn" ><span style={{ marginRight: '8px' }}><i class="fas fa-envelope"></i></span>Send SMS</button>
                                <button type="button" className="btn btn-default normal-btn" data-dismiss="modal">Close</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}
export default ModalAddDriver