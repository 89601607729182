// comments done
import React from 'react';
// import routes from '../../resources/Dashboard.json'
import
{
    withScriptjs,
    withGoogleMap,
    GoogleMap,
} from "react-google-maps";
import MarkerWithInfoWindow from '../additional components/MarkerWithInfoWindowOneAtaTime.js'
import '../../utils/css/main.css'
import moment from 'moment'
import axios from "axios";
import ReactTable from 'react-table/react-table';
import '../../utils/css/customReact-table.css'
import Loader from '../../utils/additional components/LoaderComponent'
const stylesLight = require('../json/mapColors.json')
const stylesDark = require('../json/mapColorsDark.json')
let styles = null
class Map extends React.Component
{
    // same as DashboardMap, just not rendering the polylines
    constructor(props)
    {
        super(props);
        this.state = {
            allDirectionsReady: false,
            directionService: [],
            driver: [],
            loading: false,
            newMarkers: false,
            currentOpenInfoWindow: 0,
            markerClicked: false,
            avg_lat: 13.0827,
            avg_long: 80.2707,
            intervalData: null,
            driverClicked: null,
            tableData: [],
            pathsRendered: [],
            selectedRow: -1
        }
    }
    innerRoutes = null
    colors = [
        "olive", "orange", "purple", "black", "blue", "brown", "cyan", "green", "indigo", "limegreen", "magenta", "navy", "red", "teal", "turquoise", "yellow", "grey"
    ]
    markerRendered = []
    driverCtr = 0
    noOfRoutes = null
    allDrivers = []
    driver_color = {}
    liveLocationMarkers = []
    interval_id = null
    fetchIntervalData1 = () =>
    {

        if (this.state.allDirectionsReady === false)
        {
            this.setState({ loading: true })

            axios.post('https://live-tracking-zalt.an.r.appspot.com/getnextlocation')
                .then((response) => response.data)
                .then(
                    (data) =>
                    {
                        console.log('IMPORTANT', data)
                        this.innerRoutes = data['drivers'];
                        this.noOfRoutes = Object.keys(this.innerRoutes).length;
                        for (let driver in this.innerRoutes)
                        {
                            this.allDrivers = this.allDrivers.concat(driver)
                            this.driverCtr = this.driverCtr + 1
                            this.driver_color[driver] = this.colors[this.driverCtr % 17]
                            if (this.driverCtr === this.noOfRoutes)
                            {
                                this.setState({
                                    allDriversReady: true,
                                    driver: this.state.driver.concat(this.allDrivers),
                                    loading: false,
                                    newMarkers: true,
                                    allDirectionsReady:true
                                })
                            }
                        }
                    }
                )
                // Catch any errors we hit and update the app
                .catch(error => this.setState({ error }))
        }

    }
    fetchIntervalData2 = () =>
    {
        axios.post('https://live-tracking-zalt.an.r.appspot.com/getnextlocation')
            .then((response) => response.data)
            .then(
                (data) =>
                {
                    console.log('IMPORTANT', data)
                    this.innerRoutes = data['drivers'];
                    this.noOfRoutes = Object.keys(this.innerRoutes).length
                    this.setState({ newMarkers: true })
                }
            )
            // Catch any errors we hit and update the app
            .catch(error => this.setState({ error }))
    }
    openInfoWindow = (markerKey) =>
    {
        this.setState({ currentOpenInfoWindow: markerKey, markerClicked: true })
    }
    closeOpenWindow = () =>
    {
        this.setState({ currentOpenInfoWindow: 0, markerClicked: true })
    }
    componentDidMount = () =>
    {
        this.fetchIntervalData1();
        this.interval_id = setInterval(() =>
        {
            this.fetchIntervalData2()
        }, 3000);

        let uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
        if (uiBgNo === 1)
            styles = stylesDark
        else
            styles = stylesLight
    }

    componentWillUnmount = () =>
    {
        console.log('Dashboard Map 2 will unmount')
        clearInterval(this.interval_id)
    }
    render = () =>
    {
        let avg_lat = 13.0827;
        let avg_long = 80.2705;
        let no_of_waypts = 0;
        var color = '#ff0000'
        let data = []

        if (this.state.allDirectionsReady === true)
        {
            if (this.state.newMarkers === true || this.state.markerClicked === true)
            {
                this.markerRendered = []
                let markerKey = 0;
                data = []
                avg_lat = 0; avg_long = 0;
                for (let driver in this.innerRoutes)
                {
                    color = this.driver_color[driver]
                    let arr = [this.innerRoutes[driver]["CurrentLocation"]]
                    let temp = this.innerRoutes[driver]
                    data.push({
                        'no': driver,
                        'name': temp['name'],
                        'bat_stat': temp['BatteryStatus'],
                        'curr_loc': temp['CurrentLocation'],
                        'next_deliv_loc': temp['NextDeliveryLocation'],
                        'next_deliv_addr': temp['NextDeliveryAddress'],
                        'last_up': temp['LastUpdated']
                    })
                    if (this.state.driverClicked === driver || this.state.driverClicked === null)
                    {
                        for (let j = 0; j < arr.length; j++)
                        {
                            markerKey += 1;
                            let markerIcon = 'normal';
                            let loc = arr[j].split(", ")
                            avg_lat += parseFloat(loc[0]);
                            avg_long += parseFloat(loc[1]);
                            no_of_waypts += 1;
                            let content = <div style={{ color: "black" }}>{this.innerRoutes[driver]["NextDeliveryAddress"]}</div>
                            if (j === 0)
                            {
                                content =
                                    <div style={{ color: "black" }}>
                                        <span style={{ fontWeight: "bold" }}>Driver Name: </span><span>{this.innerRoutes[driver]["name"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Battery Status: </span><span>{this.innerRoutes[driver]["BatteryStatus"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Next Delivery Address: </span><span>{this.innerRoutes[driver]["NextDeliveryAddress"]}</span>
                                        <br></br>
                                        <span style={{ fontWeight: "bold" }}>Last Updated: </span><span>{this.innerRoutes[driver]["LastUpdated"]}</span>
                                    </div>
                                markerIcon = 'bike';
                            }
                            this.markerRendered = this.markerRendered.concat(
                                <MarkerWithInfoWindow loc={loc} color={color} j={j} content={content} flag={markerIcon} markerKey={markerKey} currentOpenInfoWindow={this.state.currentOpenInfoWindow} openInfoWindow={(markerKey) => this.openInfoWindow(markerKey)} closeOpenWindow={() => this.closeOpenWindow()} />
                            )
                        }
                    }
                    avg_lat /= no_of_waypts;
                    avg_long /= no_of_waypts
                    console.log('AVERAGE', avg_lat, avg_long)
                    this.setState({ newMarkers: false, markerClicked: false, avg_lat, avg_long, tableData: data })

                }
                this.setState({ newMarkers: false, markerClicked: false })

            }

        }
        const columns = [
            {
                Header: "Driver No",
                accessor: "no",
            },
            {
                Header: "Driver Name",
                accessor: "name",
            },
            {
                Header: "Battery Status",
                accessor: "bat_stat",
            },
            {
                Header: "Current Location",
                accessor: "curr_loc",
            },
            {
                Header: "Next Delivery Location",
                accessor: "next_deliv_loc",
            },
            {
                Header: "Next Delivery Address",
                accessor: "next_deliv_addr",
            },
            {
                Header: "Last Updated",
                accessor: "last_up",
            }
        ]
        return (
            <div className='DMwrapper'>

                {this.state.loading ?
                    <div className='loadingContainer'>
                        Loading...
                    </div>
                    :
                    null
                }
                {this.state.loading && <Loader />}
                {this.state.loading ?
                    null
                    :
                    <GoogleMap
                        defaultZoom={11}
                        defaultCenter={{ lat: this.state.avg_lat, lng: this.state.avg_long }}
                        defaultOptions={{
                            disableDefaultUI: false, // disable default map UI
                            draggable: true, // make map draggable
                            keyboardShortcuts: true, // disable keyboard shortcuts
                            scaleControl: true, // allow scale controle
                            scrollwheel: true, // allow scroll wheel
                            styles: styles // change default map styles
                        }}
                    >
                        {this.markerRendered}
                        {/* {this.state.pathsRendered} */}
                    </GoogleMap>
                }
                {!this.state.loading ?
                    <div className='timeLineContainer'>
                        <div className='timeLineContainerHeader'>
                            <span className='mainLine'>Date</span>
                            <br></br>
                            <span className='subLine' style={{ fontSize: '1rem' }}>{moment().format("dddd, MMMM Do YYYY")}</span>
                        </div>
                        <div className='rtContainer2'>
                            <ReactTable
                                columns={columns}
                                data={this.state.tableData}
                                showPaginationBottom={true}
                                noDataText={"Loading..."}
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 20, 25]}
                                defaultPageSize={10}
                                // loading={this.state.loading}
                                className='-striped -highlight'
                                sortable={true}
                                filterable={true}
                                getTrProps={(state, rowInfo, column, instance) =>
                                {
                                    if (rowInfo && rowInfo.row)
                                    {
                                        return {
                                            onClick: (e, handleOriginal) =>
                                            {
                                                let val = rowInfo['original']['no'];
                                                if (this.state.driverClicked === val)
                                                    val = null;
                                                let index = rowInfo.index;
                                                if (this.state.selectedRow === index)
                                                    index = -1;

                                                this.setState({ driverClicked: val, markerClicked: true, selectedRow: index })
                                                // IMPORTANT! React-Table uses onClick internally to trigger
                                                // events like expanding SubComponents and pivots.
                                                // By default a custom 'onClick' handler will override this functionality.
                                                // If you want to fire the original onClick handler, call the
                                                // 'handleOriginal' function.
                                                if (handleOriginal)
                                                {
                                                    handleOriginal()
                                                }
                                            },
                                            style: {
                                                fontWeight: rowInfo.index === this.state.selectedRow ? '600' : 'normal',
                                                backgroundColor: rowInfo.index === this.state.selectedRow ? 'var(--reacttable-hover)' : '',
                                            }

                                        }
                                    }
                                    else return {}
                                }}
                            >

                            </ReactTable>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    };
}

const MapComponent = withScriptjs(withGoogleMap(Map));

export default () => (
    <MapComponent
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBj7orpbCxKhi3BugBoGMO9sXwIJC4EWzo&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className='mapContainer' style={{ height: '100%', width: '100%' }} />}
        mapElement={<div className='mapElement' style={{ height: `80%`, width: '100%' }}></div>}
    />
);
