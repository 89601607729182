// no comments required
import React, { Component } from 'react';

import '../../utils/css/main.css';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'

import Map from '../../utils/additional components/OrderMap';
import '../../utils/css/MapStyle.css';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
const mapStateToProps = (state) =>
{
	return { isLogged: state.isLogged }
}
class Orders extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			groups: [],
			items: [],
			showTimeline: false
		}
	}
	render()
	{
		if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
			return <Redirect to='/' />
		return (
			<div>
				<TopNavbar title={"Orders"} buttons={[{ title: "Orders 2", Link: "/Orders2" }]} />
				<Navbar highlighted={'Orders'} />

				<div className="wrapper text-center">
					<div className="main_content" style={{ height: '97vh' }}>
						{/* directly using orders map */}
						<Map />
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(Orders);
