// comments done
import React, { Component } from 'react';
import '../../utils/css/main.css';
import { Redirect } from 'react-router-dom';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'
import { connect } from 'react-redux';
import { StaticMap } from 'react-map-gl';
import { AmbientLight, PointLight, LightingEffect } from '@deck.gl/core';
import { HexagonLayer } from '@deck.gl/aggregation-layers';
import DeckGL from '@deck.gl/react';
import { tooltipStyle } from './style';
import Loader from '../../utils/additional components/LoaderComponent'

const d3 = require('d3-request');
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZXh0cmFhbmFudCIsImEiOiJja2Nkcnl4eGkwMWZ3MnhwY3Y4djhoOWliIn0.MRHbTJanWDrCjGJ4kggwcw';

// Initial viewport settings
const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 1.0
});

const pointLight1 = new PointLight({
    color: [255, 255, 255],
    intensity: 0.8,
    position: [-0.144528, 49.739968, 80000]
});

const pointLight2 = new PointLight({
    color: [255, 255, 255],
    intensity: 0.8,
    position: [-3.807751, 54.104682, 8000]
});

const lightingEffect = new LightingEffect({ ambientLight, pointLight1, pointLight2 });

const material = {
    ambient: 0.64,
    diffuse: 0.6,
    shininess: 32,
    specularColor: [51, 51, 51]
};
//above is additional light settings for the hexagon, taken from the deck gl doxs for the hexagon layer
let INITIAL_VIEW_STATE = {
    longitude: 80.271517,
    latitude: 13.077325,
    zoom: 7.4,
    minZoom: 1,
    maxZoom: 15,
    // pitch: 10.5,
    pitch: 40.5,
    // bearing: -27.396674584323023
};
// defining the color range for the hexagon
const colorRange = [
    [1, 152, 189],
    [73, 227, 206],
    [216, 254, 181],
    [254, 237, 177],
    [254, 173, 84],
    [209, 55, 78]
];

const elevationScale = { min: 1, max: 50 };

const mapStateToProps = (state) =>
{
    return { isLogged: state.isLogged, companyDetails: state.companyDetails }
}

//please refer comments for heatmap2d.js, the code structure is for most parts identical
class HexagonAnalytics extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            elevationScale: elevationScale.min,
            hover: {
                x: 0,
                y: 0,
                hoveredObject: null
            },
            points: [],
            x_coord: null,
            y_coord: null,
            hexagonReady: false,
            data1Ready: false,
            data2Ready: false,
            data3Ready: false,
            data1Switch: 1,
            data2Switch: 0,
            data3Switch: 0,
            hex_data: [],
            hex_data1: [],
            hex_data2: [],
            hex_data3: [],
            x1: 0,
            x2: 0,
            x3: 0,
            y1: 0,
            y2: 0,
            y3: 0,
            l1: 0,
            l2: 0,
            l3: 0,
            mapDataReady: false,
            radius: 1000,
            coverage: 0.7,
            upperPercentile: 100,
            dropDownClicked: true,
            timeFrame: 0
        };
    }

    _onHover({ x, y, object })
    {
        console.log('RED', object)
        const label = object ? ('No of deliveries : ' + object.points.length) : null;

        this.setState({ hover: { x, y, hoveredObject: object, label } });
    }

    _renderLayers(hex_dataInput, onHover)
    {
        return [
            new HexagonLayer({
                className: 'helloWorld',
                id: 'heatmap',
                colorRange,
                coverage: this.state.coverage,
                data: hex_dataInput,
                elevationRange: [0, 3000],
                elevationScale: hex_dataInput && hex_dataInput.length ? 50 : 0,
                extruded: true,
                getPosition: d => d,
                onHover: hover => onHover(hover),
                pickable: true,
                radius: this.state.radius,
                upperPercentile: this.state.upperPercentile,
                material,
                opacity: 0.8,//opacity of the hexagons
                transitions: {
                    elevationScale: 3000
                }
            })
        ];
    }

    fetchData = () =>
    {
        //below d3 is being used because the data fetched is to be converted from csv to json
        d3.csv('https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/3dheatmap.csv', (error, response) =>
        {
            if (!error)
            {
                console.log('FETCHED DATA 1', this.state.timeFrame)
                let hex_data1 = response.map(d => [Number(d.lng), Number(d.lat)]);
                let l1 = hex_data1.length;
                let x1 = 0, y1 = 0;
                for (let i = 0; i < l1; i++)
                {
                    x1 += parseFloat(hex_data1[i][0])
                    y1 += parseFloat(hex_data1[i][1])
                }
                let data1Ready = true;
                if (this.state.data2Ready && this.state.data3Ready)
                {
                    let x = (x1 + this.state.x2 + this.state.x3);
                    let y = (y1 + this.state.y2 + this.state.y3);
                    let len = (l1 + this.state.l2 + this.state.l3);

                    INITIAL_VIEW_STATE.latitude = parseFloat((y / len).toFixed(8));
                    INITIAL_VIEW_STATE.longitude = parseFloat((x / len).toFixed(8));
                    console.log('RED@@@1', INITIAL_VIEW_STATE)

                    this.setState({ data1Ready, hex_data1, l1, x1, y1, mapDataReady: false })
                }
                else
                    this.setState({ data1Ready, hex_data1, l1, x1, y1 })
            }
        });


        d3.csv('https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/3dheatmap.csv', (error, response) =>
        {
            if (!error)
            {
                console.log('FETCHED DATA 2', this.state.timeFrame)
                let hex_data2 = response.map(d => [Number(d.lng), Number(d.lat)]);
                let l2 = hex_data2.length;
                let x2 = 0, y2 = 0;
                for (let i = 0; i < l2; i++)
                {
                    x2 += parseFloat(hex_data2[i][0])
                    y2 += parseFloat(hex_data2[i][1])
                }
                let data2Ready = true;
                if (this.state.data1Ready && this.state.data3Ready)
                {
                    let x = (this.state.x1 + x2 + this.state.x3);
                    let y = (this.state.y1 + y2 + this.state.y3);
                    let len = (this.state.l1 + l2 + this.state.l3);

                    INITIAL_VIEW_STATE.latitude = parseFloat((y / len).toFixed(8));
                    INITIAL_VIEW_STATE.longitude = parseFloat((x / len).toFixed(8));
                    console.log('RED@@@2', INITIAL_VIEW_STATE)

                    this.setState({ data2Ready, hex_data2, x2, y2, l2, mapDataReady: false })
                }
                else
                    this.setState({ data2Ready, hex_data2, x2, y2, l2 })

            }
        });

        d3.csv('https://raw.githubusercontent.com/anantshukla/Sample-Server-Data/master/3dheatmap.csv', (error, response) =>
        {
            if (!error)
            {
                console.log('FETCHED DATA 3', this.state.timeFrame)
                let hex_data3 = response.map(d => [Number(d.lng), Number(d.lat)]);
                let l3 = hex_data3.length;
                let x3 = 0, y3 = 0;
                for (let i = 0; i < l3; i++)
                {
                    x3 += parseFloat(hex_data3[i][0])
                    y3 += parseFloat(hex_data3[i][1])
                }
                let data3Ready = true;
                if (this.state.data1Ready && this.state.data2Ready)
                {
                    let x = (this.state.x1 + this.state.x2 + x3);
                    let y = (this.state.y1 + this.state.y2 + y3);
                    let len = (this.state.l1 + this.state.l2 + l3);

                    INITIAL_VIEW_STATE.latitude = parseFloat((y / len).toFixed(8));
                    INITIAL_VIEW_STATE.longitude = parseFloat((x / len).toFixed(8));
                    console.log('RED@@@3', INITIAL_VIEW_STATE)

                    this.setState({ data3Ready, hex_data3, x3, y3, l3, mapDataReady: false })
                }
                else
                    this.setState({ data3Ready, hex_data3, x3, y3, l3 })

            }
        });
    }
    componentDidMount = () =>
    {
        this.setState({ hexagonReady: false })
        if (this.state.timeFrame === 0)
            this.fetchData();

    }

    handleDropDownClick = (e) =>
    {
        console.log('BLUE', e.target.value);
        this.setState({ timeFrame: parseInt(e.target.value), hexagonReady: false });
        this.fetchData();
    }
    render()
    {
        if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
            return <Redirect to='/' />


        if (this.state.mapDataReady === false)
        {
            let hex_data = []

            if (this.state.data1Switch === 1)
            {
                hex_data = hex_data.concat(this.state.hex_data1)
            }

            if (this.state.data2Switch === 1)
            {
                hex_data = hex_data.concat(this.state.hex_data2)
            }

            if (this.state.data3Switch === 1)
            {
                hex_data = hex_data.concat(this.state.hex_data3)
            }

            this.setState({ hex_data, mapDataReady: true, hexagonReady: true })

        }

        const { mapStyle2 = 'mapbox://styles/mapbox/dark-v9' } = this.props;
        const { mapStyle1 = 'mapbox://styles/mapbox/light-v9' } = this.props;
        //map styles config

        const uiBgNo = parseInt(localStorage.getItem('uiBgNo'))
        let mapStyle = mapStyle1;
        if (uiBgNo === 1)
            mapStyle = mapStyle2;

        const { hover } = this.state;

        console.log('RED@@@0', this.state.hex_data)


        return (
            <div>
                {hover.hoveredObject && (
                    <div
                        style={{
                            ...tooltipStyle,
                            transform: `translate(${hover.x + 105}px, ${hover.y + 88}px)`
                        }}
                    >
                        <div>{hover.label}</div>
                    </div>
                )}
                {this.state.hexagonReady===false &&
                    <Loader/>
                }
                {this.state.dropDownClicked && this.state.hexagonReady && ((this.state.data1Switch === 1) || (this.state.data2Switch === 1) || (this.state.data3Switch === 1)) ?
                    <div id="control-panel">
                        <div>
                            <label><strong>Radius:</strong> {this.state.radius}</label>
                            <input className='slider' id="radius" type="range" min="500" max="20000" step="100" onChange={(e) => { this.setState({ radius: parseInt(e.target.value) }) }} defaultValue='1000'></input>
                            <span id="radius-value"></span>
                        </div>
                        <div>
                            <label><strong>Coverage:</strong> {this.state.coverage}</label>
                            <input className='slider' id="coverage" type="range" min="0" max="1" step="0.1" onChange={(e) => { this.setState({ coverage: parseFloat(e.target.value) }) }} defaultValue='0.7'></input>
                            <span id="coverage-value"></span>
                        </div>
                        <div>
                            <label><strong>Upper Percentile:</strong> {this.state.upperPercentile}</label>
                            <input className='slider' id="upperPercentile" type="range" min="80" max="100" step="0.1" onChange={(e) => { this.setState({ upperPercentile: parseFloat(e.target.value) }) }} defaultValue='100' ></input>
                            <span id="upperPercentile-value"></span>
                        </div>
                    </div>
                    :
                    null
                }

                <TopNavbar title={"3D Heat Map"} buttons={[{ title: "2D Heat Map", Link: "/HeatMap2D" }]} />
                <Navbar highlighted={"Analytics"} />
                <div className="wrapper text-center">

                    <div className="main_content" style={{ height: '97vh' }}>
                        <div className='analyticsInputPanel'>
                            <select name="hours" id="hours" onChange={(e) => { this.handleDropDownClick(e) }}>
                                <option value="0"  selected>24hrs</option>
                                <option value="1" >0-4</option>
                                <option value="2" >4-8</option>
                                <option value="3" >8-12</option>
                                <option value="4" >12-16</option>
                                <option value="5" >16-20</option>
                                <option value="6" >20-24</option>
                            </select>
                            <div className='analyticsInputPanelInner'>
                                <div className='checkBoxGroup'>
                                    <label className='containerCheckbox'>
                                        <input type="checkbox" onClick={() => { this.setState({ data1Switch: 1 - this.state.data1Switch, mapDataReady: false }) }} id="male" name="gender" value="male" defaultChecked={true}></input>
                                        <span className='checkmark'></span>
                                    </label>
                                    <label for="male" style={{ marginRight: '1rem', marginLeft: '0rem' }}>Data 1</label>
                                </div>

                                <div className='checkBoxGroup'>
                                    <label className='containerCheckbox'>
                                        <input type="checkbox" onClick={() => { this.setState({ data2Switch: 1 - this.state.data2Switch, mapDataReady: false }) }} id="female" name="gender" value="female"></input>
                                        <span className='checkmark'></span>
                                    </label>
                                    <label for="female" style={{ marginRight: '1rem', marginLeft: '0rem' }}>Data 2</label>
                                </div>

                                <div className='checkBoxGroup'>
                                    <label className='containerCheckbox'>
                                        <input type="checkbox" onClick={() => { this.setState({ data3Switch: 1 - this.state.data3Switch, mapDataReady: false }) }} id="other" name="gender" value="other"></input>
                                        <span className='checkmark'></span>
                                    </label>
                                    <label for="other" style={{ marginLeft: '0rem' }}>Data 3</label>
                                </div>
                            </div>
                        </div>


                        <div className='analyticsMapContainer'>
                            {this.state.dropDownClicked && this.state.hexagonReady && ((this.state.data1Switch === 1) || (this.state.data2Switch === 1) || (this.state.data3Switch === 1)) ?
                                <DeckGL
                                    layers={this._renderLayers(this.state.hex_data, (hover) => { this._onHover(hover) })}
                                    effects={[lightingEffect]}
                                    initialViewState={INITIAL_VIEW_STATE}
                                    controller={true}
                                    className='DeckGlOuter'
                                >
                                    <StaticMap
                                        reuseMaps
                                        mapStyle={mapStyle}
                                        preventStyleDiffing={true}
                                        mapboxApiAccessToken={MAPBOX_TOKEN}
                                    />
                                </DeckGL>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps)(HexagonAnalytics);