//comments done
import React, { Component } from 'react';
import '../../utils/css/main.css';
import { Redirect } from 'react-router-dom';
import Navbar from '../Navbar'
import TopNavbar from '../pages/TopNavbar'
import Map2 from '../../utils/additional components/DashboardMapwithoutPolyline'
import Map3 from '../../utils/additional components/DashboardMap3'
import Map1 from '../../utils/additional components/DashboardMap'
import { connect } from 'react-redux'
const mapStateToProps = (state) => {
	return { isLogged: state.isLogged, companyDetails: state.companyDetails }
}
class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			location: ' ',
			mapNo:1
		}
	}
	changeMapNo=(mapNo)=>{
		this.setState({mapNo:mapNo})
		console.log('RED', this.state.mapNo)
	}
	render() {
		console.log('THE COMPANY IS:', this.props.companyDetails.companyName)
		if (this.props.isLogged.adminStatus === false && this.props.isLogged.userStatus === false)
			return <Redirect to='/' />
		return (
			// using radio buttons to switch between different maps
			<div>
				<TopNavbar title={"Dashboard"} />
				<Navbar highlighted={"Dashboard"} />
				<div className="wrapper text-center">
					<div className="main_content" style={{ height: '97vh' }}>
						<input type="radio" id="male" name="gender" value="male" defaultChecked={true}></input>
						<label for="male" onClick={()=>{this.changeMapNo(1)}}>Map 1</label>
						<input type="radio" id="female" name="gender" value="female"></input>
						<label for="female" onClick={()=>{this.changeMapNo(2)}} style={{marginLeft:'1rem'}}>Map 2</label>
						<input type="radio" id="other" name="gender" value="other"></input>
						<label for="other" onClick={()=>{this.changeMapNo(3)}} style={{marginLeft:'1rem'}}>Map 3</label>
						{this.state.mapNo===1 && <Map1 />}
						{this.state.mapNo===2 && <Map2 />}
						{this.state.mapNo===3 && <Map3 />}
					</div>
				</div>

			</div>
		);
	}
}

export default connect(mapStateToProps)(Dashboard);
