import React from 'react';

import
{
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

//Pages
import LoginPage from './components/pages/LoginPage';
import Dashboard from './components/pages/Dashboard';
import Orders from './components/pages/Orders';
import Orders2 from './components/pages/Orders2';
import DriverAnalytics from './components/pages/DriverAnalytics';
import DeliveryHistory from './components/pages/DeliveryHistory';
import DriverDetails from './components/pages/DriverDetails';
import OnboardRequests from './components/pages/OnboardRequests';
import ChooseProject from './components/pages/ChooseProject';
import CreateProject from './components/pages/CreateProject';
import AdminManageRequests from './Admin Portal/components/ManageRequests'
import AdminDashboard from './Admin Portal/components/Dashboard'
import AdminAnalytics from './Admin Portal/components/Analytics'
import AdminViewUsers from './Admin Portal/components/ViewUsers'
import AdminSettingsPage from './Admin Portal/components/AdminSettingsPage'
import SettingsPage from './components/pages/SettingsPage'
import HexagonAnalytics from './components/analytics_pages/HexagonAnalytics'
import HeatMap2D from './components/analytics_pages/HeatMap2D'
import ErrorPage404 from './components/pages/ErrorPage404'
import SetParameters from './components/pages/SetParameters'
//Redux
//import {useSelector, useDispatch} from 'react-redux';



function App()
{


	return (
		<div className="App">

			<Router>

				<Switch>
					<Route path="/" component={LoginPage} exact={true}>
					</Route>

					<Route path="/Dashboard" component={Dashboard} >
					</Route>

					<Route path="/ChooseProject" component={ChooseProject} >
					</Route>

					<Route path="/CreateProject" component={CreateProject} >
					</Route>

					<Route path="/Orders" component={Orders} >
					</Route>

					<Route path="/Orders2" component={Orders2} >
					</Route>

					<Route path="/DriverAnalytics" component={DriverAnalytics} >
					</Route>

					<Route path="/DeliveryHistory" component={DeliveryHistory} >
					</Route>


					<Route path="/DriverDetails" component={DriverDetails} >
					</Route>

					<Route path="/OnboardRequests" component={OnboardRequests} >
					</Route>

					<Route path="/SettingsPage" component={SettingsPage} >
					</Route>

					<Route path="/AdminManageRequests" component={AdminManageRequests} >
					</Route>

					<Route path="/AdminAnalytics" component={AdminAnalytics} >
					</Route>

					<Route path="/AdminDashboard" component={AdminDashboard} >
					</Route>

					<Route path="/AdminViewUsers" component={AdminViewUsers} >
					</Route>

					<Route path="/AdminSettingsPage" component={AdminSettingsPage} >
					</Route>

					<Route path="/HexagonAnalytics" component={HexagonAnalytics} >
					</Route>

					<Route path="/HeatMap2D" component={HeatMap2D} >
					</Route>

					<Route path="/SetParameters" component={SetParameters} >
					</Route>

					<Route component={ErrorPage404} >
					</Route>
				</Switch>
			</Router>
		</div>
	);
}




export default App;
